import React, { useContext, useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import { UserContext } from '../../contexts/UserContext/UserContext'
import { CartContext } from '../../contexts/CartContext/CartContext'
import {
  Container,
  Row,
  Col,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Form,
} from 'reactstrap'

import { FacebookLoginButton } from 'react-social-login-buttons'
import GoogleButton from 'react-google-button'
import { useForm } from 'react-hook-form'
import SigninForm from 'Components/Forms/SigninForm'
import SignupForm from 'Components/Forms/SignupForm'
import ConfirmSignup from 'Components/Forms/ConfirmSignup'
import './authpage.scss'
import ForgotPassword from 'Components/Forms/ForgotPassword'
import CompleteForgotPassword from 'Components/Forms/CompleteForgotPassword'

const AuthPage = (props) => {
  const [passwordEye, setPasswordEye] = useState(true)
  const { register, handleSubmit, watch, errors } = useForm()
  const { updateFormState, formState, user, setUser } = useContext(UserContext)
  const { cartItems } = useContext(CartContext)
  const [errorMessage, setErrorMessage] = useState(null)
  const [isVisible, setIsVisible] = useState(false)
  const onSubmit = (data) => {
    if (formState.formType === 'signUp') {
      signUp(data.usernameSignup, data.emailSignup, data.passwordSignup)
    } else if (formState.formType === 'signIn') {
      signIn(data.username, data.password)
    } else if (formState.formType === 'confirmSignUp') {
      confirmSignUp(data.authCode)
    } else if (formState.formType === 'forgotPassword') {
      forgotPassword(data.usernameForgotPassword)
    } else if (formState.formType === 'completeForgotPassword') {
      completeForgotPassword(
        data.authCodeForgotPassword,
        data.passwordForgotPassword
      )
    }
  }

  const onChange = (e) => {
    e.persist()
    updateFormState(() => ({ ...formState, [e.target.name]: e.target.value }))
  }
  const { formType } = formState

  async function signUp(username, email, password) {
    // const { username, email, password } = formState
    try {
      await Auth.signUp({ username, password, attributes: { email } })
      updateFormState(() => ({
        ...formState,
        username,
        formType: 'confirmSignUp',
      }))
      setErrorMessage(null)
    } catch (e) {
      console.log(e)
      setErrorMessage(e.message)
    }
  }
  async function confirmSignUp(authCode) {
    const { username } = formState
    try {
      await Auth.confirmSignUp(username, authCode)
      updateFormState(() => ({ ...formState, formType: 'signIn' }))
    } catch (e) {
      console.log(e)
      setErrorMessage(e.message)
    }
  }
  async function signIn(username, password) {
    // const { username, password } = formState
    try {
      await Auth.signIn({ username, password })
      const tempUser = await Auth.currentAuthenticatedUser()
      setUser(tempUser)
      updateFormState(() => ({ ...formState, formType: 'signedIn' }))
      if (cartItems.length > 0) {
        props.history.push('/order')
      } else {
        props.history.push('/')
      }
    } catch (e) {
      console.log(e)
      setErrorMessage(e.message)
    }
  }

  async function forgotPassword(username) {
    // const { username } = formState
    try {
      await Auth.forgotPassword(username)
      updateFormState(() => ({
        ...formState,
        username,
        formType: 'completeForgotPassword',
      }))
      setErrorMessage(null)
    } catch (e) {
      console.log(e)
      setErrorMessage(e.message)
    }
  }
  async function completeForgotPassword(authCode, password) {
    const { username } = formState
    try {
      await Auth.forgotPasswordSubmit(username, authCode, password)
      updateFormState(() => ({
        ...formState,
        formType: 'signIn',
      }))
      setErrorMessage(null)
    } catch (e) {
      console.log(e)
      setErrorMessage(e.message)
    }
  }
  const handleVisible = () => {
    setIsVisible(!isVisible)
  }
  return (
    <Container className='sign-in-cont'>
      <Row className='sign-in-row'>
        <Col className='sign-in-col' xl='5' lg='6' md='8' sm='12' xs='12'>
          {formType === 'confirmSignUp' ? null : formType === 'signUp' ? (
            <div className='text-center'>
              <h3>Sign Up</h3>
              <p className='already-title'>
                Already have an account?&nbsp;
                <span
                  onClick={() => {
                    updateFormState(() => ({
                      ...formState,
                      formType: 'signIn',
                    }))
                    setErrorMessage(null)
                  }}>
                  Sign In
                </span>
              </p>
            </div>
          ) : (
            <div className='text-center'>
              <h3>Sign In</h3>
              <p className='already-title'>
                New to Artichokee?&nbsp;
                <span
                  onClick={() => {
                    updateFormState(() => ({
                      ...formState,
                      formType: 'signUp',
                    }))
                    setErrorMessage(null)
                  }}>
                  Sign up
                </span>
              </p>
            </div>
          )}

          {formType === 'confirmSignUp' ? null : (
            <>
              <div style={{ padding: '0px 10px', margin: '0px 5px' }}>
                <FacebookLoginButton
                  className='my-2'
                  onClick={() => Auth.federatedSignIn({ provider: 'Facebook' })}
                />

                <div style={{ padding: '0 5px', marginTop: '8px' }}>
                  <GoogleButton
                    type='light'
                    className='my-2 w-100 text-left'
                    onClick={() => Auth.federatedSignIn({ provider: 'Google' })}
                  />
                </div>
              </div>
              <div onClick={handleVisible} className='sign-in-divider'>
                <span>or continue with email</span>
              </div>
            </>
          )}

          {formType === 'signUp' && (
            <Form
              onSubmit={handleSubmit(onSubmit)}
              style={{ display: `${isVisible ? 'block' : 'none'}` }}>
              <SignupForm register={register} errors={errors} />
            </Form>
          )}
          {formType === 'confirmSignUp' && (
            <Form onSubmit={handleSubmit(onSubmit)}>
              <ConfirmSignup register={register} errors={errors} />
            </Form>
          )}

          {formType === 'signIn' && (
            <Form
              style={{ display: `${isVisible ? 'block' : 'none'}` }}
              onSubmit={handleSubmit(onSubmit)}>
              <SigninForm
                updateFormState={updateFormState}
                formState={formState}
                register={register}
                errors={errors}
                setErrorMessage={setErrorMessage}></SigninForm>
            </Form>
          )}
          {formType === 'forgotPassword' && (
            <Form onSubmit={handleSubmit(onSubmit)}>
              <ForgotPassword
                register={register}
                errors={errors}></ForgotPassword>
            </Form>
          )}
          {formType === 'completeForgotPassword' && (
            <Form onSubmit={handleSubmit(onSubmit)}>
              <CompleteForgotPassword
                register={register}
                errors={errors}></CompleteForgotPassword>
            </Form>
          )}
          {errorMessage !== null ? (
            <div className='auth-err-message'>
              <p>{errorMessage}</p>
            </div>
          ) : null}
        </Col>
      </Row>
    </Container>
  )
}

export default AuthPage

{
  /* <div>
      {formType === 'signUp' && (
        <div>
          <input name='username' onChange={onChange} placeholder='username' />
          <input
            name='password'
            type='password'
            onChange={onChange}
            placeholder='password'
          />
          <input
            name='email'
            type='email'
            onChange={onChange}
            placeholder='email'
          />
          <button onClick={signUp}>Sign Up</button>
          <button
            onClick={() =>
              updateFormState(() => ({
                ...formState,
                formType: 'signIn',
              }))
            }
          >
            Sign In
          </button>
        </div>
      )}
      {formType === 'confirmSignUp' && (
        <div>
          <input name='authCode' onChange={onChange} placeholder='authCOde' />

          <button onClick={confirmSignUp}>Confirm Sign Up</button>
        </div>
      )}
      {formType === 'signIn' && (
        <div>
          <input name='username' onChange={onChange} placeholder='username' />
          <input
            name='password'
            type='password'
            onChange={onChange}
            placeholder='password'
          />

          <button onClick={signIn}>Sign In</button>
          <button
            onClick={() =>
              updateFormState(() => ({
                ...formState,
                formType: 'signUp',
              }))
            }
          >
            Sign Up
          </button>
        </div>
      )}
      {formType === 'signedIn' && (
        <div>
          Hello {user.username}
          <button
            onClick={() => {
              Auth.signOut()
            }}
          >
            Sign Out
          </button>
        </div>
      )}
    </div> */
}

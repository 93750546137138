import React from 'react'
import { useForm } from 'react-hook-form'
import {
Button
} from 'reactstrap'
import StandardInput from 'Components/Inputs/StandardInput/StandardInput'
function ConfirmSignup({ register, errors }) {
  return (
    <>
      <StandardInput
        labelTitle='Verification Code'
        placeHolder=''
        maxLen={6}
        inputType='text'
        isRequired={true}
        inputName='authCode'
        refTemp={register({
          required: 'Auth code is required!',
        })}
        emptyControl={errors.authCode}
      />
      <Button className='signin-btn' type='submit'>Confirm</Button>
    </>
  )
}

export default ConfirmSignup

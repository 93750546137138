import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import './privacyandpolicy.scss'
function PrivacyAndPolicy() {
  return (
    <Container className='privacy-policy-cont'>
      <Row className='title-row'>
        <Col className='title-cont' xl='12' lg='12' md='12' sm='12' xs='12'>
          Privacy Policy - United States
        </Col>
        <Col xl='12' lg='12' md='12' sm='12' xs='12'>
          Artichokee Consumers
        </Col>
      </Row>
      <Row className='content-row'>
        <Col className='content-col'>
          <p>
            Artichokee.com is operated by Artichoke, Inc. (hereinafter, "we", "our" or "us"). We respect your privacy
            and are committed to protecting your information. This privacy policy describes our practices regarding
            personal information collected to operate the Artichokee.com sites and app that can be downloaded from app
            stores (collectively, the "Services").
          </p>
          <p>
            It is important that you read this privacy policy together with any other privacy notice or fair processing
            notice we may provide on specific occasions when we are collecting or processing information about you so
            that you are fully aware of how and why we are using your data.
          </p>
          <p>
            INFORMATION COLLECTION <br /> We may collect the following types of information about you:
          </p>
          <ul>
            <li>Identity Data, which includes name or other similar identifiers.</li>
            <li>Contact Data, which includes address, email address and telephone numbers.</li>
            <li>Financial Data, which includes credit card, debit card or other payment card details.</li>
            <li>Transaction Data, which includes details about payments to and from you.</li>
            <li>
              Technical Data, which includes internet protocol (IP) address, location data, your login data, and device
              and browser information.
            </li>
            <li>
              Profile Data, which includes your username and password, your dining interests, food allergies and
              preferences.
            </li>
            <li>
              Usage Data, which includes information about how you use our Services and partner sites and advertising we
              serve on those sites.
            </li>
            <li>Marketing and Communications Data, which includes your preferences in receiving marketing.</li>
          </ul>
          <p>
            IF YOU ARE SITUATED IN THE EU, PLEASE SEE THE SECTION "ADDITIONAL DISCLOSURES FOR EU RESIDENTS" THAT PERTAIN
            TO OUR COLLECTION, USE AND DISCLOSURE OF YOUR PERSONAL DATA AND ADDITIONAL RIGHTS YOU HAVE UNDER APPLICABLE
            LAW.
          </p>
          <p>We may collect information about you in various ways, including through:</p>
          <ul>
            <li>
              Direct interactions: You may give us your information by registering or contacting us. This includes
              information you provide when you:
              <ul>
                <li>create an account with us;</li>
                <li>subscribe to our Services or publications;</li>
                <li>make a reservation through our Services;</li>
                <li>request marketing to be sent to you; or</li>
                <li>give us feedback.</li>
              </ul>
            </li>
            <li>
              Using Our Services: As you interact with our Services, we may automatically collect Technical Data and
              Usage Data about your use of our Services. Some of the ways in which we may collect information are
              further described below:
            </li>
            <li>
              Cookies and other technologies: The Services may use cookies, a type of technology that installs a small
              amount of information on a website user’s computer or other device to permit a website to, for example,
              recognize future visits using that computer or device. The Services may use other similar technologies
              (including, without limitation, tracking pixels, as described further below, and other anonymous
              identifiers) to gather information about you, such as IP addresses and location data, to customize your
              visit, to enable us to enhance our Services, or for other purposes. Please see our Cookie Policy located
              on the Artichokee.com homepage for further details.
            </li>
            <li>
              Interest-Based Advertising: We may engage third-party vendors to use your information in connection with
              their own information to deliver targeted advertising to you when you visit our website or other websites.
              Cookies, described above, may be used in this process. For example, if you are searching for information
              on a particular product, our vendor may cause an advertisement to appear on other websites you view with
              information on that product. This form of advertising is called "Behavioral Advertising." We believe that
              such advertising is helpful because you will see ads that are relevant to your interests. However, if you
              would like to opt out of these interest-based advertisements, please see the “CUSTOMER CHOICES” section
              below.
            </li>
            <li>
              Clickstream Data: When you use the internet, websites collect information about the type of computer and
              browsing software you use and the address of the website from which you linked to the website. The
              Services may collect and use clickstream data as a form of aggregate information to anonymously determine
              how much time visitors spend on each page of our website, how visitors navigate throughout the website and
              how we may tailor our website to better meet the needs of visitors. This information may be used to
              improve our Services. Any collection or use of clickstream data will be anonymous and aggregated and will
              not intentionally contain any information about you.
            </li>
            <li>
              Location Data: When you give us consent, we will collect the latitude and longitude coordinates of your
              device when you are searching restaurant availability. We use this information to help us improve the
              relevance of search results and streamline the restaurant reservation processes. This information is only
              collected while the app is running. We also use general geographic data to identify the city a user is
              visiting to make the Services more relevant. If you would like to opt out of location tracking, please see
              the “CUSTOMER CHOICES” section below.
            </li>
          </ul>
          <p>
            INFORMATION USE AND SHARING <br />
            We use information held about you to perform our services and for other purposes outlined below.
            Specifically, we use your information for a variety of purposes:
          </p>
          <ul>
            <li>To perform a contract with you;</li>
            <li>To perform specific services that you have requested;</li>
            <li>To respond to your direct inquiries;</li>
            <li>For joint marketing with other financial companies;</li>
            <li>For our affiliates’ everyday business purposes;</li>
            <li>
              Where it is necessary for our legitimate interests and your interests and fundamental rights do not
              override those interests;
            </li>
            <li>To send you Artichokee.com marketing materials; </li>
            <li>To send you marketing materials for our affiliates’ products and services;</li>
            <li>Where we need to comply with a legal or regulatory obligation; and</li>
            <li>In other ways as required or permitted by law or with your consent.</li>
          </ul>
          <p>
            We will only disclose information that we collect about you to third parties with your consent or as
            provided below:
          </p>
          <ul>
            <li>
              We will share your information with restaurants and their affiliates, as well as other third parties not
              affiliated with Artichokee.com (as further described in the Terms of Service) when you make a dining
              request through the Services, including when you make a reservation and if you provide feedback about your
              dining experience through us, such as via a survey. We will provide the restaurant certain categories of
              your information including: your name, dining profile, date and time of visit, your contact details,
              dining preferences, party size and any other information you provide at the time of your reservation or
              feedback. We or the restaurant may share your information with their affiliates (e.g., other restaurants
              in the same restaurant group) to enhance the hospitality such restaurant group provides you when you dine
              with them to provide you with customized service and to improve the restaurant’s table and shift planning.
              By interacting with you, the restaurant and their affiliates may, as permitted by applicable law, share
              your information with their partners to support operations, such as to perform analytics and tailor
              marketing to you.
            </li>
            <li>
              To use certain Services, payment card information may be requested. We may ask you for credit card, debit
              card, or other payment information. By submitting your payment information through our Services, to the
              extent permitted by applicable law, you expressly consent to the sharing of your information with
              restaurants, third-party payment processors, and other third-party service providers.
            </li>
            <li>
              To third parties to whom we may choose to sell, transfer, or merge parts of our business or our assets.
              Alternatively, we may seek to acquire other businesses or merge with them, if we are part of a bankruptcy
              proceeding or other change in control.
            </li>
            <li>
              To third parties to whom we need to share your information to investigate suspected fraud, abusive or
              unlawful uses or activities, harassment or other violations of any law, rule or regulation, or the
              policies for the Services, or to protect the security or integrity of the Services.
            </li>
            <li>To our affiliates, such as the American Express family of companies.</li>
            <li>
              To third-party service providers to provide website, application development, hosting, maintenance, and
              other services for us. These third parties may have access to or process your information as part of
              providing those services for us. Generally, we limit the information provided to these service providers
              to that which is reasonably necessary for them to perform their functions, and we require them to agree to
              maintain the confidentiality of such information.
            </li>
            <li>
              To respond to requests from law enforcement authorities and our regulators, or to respond to requests if
              we are involved in a legal proceeding.
            </li>
            <li>
              To third parties as necessary to investigate and correct technical problems and malfunctions in the
              Services.
            </li>
          </ul>
          <p>
            If you are an individual that resides in the European Union, please see the “ADDITIONAL DISCLOSURES FOR EU
            RESIDENTS” section below.
          </p>
          <h2>
            <br />
          </h2>
          <h2>
            <b>Third-Party Links and Social Networking Services</b>
          </h2>
          <p>
            The Services may include links to third-party websites, plug-ins and applications. Clicking on those links
            or enabling those connections may allow third parties to collect or share data about you. We do not control
            these third-party websites and are not responsible for their privacy policies. When you leave our Services,
            we encourage you to read the privacy policy of every website you visit. The Services may integrate with
            social networking services. We do not control such services and are not liable for the way they operate or
            process data. While we may provide you with the ability to use such services in connection with our
            Services, we are relying upon those third-party services to operate in line with their terms and conditions
            and privacy policies. You should be aware that information which you voluntarily include and transmit online
            in a publicly- accessible forum (e.g., blog, social network) may be viewed and used by others. We are unable
            to control such uses of your information, and by using such services you acknowledge that the information
            provided by you may be viewed and used by third parties.
          </p>
          <h2>
            <br />
          </h2>
          <h2>
            <b>Data Security</b>
          </h2>
          <p>
            We will keep your information confidential and limit access to your information to those who specifically
            need it to conduct their business activities, except as otherwise permitted by applicable law. We refer to
            industry standards and use reasonable administrative, technical and physical security measures to protect
            your information from unauthorized access, destruction, use, modification or disclosure. We require industry
            standard data security measures from those third parties who are authorized by us to process your
            information on our behalf.
          </p>
          <h2>
            <br />
          </h2>
          <h2>
            <b>Data Retention</b>
          </h2>
          <p>
            We will only retain your information for as long as necessary to fulfill the purposes we collected it for,
            including for the purposes of satisfying any legal, accounting, or reporting requirements. To determine the
            appropriate retention period for your information, we consider the amount, nature, and sensitivity of the
            information, the potential risk of harm from unauthorized use or disclosure of your information, the
            purposes for which we process your information and whether we can achieve those purposes through other
            means, and the applicable legal requirements. In some circumstances we may anonymize information about you
            (so that it can no longer be associated with you) for research or statistical purposes in which case we may
            use and retain this information indefinitely without further notice to you. If your account is closed, we
            may take steps to delete certain information, but we reserve our ability to retain and access the data for
            so long as required to comply with applicable laws. We will continue to use and disclose information in
            accordance with this privacy policy.
          </p>
          <h2>
            <br />
          </h2>
          <h2>
            <b>Customer Choices</b>
          </h2>
          <p>
            Accessing, Updating, and Deleting Your Personal Information
            <br />
            When you use Artichokee, we make efforts to provide you access to your personal information upon your
            request. We provide you an opportunity to correct this information if it is inaccurate or delete your
            personal data at your request if it is not required to be retained by law or for legitimate business
            purposes. You may access, review, correct, update, change or delete your information at any time. To do so,
            please contact us at privacy@artichokee.com with your name, the email address that you register with and the
            information requested to be accessed, corrected, or removed. We will start investigating your request and
            inform you about the process in 48 hours.
            <p>We may decline to process your data removal requests in these circumstances:</p>
          </p>
          <ul>
            <li>If your request is unreasonably repetitive or systematic</li>
            <li>If your request jeopardizes the privacy of others (restaurants)</li>
            <li>If you have an ongoing order process (order that is in delivery stage)</li>
          </ul>
          <p>
            Opting out of email messages from us <br />
            To opt out of any future promotional email messages from us, you should unsubscribe in the body of the
            promotional message that was sent to you (found at the bottom of the email) or send an unsubscribe request
            to us at privacy@artichokee.com. We will process your request within the timeframes required by applicable
            law.
          </p>
          <p>
            Opting out of sharing with third parties for marketing purposes <br />
            If you do not want your personal information/personal data shared with any third party who may use such
            information for direct marketing purposes, then you may opt out of such disclosures by sending an email to
            privacy@artichokee.com. Please note that if you decide to opt out in this manner, certain aspects of the
            Services may no longer be available to you. We will process your request within a reasonable time after
            receipt.
          </p>
          <p>
            Opting out of sharing with affiliates <br />
            If you do not want your personal information/personal data shared with our affiliates, who may use such
            information for direct marketing purposes, then you may opt out of such disclosures by sending an email to
            privacy@artichokee.com. Please note that if you opt out in this manner, certain aspects of the Services may
            no longer be available to you. We will process your request within a reasonable time after receipt.
          </p>
          <p>
            Opting out of interest-based advertising <br />
            The online advertising industry provides a service through which you may opt out of receiving targeted ads
            from certain data partners and other advertising partners that participate in self-regulatory programs. We
            comply with the U.S. Self-Regulatory Principles for Online Behavioral Advertising set forth by the Digital
            Advertising Alliance (DAA) and the European Interactive Digital Advertising Alliance ("EDAA"). You can opt
            out of targeted advertising from certain providers at www.aboutads.info/consumers. Please note that by
            opting out, you will continue to see generic advertising that is not tailored to your specific interests and
            activities. Please note that cookie-based opt-outs must be performed on each device and browser that you
            wish to have opted out. For example, if you have opted out on your device browser, that opt-out will not be
            effective on your mobile device. You must separately opt out on each device. EU residents who have provided
            their consent to our use of cookies and similar technologies can use the EDAA’s opt-out tool which can be
            found at artichokee.com.
          </p>
          <p>
            Opting out of cookies <br />
            If you want to stop or restrict the placement of cookies or flush any cookies that may already be on your
            computer or device, please refer to and adjust your web browser preferences. Further information on cookies
            is available at www.allaboutcookies.org. By deleting our cookies or disabling future cookies you may not be
            able to access certain areas or features of our Services or some of its functionality may be affected.
            Cookies and similar items are not used by us to automatically retrieve personal information/personal data
            from your device without your knowledge. For more information about cookies, please visit our Cookie Policy.
          </p>
          <p>
            Opting out of location tracking <br />
            You may also adjust the settings on your mobile device to allow or restrict the sharing of location
            information. For example, the "location" permissions on your mobile device may allow you to elect whether to
            never share location information with us, to share location information only while you are using the app, or
            always share location information even if you are not using the app. If you elect to not share your location
            information, you may be unable to access some features of our Services that are designed for mobile devices.
            Also, if you prevent the sharing of location information, we may still estimate your general location based
            on the IP address you use to access our Services.
          </p>
          <h2>
            <br />
          </h2>
          <h2>
            <b>Data Relating to Children</b>
          </h2>
          <p>
            Our Services are designed for those 18 years of age and older. We do not knowingly collect information from
            anyone under the age of 13 (or such other age as may be restricted under local law). If we are made aware
            that we have received such information, or any information in violation of our policy, we will use
            reasonable efforts to locate and remove that information from our records.
          </p>
          <h2>
            <br />
          </h2>
          <h2>
            <b>Cross Border Transfers</b>
          </h2>
          <p>
            Our Services are provided from the United States. By using the Services, please note that information about
            you may be transferred to the United States and other countries around the world. You understand that the
            data is stored in the United States and it may be subject to lawful requests by the courts or law
            enforcement authorities in the United States. If you are situated in the EU, whenever we transfer your
            personal data outside of the EU, we ensure appropriate and suitable safeguards are used in such transfer. We
            may use specific contracts approved by the European Commission which give personal data the same protection
            it has in the EU.
          </p>
          <h2>
            <br />
          </h2>
          <h2>
            <b>Updates to this Privacy Policy</b>
          </h2>
          <p>
            We reserve the right, at our discretion, to change, modify, add, or remove portions from this privacy policy
            at any time. However, if at any time in the future we plan to use your information in a way that materially
            differs from this privacy policy, we will post such changes here and email you. We encourage you to
            periodically review the privacy policy for the latest information on our privacy practices. You are bound by
            any changes to the privacy policy when you use the Services after such changes have been first posted.
          </p>
          <h2>
            <br />
          </h2>
          <h2>
            <b>Additional Disclosures for California Residents</b>
          </h2>
          <p>
            Certain residents of California (“CA”) may receive additional protections under the California Consumer
            Privacy Act of 2018 (“CCPA”) for “personal information.” Personal information includes information which
            identifies or could reasonably identify an individual. It does not include information that is publicly
            available or that has been de-identified or aggregated. This section describes the categories of personal
            information that Artichokee.com collects, and the purposes for which that information is collected.
          </p>
          <p>Categories of personal information that we collect</p>
          <p>
            Depending on the nature of your relationship with us (e.g., Artichokee.com user, restaurant partner, or
            Artichokee.com employee), we may collect the following categories of personal information about you,
            including:
          </p>
          <ul>
            <li>
              Identifiers such as name, postal address, email address, account name, social security number, driver’s
              license or state identification card number, or telephone number;
            </li>
            <li>
              Information that identifies, relates to or is capable of being associated with, an individual, such as
              financial information;
            </li>
            <li>
              Characteristics of protected classifications under California or federal law such as race, sex/gender or
              marital status;
            </li>
            <li>Commercial information such as dining history;</li>
            <li>Internet or other electronic network activity information such as browsing history;</li>
            <li>Geolocation data, available through your device, if enabled;</li>
            <li>Audio, electronic, or similar information;</li>
            <li>Professional or employment-related information;</li>
            <li>Education information;</li>
            <li>
              Other personal information, as described to you in this privacy policy, or otherwise at the point of
              collection; and
            </li>
            <li>Inferences drawn from any of the information identified above, including about your preferences.</li>
          </ul>
          <p>
            Depending on your relationship with us, we may have collected each of the above categories of personal
            information about you during the preceding 12 months. In addition, we may have shared each of these
            categories of personal information with third parties during the preceding 12 months for the business
            purposes described below and in the rest of this privacy policy. For each of these categories of personal
            information, please refer to the “INFORMATION USE AND SHARING” section above for more detailed information
            regarding why we collect personal information, and the categories of third parties with whom we may share
            personal information.
          </p>
          <p>
            Why we collect personal information <br /> Depending on your relationship with us (e.g., Artichokee.com
            user, restaurant partner, or Artichokee.com employee), we may use information about you for our business
            purposes, including:
          </p>
          <ul>
            <li>To perform a contract with you;</li>
            <li>To perform specific services that you have requested;</li>
            <li>To respond to your direct inquiries;</li>
            <li>For joint marketing with other financial companies;</li>
            <li>For our affiliates’ everyday business purposes;</li>
            <li>
              Where it is necessary for our legitimate interests and your interests and fundamental rights do not
              override those interests;
            </li>
            <li>To send you Artichokee.com marketing materials; </li>
            <li>To send you marketing materials for our affiliates’ products and services;</li>
            <li>To manage your employment relationship with us, if applicable;</li>
            <li>Where we need to comply with a legal or regulatory obligation; and </li>
            <li>In other ways as required or permitted by law or with your consent.</li>
          </ul>
          <p>
            Your CA privacy rights <br />
            After January 1, 2021, CA residents who have a business or employment relationship with us may have certain
            rights under the CCPA, such as the right to access, deletion, and portability. Once those rights become
            available, we will update our privacy policy. If you are a CA resident using these Services for personal or
            family purposes, please see the “CUSTOMER CHOICES” section for your rights under federal financial privacy
            laws. We may share personal information with our service providers, with third parties with whom we jointly
            offer products or services, with third parties from whom you request a product or service through us, in the
            context of a merger, acquisition, bankruptcy, or other corporate transaction, or as otherwise permitted by
            the CCPA.
          </p>
          <h2>
            <br />
          </h2>
          <h2>
            <b>Additional Disclosures for EU Residents</b>
          </h2>
          <p>
            Personal Data <br /> Some of the data that we collect from individuals in the European Union may identify or
            be identifiable to an individual. This information is considered “personal data” in the European Union, and
            subject to additional protections.
          </p>
          <p>
            Legal basis for our processing of your personal data <br />
            Below are the types of lawful bases that we will rely on to process your personal data:
          </p>
          <ul>
            <li>
              Performance of Contract means processing your data where it is necessary for the performance of a contract
              to which you are a party or to take steps at your request before entering into such a contract.
            </li>
            <li>
              Legitimate Interest means the interest of our business in conducting and managing our business to enable
              us to give you the best service/product and the best and most secure experience.
            </li>
            <li>
              Comply with a legal or regulatory obligation means processing your personal data where it is necessary for
              compliance with a legal or regulatory obligation to which we are subject.
            </li>
            <li>With your consent.</li>
          </ul>
          <p>
            Change of purpose <br />
            We will only use your personal data for the purposes for which we collected it, unless we reasonably
            consider that we need to use it for another reason and that reason is compatible with the original purpose.
            If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the
            legal basis which allows us to do so. Please note that we may process your personal data without consent, in
            compliance with the above rules, where this is required or permitted by law.
          </p>
          <p>
            Collection of personal data from third-party sources <br />
            We also collect personal data about you from various third parties and public sources. If you register for
            our Services using Facebook, Twitter or other social media sites, we will import your information from those
            social media sites. When a new restaurant joins us, we will collect any information that restaurant has
            about prior guests and import it into the restaurant's guestbook on our Services. We also obtain information
            about you from restaurants who partner with us, including notes, tags, and other metadata about your dining
            habits and experiences. As discussed above, we also obtain information through cookies and similar
            technologies (see “INFORMATION COLLECTION” section).
          </p>
          <p>
            Withdrawing your consent <br />
            If we are relying on your consent to process your personal data, you have the right to withdraw your consent
            at any time by contacting us at privacy@artichokee.com.
          </p>
          <p>
            Use of your personal data for marketing purposes <br />
            We strive to provide you with choices regarding certain personal data uses, particularly around marketing
            and advertising: <br /> Promotional offers from us: We may use your personal data to form a view on what we
            think you may want or need, or what may be of interest to you. This is how we decide which products,
            services and offers may be relevant for you (we call this marketing). You will receive marketing
            communications from us if you have requested information from us or used our services and have not opted out
            of receiving that marketing. For the EU, you must opt in to receive marketing communications. <br />{' '}
            Third-party marketing: We will get your consent before we share your personal data with any company outside
            our company for marketing purposes.
          </p>
          <p>To see how you can opt out of marketing communications, please see the “CUSTOMER CHOICES” section.</p>
          <p>
            Rights of EU data subjects <br />
            Under certain circumstances, you have rights under data protection laws in relation to your personal data.
            You have the right to:
          </p>
          <ul>
            <li>
              Request access to your personal data (commonly known as a "data subject access request"). This enables you
              to receive a copy of the personal data we hold about you and to check that we are lawfully processing it.
            </li>
            <li>
              Request correction of the personal data that we hold about you. This enables you to have any incomplete or
              inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you
              provide to us.
            </li>
            <li>
              Request erasure of your personal data. This enables you to ask us to delete or remove personal data where
              there is no good reason for us continuing to process it. You also have the right to ask us to delete or
              remove your personal data where you have successfully exercised your right to object to processing (see
              below), where we may have processed your information unlawfully or where we are required to erase your
              personal data to comply with local law. Note, however, that we may not always be able to comply with your
              request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of
              your request.
            </li>
            <li>
              Object to processing of your personal data where we are relying on a legitimate interest (or those of a
              third party) and there is something about your situation which makes you want to object to processing on
              this ground as you feel it impacts your fundamental rights and freedoms. You also have the right to object
              where we are processing your personal data for direct marketing purposes. In some cases, we may
              demonstrate that we have compelling legitimate grounds to process your information which override your
              rights and freedoms.
            </li>
            <li>
              Request restriction of processing of your personal data. This enables you to ask us to suspend the
              processing of your personal data in the following scenarios: (a) if you want us to establish the data's
              accuracy; (b) where our use of the data is unlawful but you do not want us to erase it; (c) where you need
              us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal
              claims; or (d) you have objected to our use of your data but we need to verify whether we have overriding
              legitimate grounds to use it.
            </li>
            <li>
              Request the transfer of your personal data to you or to a third party. We will provide to you, or a third
              party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note
              that this right only applies to automated information which you initially provided consent for us to use
              or where we used the information to perform a contract with you.
            </li>
            <li>
              Withdraw consent at any time where we are relying on consent to process your personal data. However, this
              will not affect the lawfulness of any processing carried out before you withdraw your consent. If you
              withdraw your consent, we may not be able to provide certain products or services to you. We will advise
              you if this is the case at the time you withdraw your consent.
            </li>
          </ul>
          <p>
            You will not have to pay a fee to access your personal data (or to exercise any of the other rights).
            However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive.
            Alternatively, we may refuse to comply with your request in these circumstances.
          </p>
          <p>
            We may need to request specific information from you to help us confirm your identity and ensure your right
            to access your personal data (or to exercise any of your other rights). This is a security measure to ensure
            that personal data is not disclosed to any person who has no right to receive it. We may also contact you to
            ask you for further information in relation to your request to speed up our response.
          </p>
          <p>
            We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a
            month if your request is particularly complex or you have made several requests. In this case, we will
            notify you and keep you updated. If you are situated in the EU and have any complaints regarding our privacy
            practices as a data controller or want to exercise your rights, please contact us please contact our Data
            Protection Officer at privacy@artichokee.com. You may also contact the local Supervisory Authority or seek
            legal remedies.
          </p>
          <p>
            Additionally, if you would like a restaurant to delete information it as collected about you, we encourage
            you to contact the restaurant directly. If you are unsuccessful in reaching the appropriate contact at the
            restaurant, please contact us at privacy@artichokee.com and we will do our best to connect you with the
            right person who can help you.
          </p>
          <p>
            CONTACT US <br />
            Artichokee, Inc. <br />
            <a href='mailto:privacy@artichokee.com'>privacy@artichokee.com</a>
          </p>
        </Col>
      </Row>
    </Container>
  )
}

export default PrivacyAndPolicy

import React, { useState, useContext } from 'react'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Container,
  Row,
  Col,
  Input,
  FormGroup,
} from 'reactstrap'
import { CartContext } from 'contexts/CartContext/CartContext'
import uuidv4 from 'helperFunctions/UUIDGenerator/uuIDGenerator'
import './productmodal.scss'

const ProductModal = ({
  toggle,
  modal,
  singleProduct,
  groupMinMaxQty,
  setSingleProduct,
  singleRestaurant,
  toggleSuccessfulModal,
  singleRequiredList,
}) => {
  const { addProduct, cartItems, addMultipleProduct } = useContext(CartContext)

  const [total, setTotal] = useState(null)
  //totali localstorage da Order a kaydet .....O
  const [checkedOptional, setCheckedOptional] = useState([])
  const [checkedRequired, setCheckedRequired] = useState([])

  const [pairingCheck, setPairingCheck] = useState()

  const [controlReqCheck, setControlReqCheck] = useState(false)

  const [checkerList, setCheckerList] = useState([])
  //CheckerIds tum check edilen liste. Bunu localStorage a eklerken kullanmak zorunlu.
  const [checkerIds, setCheckerIds] = useState([])

  const [instructions, setInstructions] = useState('')

  function checker(arr) {
    let checker = []
    let checkerIds = []

    arr.map((clickedItem, i) => {
      checker.push({
        CheckerGroupName: clickedItem.groupName,
        CheckerCount: clickedItem.count,
        CheckerName: clickedItem.extraItemName,
        CheckerId: clickedItem.id,
      })
    })

    setCheckerIds(arr)

    var checkerMatch = []
    checker.reduce(function (res, value) {
      if (!res[value.CheckerGroupName]) {
        res[value.CheckerGroupName] = {
          CheckerGroupName: value.CheckerGroupName,
          qty: 0,
        }
        checkerMatch.push(res[value.CheckerGroupName])
      }
      res[value.CheckerGroupName].qty += value.CheckerCount

      return res
    }, {})

    let temp1 = []
    checkerMatch.map((x) => {
      groupMinMaxQty.map((y) => {
        if (x.CheckerGroupName === y.GroupName) {
          if (x.qty < y.GroupMaxQty) {
            temp1.push({
              GroupName: x.CheckerGroupName,
              Qty: x.qty,
              control: true,
            })
          } else {
            temp1.push({
              GroupName: x.CheckerGroupName,
              Qty: x.qty,
              control: false,
            })
          }
        }
      })
    })

    //singleProduct.extraItemGroup.items[z].control = obj.control

    singleProduct.ProductExtraItemGroups.items.map((group, k) => {
      let b = temp1.find((el) => el.GroupName === group.ExtraItemGroup.GroupName)

      if (b !== undefined) {
        singleProduct.ProductExtraItemGroups.items[k].ExtraItemGroup.control = b.control
      } else {
        singleProduct.ProductExtraItemGroups.items[k].ExtraItemGroup.control = true
      }
    })
  }
  function checkFunction(checkedItem, e, checkedGroup) {
    //tempArr optional veya required olma durumuna gore tiklanan dalgalarin gruplamasini yapyor.
    let tempArr = []

    if (e.target.checked === true) {
      if (checkedGroup.isOptional === true) {
        setCheckedOptional([
          ...checkedOptional,
          {
            ...checkedItem,
            count: 1,
            groupName: checkedGroup.GroupName,
            optionalMaxItem: checkedGroup.opMaximumExtraItem,
          },
        ])
        tempArr.push(...checkedOptional, ...checkedRequired, {
          ...checkedItem,
          count: 1,
          groupName: checkedGroup.GroupName,
          optionalMaxItem: checkedGroup.opMaximumExtraItem,
        })
        setTotal(total + checkedItem.extraItemPrice)
      } else {
        setCheckedRequired([
          ...checkedRequired,
          {
            ...checkedItem,
            count: 1,
            groupName: checkedGroup.GroupName,
            requiredMinItem: checkedGroup.reqMinimumExtraItem,
            requiredMaxItem: checkedGroup.reqMaximumExtraItem,
          },
        ])
        tempArr.push(...checkedOptional, ...checkedRequired, {
          ...checkedItem,
          count: 1,
          groupName: checkedGroup.GroupName,
          optionalMaxItem: checkedGroup.opMaximumExtraItem,
        })
        setTotal(total + checkedItem.extraItemPrice)
      }
    } else if (e.target.checked === false) {
      if (checkedGroup.isOptional === true) {
        setCheckedOptional(checkedOptional.filter(({ id }) => id !== checkedItem.id))
        let temp = checkedOptional.filter(({ id }) => id !== checkedItem.id)

        tempArr = checkedRequired.concat(temp)

        setTotal(total - checkedItem.extraItemPrice)
      } else {
        setCheckedRequired(checkedRequired.filter(({ id }) => id !== checkedItem.id))
        let temp = checkedRequired.filter(({ id }) => id !== checkedItem.id)

        tempArr = checkedOptional.concat(temp)
      }
    }

    checker(tempArr)
  }
  const [countMenu, setCountMenu] = useState(1)

  function increment() {
    setCountMenu((prevState) => prevState + 1)
  }

  function decrement() {
    if (countMenu > 1) {
      setCountMenu((prevState) => prevState - 1)
    } else {
      setCountMenu(1)
    }
  }

  const calculatePrice = (singleProduct, checkedOptional, checkedRequired) => {
    let extraOptionalPrice = checkedOptional.map((x) => x.count * x.extraItemPrice)
    let extraRequiredPrice = checkedRequired.map((x) => x.count * x.extraItemPrice)

    var sumOptional = extraOptionalPrice.reduce(function (a, b) {
      return a + b
    }, 0)
    var sumRequired = extraRequiredPrice.reduce(function (a, b) {
      return a + b
    }, 0)

    let singleProductPrice = singleProduct !== null ? singleProduct.price : 0

    return countMenu * (sumOptional + sumRequired + singleProductPrice)
  }

  function addLast() {
    if (cartItems <= 1) {
      const tempID = uuidv4()
      let GeneralInformationRestaurant = {
        RestaurantName: singleRestaurant.name,
        RestaurantPaymentId: singleRestaurant.paymentId,
        RestaurantId: singleRestaurant.id,
        RestaurantCity: singleRestaurant.location.city,
        RestaurantState: singleRestaurant.location.state,
        RestaurantStreet: singleRestaurant.location.street,
        RestaurantZipCode: singleRestaurant.location.zipCode,
        RestaurantTax: singleRestaurant.tax,
        RestaurantOwnerMail: singleRestaurant.email,
        RestaurantDescription: singleRestaurant.description,
        RestaurantLogo: singleRestaurant.logo,
        RestaurantPhoneNumber: singleRestaurant.phoneNumber,
        RestaurantStartTime: singleRestaurant.workingHours.openingTime,
        RestaurantEndTime: singleRestaurant.workingHours.closingTime,
        RestaurantWorkingDays: singleRestaurant.workingHours.openingDays,
        RestaurantOwnerName: singleRestaurant.owner,
        //Company Tabledan al
        RestaurantCardPaymentEnabled: singleRestaurant.RestaurantCardPaymentEnabled,
        RestaurantPayatDoorEnabled: singleRestaurant.RestaurantPayatDoorEnabled,
        RestaurantDeliveryEnabled: singleRestaurant.RestaurantDeliveryEnabled,
        RestaurantPickupEnabled: singleRestaurant.RestaurantPickupEnabled,
        RestaurantAsapPickupTimeEnabled: singleRestaurant.RestaurantAsapPickupTimeEnabled,
        RestaurantLaterPickupTimeEnabled: singleRestaurant.RestaurantLaterPickupTimeEnabled,
        RestaurantPrepTime: singleRestaurant.RestaurantPrepTime,
        RestaurantType: singleRestaurant.restaurantType,
      }
      let orderSubTotal = calculatePrice(singleProduct, checkedOptional, checkedRequired)
      let orderTax = parseFloat(orderSubTotal * singleRestaurant.tax)
      let GeneralInformationOrder = {
        OrderOwner: singleRestaurant.owner,
        OrderTotal: parseFloat((orderSubTotal + orderTax).toFixed(2)),
        OrderSubTotal: parseFloat(orderSubTotal.toFixed(2)),
        OrderTax: parseFloat(orderTax.toFixed(2)),
      }
      let GeneralInformationMainMenu = [
        {
          MainMenuTempID: tempID,
          MainMenuId: singleProduct.id,
          MainMenuName: singleProduct.name,
          MainMenuCount: countMenu,
          MainMenuPrice: singleProduct.price,
          MainMenuDescription: singleProduct.description,
          MainMenuDate: '',
          MainMenuStartDate: '',
          MainMenuEndDate: '',
          MainMenuStartTime: '',
          MainMenuEndTime: '',
          MainMenuInstruction: instructions,
          MainMenuIsFixMenu: singleProduct.isFixMenu,
          MainMenuPicture: singleProduct.picture,
        },
      ]
      let GeneralInformationAddOns = []

      if (checkedOptional.length > 0) {
        checkedOptional.map((x, i) => {
          GeneralInformationAddOns.push({
            AddOnsTempID: tempID,
            AddOnsMenuName: singleProduct.name,
            AddOnsName: x.extraItemName,
            AddOnsPrice: x.extraItemPrice,
            AddOnsCount: countMenu,
          })
        })
      }
      if (checkedRequired.length > 0) {
        checkedRequired.map((x, i) => {
          GeneralInformationAddOns.push({
            AddOnsTempID: tempID,
            AddOnsMenuName: singleProduct.name,
            AddOnsName: x.extraItemName,
            AddOnsPrice: x.extraItemPrice,
            AddOnsCount: countMenu,
          })
        })
      }

      let General = [
        {
          GeneralInformationMainMenu,
          GeneralInformationAddOns,
          GeneralInformationRestaurant,
          GeneralInformationOrder,
        },
      ]

      addProduct(General)
    } else {
      const tempID = uuidv4()
      let GeneralInformationRestaurant = {
        RestaurantName: singleRestaurant.name,
        RestaurantPaymentId: singleRestaurant.paymentId,
        RestaurantId: singleRestaurant.id,
        RestaurantCity: singleRestaurant.location.city,
        RestaurantState: singleRestaurant.location.state,
        RestaurantStreet: singleRestaurant.location.street,
        RestaurantZipCode: singleRestaurant.location.zipCode,
        RestaurantTax: singleRestaurant.tax,
        RestaurantOwnerMail: singleRestaurant.email,
        RestaurantDescription: singleRestaurant.description,
        RestaurantLogo: singleRestaurant.logo,
        RestaurantPhoneNumber: singleRestaurant.phoneNumber,
        RestaurantStartTime: singleRestaurant.workingHours.openingTime,
        RestaurantEndTime: singleRestaurant.workingHours.closingTime,
        RestaurantWorkingDays: singleRestaurant.workingHours.openingDays,
        RestaurantOwnerName: singleRestaurant.owner,
        //Company Tabledan al
        RestaurantCardPaymentEnabled: singleRestaurant.RestaurantCardPaymentEnabled,
        RestaurantPayatDoorEnabled: singleRestaurant.RestaurantPayatDoorEnabled,
        RestaurantDeliveryEnabled: singleRestaurant.RestaurantDeliveryEnabled,
        RestaurantPickupEnabled: singleRestaurant.RestaurantPickupEnabled,
        RestaurantAsapPickupTimeEnabled: singleRestaurant.RestaurantAsapPickupTimeEnabled,
        RestaurantLaterPickupTimeEnabled: singleRestaurant.RestaurantLaterPickupTimeEnabled,
        RestaurantPrepTime: singleRestaurant.RestaurantPrepTime,
        RestaurantType: singleRestaurant.restaurantType,
      }
      let orderSubTotal = calculatePrice(singleProduct, checkedOptional, checkedRequired)
      let orderTax = parseFloat(orderSubTotal * singleRestaurant.tax)
      let GeneralInformationOrder = {
        OrderOwner: singleRestaurant.owner,
        OrderTotal: parseFloat((orderSubTotal + orderTax).toFixed(2)),
        OrderSubTotal: parseFloat(orderSubTotal.toFixed(2)),
        OrderTax: parseFloat(orderTax.toFixed(2)),
      }
      let GeneralInformationMainMenu = {
        MainMenuTempID: tempID,
        MainMenuId: singleProduct.id,
        MainMenuName: singleProduct.name,
        MainMenuCount: countMenu,
        MainMenuPrice: singleProduct.price,
        MainMenuDescription: singleProduct.description,
        MainMenuDate: '',
        MainMenuStartDate: '',
        MainMenuEndDate: '',
        MainMenuStartTime: '',
        MainMenuEndTime: '',
        MainMenuInstruction: instructions,
        MainMenuIsFixMenu: singleProduct.isFixMenu,
        MainMenuPicture: singleProduct.picture,
      }
      let GeneralInformationAddOns = []
      if (checkedOptional.length > 0) {
        checkedOptional.map((x, i) => {
          GeneralInformationAddOns.push({
            AddOnsTempID: tempID,
            AddOnsMenuName: singleProduct.name,
            AddOnsName: x.extraItemName,
            AddOnsPrice: x.extraItemPrice,
            AddOnsCount: countMenu,
          })
        })
      }
      if (checkedRequired.length > 0) {
        checkedRequired.map((x, i) => {
          GeneralInformationAddOns.push({
            AddOnsTempID: tempID,
            AddOnsMenuName: singleProduct.name,
            AddOnsName: x.extraItemName,
            AddOnsPrice: x.extraItemPrice,
            AddOnsCount: countMenu,
          })
        })
      }

      let General = [
        {
          GeneralInformationMainMenu,
          GeneralInformationAddOns,
          GeneralInformationRestaurant,
          GeneralInformationOrder,
        },
      ]

      addMultipleProduct(General)
    }
  }

  function handleAddToStorage() {
    let checkedIDs = []

    let requiredListIDs = []
    checkedRequired.map((x) => {
      checkedIDs.push(x)
    })
    singleRequiredList.map((group) => {
      group.ExtraItemGroup.ExtraItemGroupExtraItems.items.map((item) => {
        requiredListIDs.push(item.ExtraItem)
      })
    })

    //checkedIDs listesi modalda clicklenenler icin
    //requiredListIDs listesi modal acilmadan required olanlarin listesi
    //1.durum : requiredListIDs eger bos ise hic bakmaya gerek yok
    //2.durum : requiredListIDs dolu checkedIDs bos ise
    //          demekki hic bir req alan isaretlenmedi
    //          Yapilmasi gereken
    //3.durum : requiredListIDs bos degil ise ve checkedIDs bos degil ise
    //          checkedIDs icindeki elemanlar requiredListIDs de var mi
    let newArr = []
    if (singleRequiredList.length === 0) {
      addLast()
      toggle()
      setSingleProduct(null)
      setTotal(0)
      setCheckedOptional([])
      setCheckedRequired([])
      setCountMenu(1)
      toggleSuccessfulModal()
    } else if (singleRequiredList.length !== 0 && checkedIDs.length === 0) {
      //When the add to cart button is clicked, we scroll to the element that has required group.id.
      scrollToTopRequiredElement(singleRequiredList[0])
      setControlReqCheck(true)
    } else if (singleRequiredList.length === 1 && checkedIDs.length === 1) {
      addLast()
      toggle()
      setSingleProduct(null)
      setTotal(0)
      setCheckedOptional([])
      setCheckedRequired([])
      setCountMenu(1)
      toggleSuccessfulModal()
    }
    //sadece 1 adet secilme durumunu ayri kontrol etmek gerekiyor
    else if (singleRequiredList.length > 1 && checkedIDs.length === 1) {
      let tempPairingList = []
      singleRequiredList.map((x, i) => {
        if (x.GroupName === checkedIDs[0].groupName) {
          x.extraItems.items.map((y, k) => {
            if (y.id === checkedIDs[0].id) {
              tempPairingList = checkedIDs[0]
            }
          })
        }
      })
      //Kontrol yapisini tam oturt

      setPairingCheck(tempPairingList)
    } else {
      addLast()
      toggle()
      setSingleProduct(null)
      setTotal(0)
      setCheckedOptional([])
      setCheckedRequired([])
      setCountMenu(1)
      toggleSuccessfulModal()
    }
    if (controlReqCheck) scrollToTopRequiredElement(singleRequiredList[0])
  }
  function scrollToTopRequiredElement(singleRequiredList) {
    document.getElementById(singleRequiredList.id).scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <div>
      {singleProduct && (
        <Modal
          id='product-modal'
          className='temp-village-modal-cont'
          isOpen={modal}
          onClosed={() => {
            setSingleProduct(null)
            setTotal(0)
            setCheckedOptional([])
            setCheckedRequired([])
            setCountMenu(1)
            setInstructions('')
            setControlReqCheck(false)
          }}
          toggle={() => {
            toggle()
          }}>
          <ModalHeader toggle={toggle} className='temp-village-modal-header'></ModalHeader>
          <ModalBody className='temp-village-modal-body'>
            <div className='name-description-cont'>
              <h2 className='name'>{singleProduct.name}</h2>
              <span className='description'>{singleProduct.description}</span>
            </div>
            <div className='img-cont'>
              <img src={singleProduct.picture !== '' ? singleProduct.picture : null}></img>
            </div>
            {singleProduct === null
              ? null
              : singleProduct.ProductExtraItemGroups.items.map((group, i) => {
                  return (
                    <div>
                      {pairingCheck && group.ExtraItemGroup.GroupName !== pairingCheck.groupName ? (
                        <div
                          id={group.id}
                          style={
                            controlReqCheck === true
                              ? group.ExtraItemGroup.isOptional === false
                                ? {
                                    border: '2px solid #C09850',
                                    borderRadius: '5px',
                                  }
                                : null
                              : null
                          }
                          className='add-on-group'>
                          <div>
                            <span className='add-on-group-name'>{group.ExtraItemGroup.GroupName}</span>
                            <span className='add-on-limit'>
                              {group.ExtraItemGroup.isOptional === true
                                ? 'Select up to ' + group.ExtraItemGroup.opMaximumExtraItem
                                : 'Select up to ' + group.ExtraItemGroup.reqMaximumExtraItem}
                            </span>
                          </div>
                          <div
                            style={
                              controlReqCheck && group.ExtraItemGroup.isOptional === false
                                ? {
                                    backgroundColor: 'rgb(254, 255, 88)',
                                    color: 'rgb(118, 118, 118)',
                                  }
                                : null
                            }
                            className='optional-required-cont'>
                            <span className='optional-required'>
                              {group.ExtraItemGroup.isOptional === true ? 'Optional' : 'Required'}
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div
                          id={group.id}
                          style={
                            controlReqCheck === true
                              ? group.ExtraItemGroup.isOptional === false
                                ? {
                                    border: '2px solid #C09850',
                                    borderRadius: '5px',
                                  }
                                : null
                              : null
                          }
                          className='add-on-group'>
                          <div>
                            <span className='add-on-group-name'>{group.ExtraItemGroup.GroupName}</span>
                            <span className='add-on-limit'>
                              {group.ExtraItemGroup.isOptional === true
                                ? 'Select up to ' + group.ExtraItemGroup.opMaximumExtraItem
                                : 'Select up to ' + group.ExtraItemGroup.reqMaximumExtraItem}
                            </span>
                          </div>
                          <div
                            style={
                              controlReqCheck && group.ExtraItemGroup.isOptional === false
                                ? {
                                    backgroundColor: 'rgb(254, 255, 88)',
                                    color: 'rgb(118, 118, 118)',
                                  }
                                : null
                            }
                            className='optional-required-cont'>
                            <span className='optional-required'>
                              {group.ExtraItemGroup.isOptional === true ? 'Optional' : 'Required'}
                            </span>
                          </div>
                        </div>
                      )}

                      <Container className='choice-cont'>
                        {group.ExtraItemGroup.control === true
                          ? group.ExtraItemGroup.ExtraItemGroupExtraItems.items.map((extraItem) => (
                              <>
                                <Row className='choice-row ml-0 mr-0'>
                                  <Col xl='9' lg='9' md='9' sm='9' xs='9' className='pl-0 pr-0'>
                                    <Label>
                                      <Input
                                        id={group.ExtraItemGroup.id}
                                        className='choice-checkbox'
                                        type='checkbox'
                                        onClick={(e) => checkFunction(extraItem.ExtraItem, e, group.ExtraItemGroup)}
                                      />

                                      <span className='choice-name'>{extraItem.ExtraItem.extraItemName}</span>
                                    </Label>
                                  </Col>

                                  <Col xl='3' lg='3' md='3' sm='3' xs='3' className='pl-0 pr-0 choice-price'>
                                    <span>
                                      {extraItem.ExtraItem.extraItemPrice !== 0
                                        ? '+$' + parseFloat(extraItem.ExtraItem.extraItemPrice).toFixed(2)
                                        : ''}
                                    </span>
                                  </Col>
                                </Row>
                                <hr className='choice-hr'></hr>
                              </>
                            ))
                          : group.ExtraItemGroup.ExtraItemGroupExtraItems.items.map((extraItem) =>
                              checkerIds.find((element) => element.id === extraItem.ExtraItem.id) ? (
                                <>
                                  <Row className='choice-row ml-0 mr-0'>
                                    <Col xl='9' lg='9' md='9' sm='9' xs='9' className='pl-0 pr-0'>
                                      <Label>
                                        <Input
                                          id={group.ExtraItemGroup.id}
                                          className='choice-checkbox'
                                          type='checkbox'
                                          onClick={(e) => checkFunction(extraItem.ExtraItem, e, group.ExtraItemGroup)}
                                        />

                                        <span className='choice-name'>{extraItem.ExtraItem.extraItemName}</span>
                                      </Label>
                                    </Col>

                                    <Col xl='3' lg='3' md='3' sm='3' xs='3' className='pl-0 pr-0 choice-price'>
                                      <span>
                                        {extraItem.ExtraItem.extraItemPrice !== 0
                                          ? '+$' + parseFloat(extraItem.ExtraItem.extraItemPrice).toFixed(2)
                                          : ''}
                                      </span>
                                    </Col>
                                  </Row>
                                  <hr className='choice-hr'></hr>
                                </>
                              ) : (
                                <>
                                  <Row className='choice-row ml-0 mr-0'>
                                    <Col xl='9' lg='9' md='9' sm='9' xs='9' className='pl-0 pr-0'>
                                      <Label>
                                        <Input
                                          id={group.ExtraItemGroup.id}
                                          className='choice-checkbox'
                                          type='checkbox'
                                          disabled={true}
                                          style={{ cursor: 'not-allowed' }}
                                          onClick={(e) => checkFunction(extraItem.ExtraItem, e, group.ExtraItemGroup)}
                                        />

                                        <span className='choice-name' style={{ cursor: 'not-allowed' }}>
                                          {extraItem.ExtraItem.extraItemName}
                                        </span>
                                      </Label>
                                    </Col>

                                    <Col xl='3' lg='3' md='3' sm='3' xs='3' className='pl-0 pr-0 choice-price'>
                                      <span>
                                        {extraItem.ExtraItem.extraItemPrice !== 0
                                          ? '+$' + parseFloat(extraItem.ExtraItem.extraItemPrice).toFixed(2)
                                          : ''}
                                      </span>
                                    </Col>
                                  </Row>
                                  <hr className='choice-hr'></hr>
                                </>
                              )
                            )}
                      </Container>
                    </div>
                  )
                })}

            <div className='inst-cont'>
              <b>Preferences</b>
              <textarea
                name='instruction'
                rows='3'
                cols='50'
                placeholder='Type your instructions here...'
                onChange={(e) => setInstructions(e.target.value)}></textarea>
            </div>
          </ModalBody>
          <ModalFooter className='temp-village-modal-footer'>
            <Container>
              <Row>
                <Col xs='2' className='text-center pl-sm-0 pr-sm-0'>
                  <Button className='minus-plus' onClick={() => decrement()}>
                    -
                  </Button>
                </Col>
                <Col xs='2' className='menu-count pl-sm-0 pr-sm-0'>
                  {countMenu}
                </Col>
                <Col xs='2' className='text-center pl-sm-0 pr-sm-0'>
                  <Button className='minus-plus' onClick={() => increment()}>
                    +
                  </Button>
                </Col>

                <Col xs='6'>
                  <Button
                    className='total-price'
                    onClick={() => {
                      handleAddToStorage()
                    }}>
                    Add To Cart
                    {' $' + calculatePrice(singleProduct, checkedOptional, checkedRequired).toFixed(2)}
                  </Button>
                </Col>
              </Row>
            </Container>
          </ModalFooter>
        </Modal>
      )}
    </div>
  )
}

export default ProductModal

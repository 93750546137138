import { AddOnPriceCalculate } from '../PriceCalculate/PriceCalculate'
export default function getOrderTotal(restaurantType, cartItems) {
  if (restaurantType === 'type2') return cartItems.GeneralInformationOrder.OrderTotal
  return parseFloat(
    (
      cartItems.GeneralInformationMainMenu.MainMenuCount * cartItems.GeneralInformationMainMenu.MainMenuPrice +
      AddOnPriceCalculate(cartItems.GeneralInformationAddOns, 1)
    ).toFixed(2)
  )
}

import React, { useState, useEffect } from 'react'
import { Jumbotron, Button, Container, Row, Col } from 'reactstrap'
import moment from 'moment'
import { API, graphqlOperation } from 'aws-amplify'
import { updateOrder } from 'graphql/custom-mutations'
//Scss Imports
import './ordercard.scss'
const OrderCard = (props) => {
  const [order, setOrder] = useState(props.orders)

  async function handleCancel(event) {
    try {
      const res = await API.graphql(
        graphqlOperation(updateOrder, {
          input: {
            id: event.id,
            orderStatus: 'cancel',
          },
        })
      )
      const updatedOrder = res.data.updateOrder
      setOrder((prev) => {
        return {
          ...prev,
          orderStatus: updatedOrder.orderStatus,
        }
      })
    } catch (err) {
      console.log('orderError', err)
    }
  }

  function createOrderStatusMessage(order) {
    if (order.owner === 'villageburger') {
      if (order.orderStatus === 'pending') {
        return 'Your order is in the waiting stage'
      }
    } else {
      if (order.orderStatus === 'pending') {
        return `You can cancel your order before ${moment(order.pickUpDate).format('MM.DD.YYYY')}`
      } else if (order.orderStatus === 'cancel') {
        return `Your order has been cancelled.`
      } else if (order.orderStatus === 'reject') {
        return `Your order has been rejected.`
      } else if (order.orderStatus === 'accept') {
        return `You can pick up your order at ${order.pickUpTime.toLowerCase()} on ${moment(order.pickUpDate).format(
          'MM.DD.YYYY'
        )}`
      } else if (order.orderStatus === 'fullfield') {
        return `Your order has been delivered.`
      }
    }
  }
  return (
    <Container className='order-card-cont'>
      <Row className='order-header'>
        <Container>
          <Row>
            <Col xl='6' lg='6' md='6' sm='6' xs='6'>
              <span className='point'>10.0</span> <span className='rest-name'>{order.company.name}</span>
            </Col>
            <Col className='d-flex justify-content-end' xl='6' lg='6' md='6' sm='6' xs='6'>
              <div className='order-date'>Order Date: {moment(order.createdAt).format('MMM Do YYYY, h:mm:ss a')} </div>
            </Col>
          </Row>
        </Container>
      </Row>
      <Row className='order-body'>
        <Container>
          <Row className='order-details'>
            <Col className='p-0' xl='6' lg='6' md='6' sm='6' xs='6'>
              <div>
                {order.menu.map((mainMenu) => {
                  return (
                    <div key={mainMenu.mainMenuTempID}>
                      <strong className='menu-name'>{`${mainMenu.mainMenuName} x${mainMenu.mainMenuCount}`}</strong>
                      {order.menuItem.map((addOn) => {
                        return (
                          <>
                            {' '}
                            {mainMenu.mainMenuTempID === addOn.addOnsTempID ? (
                              <p className='extra-item'>{`${addOn.addOnsName} x${addOn.addOnsCount}`}</p>
                            ) : null}
                          </>
                        )
                      })}
                      <hr />
                    </div>
                  )
                })}
              </div>
              <div className='total-price'>
                Total : <strong> ${parseFloat(order.totalPrice).toFixed(2)}</strong>
              </div>
            </Col>
            <Col className='text-right' xl='6' lg='6' md='6' sm='6' xs='6'>
              {order.orderStatus === 'pending' ? (
                <button className='cancel-btn' onClick={(e) => handleCancel(order)}>
                  Cancel Order
                </button>
              ) : null}
            </Col>
          </Row>
          <Row className='order-history-detail'>
            <Col className='pending-message'>{createOrderStatusMessage(order)}</Col>
          </Row>
        </Container>
      </Row>
    </Container>
  )
}
export default OrderCard

import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col } from 'reactstrap'
import SocialLinks from 'Components/SocialLinks/SocialLinks'
//Scss Imports
import './restaurantdetail.scss'
// Helper Functions
import { getDefaultLocation } from 'helperFunctions/GetDefaultLocation/GetDefaultLocation'

const RestaurantDetail = ({ restaurant, isDetail }) => {
  const restDetailCustomStyle = {
    border: 'none',
    boxShadow: 'none',
  }
  const defaultLocation = getDefaultLocation(restaurant.location)
  const operationDayFormat = () => {
    let dayRange = []
    let minDayNum = null
    let maxDayNum = null
    let days = restaurant.workingHours.openingDays
    let tempDays = []
    days.forEach((day) => {
      tempDays.push(day.value)
    })
    minDayNum = Math.min(...tempDays)
    maxDayNum = Math.max(...tempDays)
    days.forEach((day) => {
      if (day.value === minDayNum) {
        dayRange.push(day.label)
      }
      if (day.value === maxDayNum) {
        dayRange.push(day.label)
      }
    })
    return dayRange
  }
  const dateFormat = (date) => {
    var hours = parseInt(date.slice(0, 2))
    var minutes = parseInt(date.slice(3))
    // Check whether AM or PM
    var newformat = hours >= 12 ? 'pm' : 'am'

    // Find current hour in AM-PM Format
    hours = hours % 12

    // To display "0" as "12"
    hours = hours ? hours : 12
    minutes = minutes < 10 ? '0' + minutes : minutes

    return hours + ':' + minutes + '' + newformat
  }

  function isBetween(n, a, b) {
    return (n - a) * (n - b) <= 0
  }

  // function isOpenorClose(filtRestaurant) {
  //   const opDay = filtRestaurant.workingHours.openingDate;
  //   const closeDay = filtRestaurant.workingHours.closingDate;
  //   var objToday = new Date(),
  //     weekday = new Array(
  //       "Monday",
  //       "Tuesday",
  //       "Wednesday",
  //       "Thursday",
  //       "Friday",
  //       "Saturday",
  //       "Sunday"
  //     ),
  //     dayOfWeek = weekday[objToday.getDay()];

  //   let opp = weekday.indexOf(opDay);
  //   let close = weekday.indexOf(closeDay);
  //   let dayOfWeek1 = weekday.indexOf(dayOfWeek);

  //   return isBetween(dayOfWeek1, opp, close);
  // }

  return (
    <Row style={isDetail ? restDetailCustomStyle : null} className='single-rest-row'>
      <Col className='p-0' xl='3' lg='3' md='3' sm='3' xs='12'>
        {isDetail ? (
          <div className='rest-logo'>
            <img src={restaurant.logo} alt='company-logo'></img>
          </div>
        ) : (
          <Link to={`/${restaurant.urlStub}`}>
            <div className='rest-logo'>
              <img src={restaurant.logo} alt='company-logo'></img>
            </div>
          </Link>
        )}
      </Col>

      <Col xl='5' lg='5' md='5' sm='5' xs='12'>
        <div className='middle-cont'>
          <div>
            {isDetail ? (
              <h2>{restaurant.name}</h2>
            ) : (
              <Link className='rest-name' to={`/${restaurant.urlStub}`}>
                <h2>{restaurant.name}</h2>
              </Link>
            )}
            <div className='phone-cont'>
              <i className='fas fa-phone-alt'></i>
              <a href={'tel:+' + restaurant.phoneNumber}>
                <span>
                  {'+' +
                    restaurant.phoneNumber.slice(0, 1) +
                    ' (' +
                    restaurant.phoneNumber.slice(1, 4) +
                    ') ' +
                    restaurant.phoneNumber.slice(4, 7) +
                    '-' +
                    restaurant.phoneNumber.slice(7, 11)}
                </span>
              </a>
            </div>
          </div>
          <p>{restaurant.description}</p>
          <div className='food-type'>
            <ul>
              <li>{restaurant.foodType.typeOne}</li>
              <li>{restaurant.foodType.typeTwo}</li>
              <li>{restaurant.foodType.typeThree}</li>
            </ul>
          </div>
        </div>
      </Col>
      <Col className='py-0 pr-3' xl='4' lg='4' md='4' sm='4' xs='12'>
        <div className='end-cont'>
          <div className='comp-type'>
            {restaurant.companyType.map((type, index) => {
              return (
                <span
                  key={index}
                  className='mr-1'
                  style={{
                    backgroundColor:
                      type.label === 'Pickup' ? '#148F00' : type.label === 'Delivery' ? '#FF4C01' : '#0278ae',
                  }}>
                  {type.label}
                </span>
              )
            })}
          </div>
          <div className='work-hours'>
            <div>
              <span>
                <u>Hours of Operation:</u>
              </span>
            </div>

            <div>
              <p>
                {restaurant.workingHours.openingDays.length > 1
                  ? operationDayFormat()[0] + '-' + operationDayFormat()[1]
                  : operationDayFormat()[0]}
              </p>
            </div>
            <div>
              <span>{restaurant.workingHours.openingTime} - </span>
              <span>{restaurant.workingHours.closingTime}</span>
            </div>
          </div>
          <div className='rest-loc'>
            <p className='m-0'>
              <span>{defaultLocation.street},</span>
            </p>
            <p className='m-0'>
              <span>{defaultLocation.city}, </span>
              <span>{defaultLocation.state}, </span>
              <span>{defaultLocation.zipCode}</span>
            </p>
          </div>
          <SocialLinks restaurant={restaurant} />
        </div>
      </Col>
    </Row>
  )
}
export default RestaurantDetail

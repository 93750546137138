import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import './categorymenucard.scss'
function CategoryMenuCard({ product, openProductModal }) {
  return (
    <div className='category-menu-card' onClick={() => openProductModal(product)}>
      <Container>
        <Row>
          <Col className='p-3' xl='8' lg='8' md='8' sm='8' xs='8'>
            <div>
              <div>
                <span className='menu-name'>{product.name}</span>
              </div>
              <span className='description'>{product.description}</span>
              <div className='price-cont'>
                <span className='price'> ${parseFloat(product.price).toFixed(2)}</span>
              </div>
            </div>
          </Col>
          <Col className='p-0' xl='4' lg='4' md='4' sm='4' xs='4'>
            <div>
              {product.picture !== null && product.picture !== '' ? (
                <img className='menu-img' src={product.picture} alt='burger-img'></img>
              ) : (
                <div className='menu-img'></div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default CategoryMenuCard

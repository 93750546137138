import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import './successfulmodal.scss'
import logo from '../../assets/images/success-m-logo.svg'
const SuccessfulModal = (props) => {
  return (
    <Modal
      className='successful-modal-completed-order'
      onClosed={props.onClosed}
      isOpen={props.modal}
      toggle={props.toggle}
    >
      <ModalHeader
        className='modal-header'
        toggle={props.toggle}
      ></ModalHeader>
      <ModalBody className='d-flex justify-content-center flex-column successful-modal-body'>
        <div>
          <img src={logo}></img>
        </div>
        <b>Thank you for your order!</b>
        <p>
          You will receive an email once {props.restName} has confirmed your
          order.
        </p>
      </ModalBody>
    </Modal>
  )
}

export default SuccessfulModal

import React, { useContext, useState, useEffect } from 'react'
import { Container, Row, Col, Input, FormGroup, Form } from 'reactstrap'
import { CartContext } from '../../contexts/CartContext/CartContext'
import { UserContext } from '../../contexts/UserContext/UserContext'
import { API, graphqlOperation, Auth } from 'aws-amplify'
import { createOrder } from 'graphql/custom-mutations'
import {
  AddOnPriceCalculate,
  MainMenuPriceCalculate,
} from '../../helperFunctions/PriceCalculate/PriceCalculate'

import SuccessfulModal from './SuccessfulModal'

import 'react-phone-input-2/lib/style.css'
//Scss Imports
import './orderpage.scss'

// STRIPE
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import StripeMiddleware from '../../Components/StripeMiddleware/StripeMiddleware'
// const stripePromise = loadStripe(
//   'pk_test_51HntzmKWYJ4D9DWpYmWsJfN45BipRAFtfkhD8DHSBuM7mspz8PPQ6eYQrcAvNRTK0uRL6WaN8W9cLXJokuw6D0P600MaN68g69',
//   { stripeAccount: 'acct_1I5G4w4E6sniNwBf' }
// )
const OrderPage = (props) => {
  const { clearCart, cartItems } = useContext(CartContext)
  const [localCartItems, setLocalCartItems] = useState(cartItems[0])

  const stripePromise = React.useMemo(
    () =>
      cartItems.length !== 0
        ? localCartItems.GeneralInformationRestaurant.RestaurantPaymentId
          ? loadStripe(
              'pk_live_51HntzmKWYJ4D9DWptKYWW2WXZsIutE1G7cg96fXM90EI7YT4EUeMaCijMq9wHHXNZS8PUXDoToTaHudX3P0qDRtk00DcCwDlua',
              {
                stripeAccount:
                  cartItems.length !== 0
                    ? localCartItems.GeneralInformationRestaurant
                        .RestaurantPaymentId
                    : null,
              }
            )
          : null
        : null,
    [
      cartItems.length,
      localCartItems.GeneralInformationRestaurant.RestaurantPaymentId,
    ]
  )
  const { user, setIsUserDataBlank } = useContext(UserContext)
  const [subTotalPrice, setSubTotalPrice] = useState()
  const [totalPrice, setTotalPrice] = useState()
  const [order, setOrder] = useState(calculateOrder)
  const [paymentOption, setPaymentOption] = useState('payatdoor')
  const [creditCardCollapse, setCreditCardCollapse] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [recap, setRecap] = useState()
  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)

  function calculateOrder() {
    if (localCartItems !== undefined && user) {
      let addOnsCount = 0
      localCartItems.GeneralInformationAddOns.map((x) => {
        addOnsCount += x.AddOnsCount
      })

      let MainMenu = {
        mainMenuId: localCartItems.GeneralInformationMainMenu.MainMenuId,
        mainMenuCount: localCartItems.GeneralInformationMainMenu.MainMenuCount,
        mainMenuInstruction:
          localCartItems.GeneralInformationMainMenu.MainMenuInstruction,
        mainMenuName: localCartItems.GeneralInformationMainMenu.MainMenuName,
        mainMenuPrice: localCartItems.GeneralInformationMainMenu.MainMenuPrice,
        mainMenuTempID: localCartItems.GeneralInformationMainMenu.MainMenuId,
      }

      let addOns = []
      localCartItems.GeneralInformationAddOns.map((x) => {
        //Onceden orderin icine menunun butun itemlarini gondermisiz.a
        // Eger eklendiyse gondermemiz lazim ??
        if (x.AddOnsCount > 0) {
          addOns.push({
            addOnsCount: x.AddOnsCount,
            addOnsName: x.AddOnsName,
            addOnsPrice: x.AddOnsPrice,
            addOnsTempID: localCartItems.GeneralInformationMainMenu.MainMenuId,
          })
        }
      })

      const addOnsPrice = AddOnPriceCalculate(addOns)
      const mainMenuPrice = MainMenuPriceCalculate(MainMenu)

      setSubTotalPrice((addOnsPrice + mainMenuPrice).toFixed(2))
      setTotalPrice((addOnsPrice + mainMenuPrice).toFixed(2))

      // console.log(typeof +(addOnsPrice + mainMenuPrice).toFixed(2))
      // BU + BURAYI GERI NUMBERA CEVIRIYOR AMA PERFORMANS OLARAK BU ISLEM KOTU
      //ALTERNATIF BULMAK LAZIM.
      //TOFIXED(2) STRING YAPIYOR GERI NUMBER LAZIM BIZE SORUN BU

      const orderInput = {
        subTotalPrice: +(addOnsPrice + mainMenuPrice).toFixed(2),
        taxPrice: localCartItems.GeneralInformationRestaurant.RestaurantTax,
        totalPrice: +(addOnsPrice + mainMenuPrice).toFixed(2),
        mainMenusCount: localCartItems.GeneralInformationMainMenu.MainMenuCount,
        mainAddOnsCount: addOnsCount,
        customerName: user.attributes.given_name,
        customerPhone: user.attributes.phone_number,
        customerEmail: user.attributes.email,
        pickUpDate: localCartItems.GeneralInformationMainMenu.MainMenuDate,
        pickUpTime:
          localCartItems.GeneralInformationMainMenu.MainMenuPickUpTime,
        orderStatus: 'pending',
        paymentStatus: 'payatdoor',
        paymentIntentId: '',
        owner: localCartItems.GeneralInformationOrder.OrderOwner,
        menu: MainMenu,
        menuItem: addOns,
        orderCompanyId:
          localCartItems.GeneralInformationRestaurant.RestaurantId,
        locationID:
          localCartItems.GeneralInformationRestaurant.RestaurantLocationID,
      }

      return orderInput
    }

    // setOrder(orderInput)
  }

  async function addTodo(e) {
    e.preventDefault()
    setProcessing(true)
    try {
      const res = await API.graphql(
        graphqlOperation(createOrder, { input: order })
      )

      sendMailCustomer()
      sendMailOwner()
      toggle()
    } catch (err) {
      console.log('orderError', err)
    }
  }
  const sendMailCustomer = async () => {
    const itemAddOns = []
    if (order.menuItem !== []) {
      order.menuItem.map((item) => {
        if (item.addOnsCount > 0) {
          itemAddOns.push({
            addOnsName: item.addOnsName,
            addOnsCount: item.addOnsCount,
            addOnsPrice: item.addOnsPrice,
            addOnsTempID: item.addOnsTempID,
          })
        }
      })
    }
    const menusMap = []

    menusMap.push({
      mainMenuId: order.menu.mainMenuId,
      mainMenuInstruction: order.menu.mainMenuInstruction,
      mainMenuName: order.menu.mainMenuName,
      mainMenuPrice: order.menu.mainMenuPrice,
      mainMenuTempID: order.menu.mainMenuTempID,
      mainMenuCount: order.menu.mainMenuCount,
    })

    const initialOrder = {
      customerName: order.customerName,
      paymentStatus: order.paymentStatus,
      restaurantName:
        localCartItems.GeneralInformationRestaurant.RestaurantName,
      pickUpDate: order.pickUpDate,
      pickUpTime: order.pickUpTime, // PickUpTime undefined oldugu icin.
      totalPrice: order.totalPrice,
      //Company Logo Siparis verilen restauranttan gelmeli (location gibi)
      companyLogo:
        'https://artichokee-ses-email-assets.s3.amazonaws.com/navbar-renzosfire.png',
      //artichokee logo boyle gelecek sorun yok. Sonra html icine koyariz bunu hep ayni cunku
      artichokeeLogo:
        'https://artichokee-ses-email-assets.s3.amazonaws.com/artichokee_logo_128x128_stripe.png',
      menuItem: itemAddOns,
      menu: menusMap,
      isPaid: order.paymentStatus === 'payatdoor' ? false : true,
      restaurantOwner: 'Teresa Acosta',
      addressName:
        localCartItems.GeneralInformationRestaurant.RestaurantAddressName,
      state: localCartItems.GeneralInformationRestaurant.RestaurantState,
      city: localCartItems.GeneralInformationRestaurant.RestaurantCity,
      street: localCartItems.GeneralInformationRestaurant.RestaurantStreet,
      zipCode: localCartItems.GeneralInformationRestaurant.RestaurantZipCode,
    }
    const myInit = {
      to: order.customerEmail,
      from: 'info@artichokee.com',
      replyTo: localCartItems.GeneralInformationRestaurant.RestaurantOwnerMail,
      order: JSON.stringify(initialOrder),
      mailTemplate: 'customer14',
    }

    try {
      let apiName = 'mail'
      let path = '/mail'
      const user = await Auth.currentAuthenticatedUser()
      const token = user.signInUserSession.idToken.jwtToken
      let request = {
        body: { myInit },
        // headers: {
        //   Authorization: token,
        // },
      }
      const mailData = await await API.post(apiName, path, request)
    } catch (error) {
      console.log([error])
    }
  }

  const sendMailOwner = async () => {
    const itemAddOns = []
    if (order.menuItem !== []) {
      order.menuItem.map((item) => {
        if (item.addOnsCount > 0) {
          itemAddOns.push({
            addOnsName: item.addOnsName,
            addOnsCount: item.addOnsCount,
            addOnsPrice: item.addOnsPrice,
            addOnsTempID: item.addOnsTempID,
          })
        }
      })
    }
    const menusMap = []

    menusMap.push({
      mainMenuId: order.menu.mainMenuId,
      mainMenuInstruction: order.menu.mainMenuInstruction,
      mainMenuName: order.menu.mainMenuName,
      mainMenuPrice: order.menu.mainMenuPrice,
      mainMenuTempID: order.menu.mainMenuTempID,
      mainMenuCount: order.menu.mainMenuCount,
    })
    const initialOrder = {
      customerName: order.customerName,
      customerEmail: order.customerEmail,
      customerPhone: order.customerPhone,
      paymentStatus: order.paymentStatus,
      restaurantName:
        localCartItems.GeneralInformationRestaurant.RestaurantName,
      pickUpDate: order.pickUpDate,
      pickUpTime: order.pickUpTime, // PickUpTime undefined oldugu icin.
      totalPrice: order.totalPrice,
      //Company Logo Siparis verilen restauranttan gelmeli (location gibi)
      companyLogo:
        'https://artichokee-ses-email-assets.s3.amazonaws.com/navbar-renzosfire.png',
      //artichokee logo boyle gelecek sorun yok. Sonra html icine koyariz bunu hep ayni cunku
      artichokeeLogo:
        'https://artichokee-ses-email-assets.s3.amazonaws.com/artichokee_logo_128x128_stripe.png',
      menuItem: itemAddOns,
      menu: menusMap,
      isPaid: order.paymentStatus === 'payatdoor' ? false : true,
      restaurantOwner: 'Teresa Acosta', // Company tablosunda restaurant owner ismini tutalim. Zorunlu olmasin eger owner ismini vermek istemezse default olarak restaurantin adiyla ayni yapalim.
      state: localCartItems.GeneralInformationRestaurant.RestaurantState,
      city: localCartItems.GeneralInformationRestaurant.RestaurantCity,
      street: localCartItems.GeneralInformationRestaurant.RestaurantStreet,
      zipCode: localCartItems.GeneralInformationRestaurant.RestaurantZipCode,
    }
    const myInit = {
      to: localCartItems.GeneralInformationRestaurant.RestaurantOwnerMail,
      from: 'info@artichokee.com',
      subject: `SUBJECT`,
      text: `TEXT`,
      replyTo: 'canberk@prplbx.com', // restaurant owner ulasmak isterse restaurants@artichokee.com falan yapilabilir.
      order: JSON.stringify(initialOrder),
      mailTemplate: 'owner8',
    }
    try {
      let apiName = 'mail'
      let path = '/mail'
      const user = await Auth.currentAuthenticatedUser()
      const token = user.signInUserSession.idToken.jwtToken
      let request = {
        body: { myInit },
        // headers: {
        //   Authorization: token,
        // },
      }
      const mailData = await API.post(apiName, path, request)
    } catch (error) {
      console.log([error])
    }
  }

  function onChange(value) {
    setRecap(value)
  }
  const onClosed = () => {
    props.history.push('/')
    clearCart()
  }
  //User yoksa direk auth sayfasina yonlendiriyoruz
  const noUser = () => {
    props.history.push('/auth')
    // clearCart()
    //Kullanici giris yapmadan sepetine urun ekledi, SOnra giris yapti sepeti bosalmasin.
  }

  useEffect(() => {
    if (user) {
      if (
        user.attributes.phone_number === undefined ||
        user.attributes.given_name === undefined ||
        user.attributes.email === undefined
      ) {
        setIsUserDataBlank(true)
        props.history.push('/account')
      } else {
        setIsUserDataBlank(false)
      }
    }
  }, [props.history, setIsUserDataBlank, user])

  const radioPaymentChange = (e) => {
    if (e.target.value === 'payatdoor') {
      setCreditCardCollapse(false)
      setOrder({ ...order, orderStatus: 'pending', paymentStatus: 'payatdoor' })
    } else {
      setCreditCardCollapse(true)
      setOrder({ ...order, orderStatus: 'accept', paymentStatus: 'paid' })
    }
    setPaymentOption(e.target.value)
  }
  return (
    <>
      {!user ? (
        noUser()
      ) : (
        <div className='order-page-cont'>
          <Container>
            <Row className='pt-4'>
              <Col>
                <h3>Review Order</h3>
              </Col>
            </Row>
          </Container>
          <hr />
          <Form>
            <Container className='confirm-cont'>
              {cartItems.length === 0 ? null : (
                <>
                  <Row className='confirm-row'>
                    <Col className='left' xl='6' lg='6' md='6' sm='12' xs='12'>
                      <div>
                        <div className='d-flex justify-content-end align-items-center'></div>
                        <h5>
                          {
                            localCartItems.GeneralInformationRestaurant
                              .RestaurantName
                          }
                        </h5>
                        <hr />
                        <Container className='menu-item-cont'>
                          <>
                            <Row className='menu-item-row'>
                              <Col xl='1' lg='1' md='1' sm='1' xs='1'>
                                <span className='count'>
                                  {
                                    localCartItems.GeneralInformationMainMenu
                                      .MainMenuCount
                                  }
                                  x
                                </span>
                              </Col>
                              <Col xl='8' lg='8' md='8' sm='7' xs='7'>
                                <span className='name'>
                                  {
                                    localCartItems.GeneralInformationMainMenu
                                      .MainMenuName
                                  }
                                </span>
                              </Col>
                              <Col
                                className='d-flex justify-content-end'
                                xl='3'
                                lg='3'
                                md='3'
                                sm='3'
                                xs='3'>
                                <span className='price'>
                                  $
                                  {parseFloat(
                                    localCartItems.GeneralInformationMainMenu
                                      .MainMenuPrice *
                                      localCartItems.GeneralInformationMainMenu
                                        .MainMenuCount
                                  ).toFixed(2)}
                                </span>
                              </Col>
                            </Row>

                            {localCartItems.GeneralInformationAddOns.map(
                              (x, indexExtra) => {
                                return x.AddOnsCount > 0 ? (
                                  <Row key={indexExtra}>
                                    <Col xl='1' lg='1' md='1' sm='1' xs='1'>
                                      <span className='count' key={indexExtra}>
                                        {x.AddOnsCount}x
                                      </span>
                                    </Col>
                                    <Col xl='8' lg='8' md='8' sm='7' xs='7'>
                                      <span key={indexExtra} className='name'>
                                        {x.AddOnsName}
                                      </span>
                                    </Col>
                                    <Col
                                      className='d-flex justify-content-end'
                                      xl='3'
                                      lg='3'
                                      md='3'
                                      sm='3'
                                      xs='3'>
                                      <span className='price'>
                                        $
                                        {parseFloat(
                                          x.AddOnsPrice * x.AddOnsCount
                                        ).toFixed(2)}
                                      </span>
                                    </Col>
                                  </Row>
                                ) : null
                              }
                            )}
                            <Row>
                              <Col
                                className='instructions-col'
                                xl='12'
                                lg='12'
                                md='12'
                                sm='12'
                                xs='12'>
                                {' '}
                                <p className='m-0 mt-3'>
                                  <span className='instructions font-weight-bold'>
                                    Pickup Date:&nbsp;
                                  </span>
                                  {
                                    localCartItems.GeneralInformationMainMenu
                                      .MainMenuDate
                                  }
                                </p>
                                <p className='m-0'>
                                  <span className='instructions font-weight-bold'>
                                    Pickup Time:&nbsp;
                                  </span>
                                  {
                                    localCartItems.GeneralInformationMainMenu
                                      .MainMenuPickUpTime
                                  }
                                </p>
                              </Col>
                              <Col
                                className='instructions-col'
                                xl='12'
                                lg='12'
                                md='12'
                                sm='12'
                                xs='12'>
                                {' '}
                                <p>
                                  <span className='instructions font-weight-bold'>
                                    Instructions:&nbsp;
                                  </span>
                                  {
                                    localCartItems.GeneralInformationMainMenu
                                      .MainMenuInstruction
                                  }
                                </p>{' '}
                              </Col>
                            </Row>
                          </>

                          <div className='sub-total-cont '>
                            <div className='d-flex justify-content-between'>
                              <span>Subtotal</span>
                              <span>${subTotalPrice}</span>
                            </div>
                          </div>
                          <hr />
                          <div className='d-flex justify-content-between'>
                            <span className='font-weight-bold'>Total</span>
                            <span>${totalPrice}</span>
                          </div>
                        </Container>
                      </div>
                    </Col>

                    <Col className='right' xl='6' lg='6' md='6' sm='12' xs='12'>
                      {cartItems.length === 0 ? null : (
                        <div>
                          <h6 className='mt-3'>Contact Info</h6>
                          <hr />
                          <Container className='p-0 form-cont' fluid>
                            <Row>
                              <Col>
                                <div className='inform-box'>
                                  <span className='t1'>Name</span>
                                  <span className='t2'>
                                    {user.attributes.given_name}
                                  </span>
                                </div>
                                <div className='inform-box'>
                                  <span className='t1'>Email</span>
                                  <span className='t2'>
                                    {user.attributes.email}
                                  </span>
                                </div>
                                <div className='inform-box'>
                                  <span className='t1'>Phone</span>
                                  <span className='t2'>
                                    {user.attributes.phone_number}
                                  </span>
                                </div>
                              </Col>
                            </Row>
                          </Container>
                          <h6 className='mt-4'>Pickup Location</h6>
                          <hr />

                          {cartItems.length !== 0 ? (
                            <>
                              <p className='address-name'>
                                {
                                  localCartItems.GeneralInformationRestaurant
                                    .RestaurantAddressName
                                }
                              </p>
                              <p>
                                {
                                  localCartItems.GeneralInformationRestaurant
                                    .RestaurantStreet
                                }
                                ,{' '}
                                {
                                  localCartItems.GeneralInformationRestaurant
                                    .RestaurantCity
                                }
                                ,{' '}
                                {
                                  localCartItems.GeneralInformationRestaurant
                                    .RestaurantState
                                }
                                ,{' '}
                                {
                                  localCartItems.GeneralInformationRestaurant
                                    .RestaurantZipCode
                                }
                              </p>
                            </>
                          ) : null}

                          <h6 className='mt-4'>Payment</h6>
                          <hr />
                          <div>
                            <input
                              type='radio'
                              value='payatdoor'
                              checked={paymentOption === 'payatdoor'}
                              onChange={radioPaymentChange}
                            />
                            <span className='payment-option-title'>
                              &nbsp;Pay at the door
                            </span>
                            <p>Payment will be processed at Pickup</p>
                          </div>
                          {localCartItems.GeneralInformationRestaurant
                            .RestaurantCardPaymentEnabled ? (
                            // Önceden localCartItems.GeneralInformationRestaurant.RestaurantPaymentId kontrolü vardı.
                            <div>
                              <input
                                type='radio'
                                value='cardPayment'
                                checked={paymentOption === 'cardPayment'}
                                onChange={radioPaymentChange}
                                onClick={() => setCreditCardCollapse(true)}
                              />
                              <span className='payment-option-title'>
                                &nbsp;Credit card
                              </span>
                              <div
                                className={
                                  creditCardCollapse
                                    ? 'panel-collapse'
                                    : 'panel-collapse panel-close'
                                }>
                                {cartItems.length === 0 ? null : (
                                  <Elements stripe={stripePromise}>
                                    <StripeMiddleware
                                      toggle={toggle}
                                      userEmail={user.attributes.email}
                                      userName={user.attributes.given_name}
                                      userPhone={user.attributes.phone_number}
                                      order={order}
                                      sendMailCustomer={sendMailCustomer}
                                      sendMailOwner={sendMailOwner}
                                      accountID={
                                        localCartItems
                                          .GeneralInformationRestaurant
                                          .RestaurantPaymentId
                                      }
                                    />
                                  </Elements>
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col className='d-flex justify-content-center py-5'>
                      {paymentOption !== 'payatdoor' ? null : (
                        <button
                          className='order-btn'
                          onClick={addTodo}
                          disabled={processing}>
                          {processing ? 'Processing…' : 'Place Order'}
                        </button>
                      )}
                    </Col>
                  </Row>
                </>
              )}
              {cartItems.length === 0 ? null : (
                <SuccessfulModal
                  onClosed={onClosed}
                  modal={modal}
                  toggle={toggle}
                  restName={
                    localCartItems.GeneralInformationRestaurant.RestaurantName
                  }
                />
              )}
            </Container>
          </Form>
        </div>
      )}{' '}
    </>
  )
}
export default OrderPage

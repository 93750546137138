import React from 'react'
import { FormGroup, Label, Input } from 'reactstrap'
import './maskedphone.scss'
function MaskedPhoneInput({
  labelTitle,
  inputName,
  isRequired,
  refTemp,
  emptyControl,
  maskedInput,
  inputMask,
  placeHolder,
  defVal,
}) {
  return (
    <FormGroup className='masked-phone-input'>
      {placeHolder !== '' ? null : (
        <Label className='lable-title' for='exampleName'>
          {labelTitle} {isRequired && <span className='star'>*</span>}
        </Label>
      )}
      <Input
        type='tel'
        name={inputName}
        mask={inputMask}
        placeholder={placeHolder}
        maskChar=' '
        tag={maskedInput}
        inputRef={refTemp}
        defaultValue={defVal !== '' ? defVal : ''}
      ></Input>
      {emptyControl ? (
        <p className='error-message'>{emptyControl.message}</p>
      ) : null}
    </FormGroup>
  )
}

export default MaskedPhoneInput

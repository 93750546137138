import React, { useState, useContext, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import { Link, useHistory } from 'react-router-dom'
import { Collapse, Navbar, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap'
import logo from '../../assets/images/navbar-renzosfire.png'
import navbarBgDarkMode from '../../assets/images/navbarBackgroundDarkMode.jpg'
import logoDarkM from '../../assets/images/logoNavbarDarkM.svg'

import { useStore } from '../../contexts/SearchContext/SearchContext'
//Scss Imports
import './navi.scss'

import { CartContext } from '../../contexts/CartContext/CartContext'
import { UserContext } from '../../contexts/UserContext/UserContext'

const Navi = ({ theme, toggleTheme, toggleMenu }) => {
  const { cartItems } = useContext(CartContext)
  const { updateFormState, formState, user } = useContext(UserContext)
  const [sticky, setSticky] = useState()
  const [header, setHeader] = useState()
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)
  const history = useHistory()

  const [search, setSearch] = useState()

  const { dispatch } = useStore()
  const handleChange = (val) => {
    setSearch(val)
  }

  const onEnter = (event, callback) => {
    if (event.key === 'Enter') {
      searchingSet()

      history.push('/restaurants')
    }
  }
  const searchingSet = () => {
    dispatch({ type: 'change', search: search })
  }

  return (
    <header className='sticky-top' id='myHeader'>
      <Navbar
        style={{ backgroundImage: theme ? `url(${navbarBgDarkMode})` : null }}
        className='main-navbar'
        light
        expand='md'>
        <Link className='navbar-brand navbar-logo' to='/'>
          <img src={theme ? logoDarkM : logo} alt='logo'></img>
        </Link>

        <div className='search-main-cont'>
          <div className='search-bar-cont'>
            <input
              type='text'
              placeholder='Search location or food'
              value={search}
              onChange={(e) => handleChange(e.target.value)}
              onKeyPress={(e) => onEnter(e, search)}></input>
            <Link className='fas fa-search' onClick={searchingSet} to='/restaurants'></Link>
          </div>
        </div>

        <div className='shopping-cart-cont-extra' onClick={toggleMenu}>
          <i className='fas fa-shopping-cart'></i>
          <span>
            Order(
            {cartItems.length !== 0
              ? cartItems[0].GeneralInformationRestaurant.RestaurantType === 'type2'
                ? cartItems[0].GeneralInformationMainMenu.length
                : cartItems[0].GeneralInformationMainMenu.MainMenuCount
              : '0'}
            )
          </span>
        </div>
        {/* <NavbarToggler  className="nav-toggler" onClick={toggle} /> */}
        <i className='fas fa-bars navbar-toggler menu-icon' onClick={toggle}></i>
        <Collapse className='nav-collapse' isOpen={isOpen} navbar>
          <Nav className='nav-list' navbar>
            <NavItem className='theme-toggle'>
              <i style={{ color: theme ? '#FF4C01' : null }} className='fas fa-sun' onClick={toggleTheme}></i>
            </NavItem>

            {!user ? (
              <>
                <NavItem>
                  <Link
                    className='sign-in-up-btn px-md-3'
                    to='/auth'
                    onClick={() =>
                      updateFormState(() => ({
                        ...formState,
                        formType: 'signIn',
                      }))
                    }>
                    Sign In
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    className='sign-in-up-btn px-md-3'
                    to='/auth'
                    onClick={() =>
                      updateFormState(() => ({
                        ...formState,
                        formType: 'signUp',
                      }))
                    }>
                    Sign Up
                  </Link>
                </NavItem>
              </>
            ) : (
              <>
                <NavItem>
                  <Link className='sign-in-up-btn px-md-3' to='/account'>
                    Profile
                  </Link>
                </NavItem>

                <NavItem>
                  <a
                    className='sign-in-up-btn'
                    onClick={async () => {
                      await Auth.signOut()
                      history.push('/')
                    }}>
                    Sign Out
                  </a>
                </NavItem>
              </>
            )}
          </Nav>
        </Collapse>

        <div className='shopping-cart-cont' onClick={toggleMenu}>
          <i className='fas fa-shopping-cart'></i>
          <span>
            Order(
            {cartItems.length !== 0
              ? cartItems[0].GeneralInformationRestaurant.RestaurantType === 'type2'
                ? cartItems[0].GeneralInformationMainMenu.length
                : cartItems[0].GeneralInformationMainMenu.MainMenuCount
              : '0'}
            )
          </span>
        </div>
      </Navbar>
    </header>
  )
}
export default Navi

import React, { useState, useEffect, useContext } from 'react'
//Package Imports
import { slide as Menu } from 'react-burger-menu'

import { Link } from 'react-router-dom'

import { Container } from 'reactstrap'
import Item from './Item'
import { CartContext } from 'contexts/CartContext/CartContext'
//Scss Imports
import './cart.scss'
import CartImage from '../../assets/images/cart-img.jpg'

import { AddOnPriceCalculate, MainMenuPriceCalculate } from '../../helperFunctions/PriceCalculate/PriceCalculate'
import getOrderTotal from 'helperFunctions/GetOrderTotal/GetOrderTotal'
const Cart = (props) => {
  const { cartItems, quantity, total, clearCart, removeProduct } = useContext(CartContext)

  function createMenuItem(mainMenu, generalInformationAddOns, cartItems) {
    const menuItem = {
      GeneralInformationMainMenu: null,
      GeneralInformationAddOns: null,
      GeneralInformationRestaurant: cartItems.GeneralInformationRestaurant,
      GeneralInformationOrder: cartItems.GeneralInformationOrder,
    }
    const menuAddOns = []

    generalInformationAddOns.forEach((addOn) => {
      if (mainMenu.MainMenuTempID === addOn.AddOnsTempID) {
        menuAddOns.push(addOn)
      }
    })

    menuItem.GeneralInformationMainMenu = mainMenu
    menuItem.GeneralInformationAddOns = menuAddOns
    const {
      GeneralInformationMainMenu: { MainMenuTempID },
    } = menuItem
    return <Item key={MainMenuTempID} cartItems={menuItem} removeProduct={removeProduct} />
  }

  return (
    <Menu
      styles={styles}
      pageWrapId={'page-wrap'}
      outerContainerId={'outer-container'}
      isOpen={props.menuIsOpen}
      disableOverlayClick={() => {
        props.toggleMenu()
        return false
      }}
      customBurgerIcon={false}
      disableAutoFocus
      right>
      <div className='d-flex justify-content-end'>
        <i className='fas fa-times' onClick={props.toggleMenu}></i>
      </div>

      {cartItems.length === 0 ? (
        <img className='cart-img' src={CartImage}></img>
      ) : (
        <div className='cart-title'>
          <span>Your Order:</span>
        </div>
      )}
      <Container className='bag-item-container'>
        {cartItems.length !== 0 ? (
          cartItems[0].GeneralInformationMainMenu.length >= 1 ? (
            cartItems[0].GeneralInformationMainMenu.map((mainMenu) => {
              return createMenuItem(mainMenu, cartItems[0].GeneralInformationAddOns, cartItems[0])
            })
          ) : (
            createMenuItem(cartItems[0].GeneralInformationMainMenu, cartItems[0].GeneralInformationAddOns, cartItems[0])
          )
        ) : (
          <div className='pt-5'>
            <span className='cart-empty-text'>Your cart is empty</span>
            <span className='cart-empty-text'>Add items to get started</span>
          </div>
        )}
      </Container>

      {cartItems.length === 0 ? null : (
        <Link
          to={cartItems[0].GeneralInformationRestaurant.RestaurantType === 'type2' ? '/checkout' : '/order'}
          onClick={props.toggleMenu}>
          <div className='checkout-total'>
            <div className='font-weight-bold'>Checkout</div>
            <span>
              $
              {parseFloat(
                getOrderTotal(cartItems[0].GeneralInformationRestaurant.RestaurantType, cartItems[0])
              ).toFixed(2)}
            </span>
          </div>
        </Link>
      )}
    </Menu>
  )
}

var styles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '36px',
    height: '30px',
    left: 'initial',
    right: '36px',
    top: '36px',
  },
  bmBurgerBars: {
    background: '#373a47',
  },
  bmBurgerBarsHover: {
    background: '#a90000',
  },
  bmCrossButton: {
    display: 'none',
    height: '24px',
    width: '24px',
  },
  bmCross: {
    background: 'black',
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    width: '30%',
  },
  bmMenu: {
    background: '#ffffff',
    padding: '1.5em 1.5em 0',
    fontSize: '1.15em',
  },
  bmMorphShape: {
    fill: '#373a47',
  },
  bmItemList: {
    fontSize: '0.8rem',
    color: 'black',
  },
  bmItem: {
    display: 'block',
    outline: 'none',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
  },
}
var stylesDarkTheme = {
  ...styles,
  bmItemList: { fontSize: '0.8rem', color: 'white' },
  bmMenu: {
    background: '#1D1D1D',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em',
  },
  bmCross: { background: 'white' },
}
export default Cart

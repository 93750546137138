import React from 'react'
import { Col, Row } from 'reactstrap'

//Scss Imports
import './item.scss'

export default function Item({ cartItems, removeProduct }) {
  return (
    <Row className='bag-item-row'>
      <Col className='p-0 d-flex align-items-center' xl='1' lg='1' md='1' sm='1' xs='1'>
        <div className='bag-item'>
          <span className='item'>{'x' + cartItems.GeneralInformationMainMenu.MainMenuCount}</span>
        </div>
      </Col>
      <Col className='p-0' xl='8' lg='8' md='8' sm='8' xs='8'>
        <div className='bag-item'>
          <span className='item'>{cartItems.GeneralInformationMainMenu.MainMenuName}</span>
        </div>
      </Col>

      <Col className='p-0' xl='2' lg='2' md='2' sm='2' xs='2'>
        <div className='bag-price'>
          <span>
            $
            {(
              cartItems.GeneralInformationMainMenu.MainMenuPrice * cartItems.GeneralInformationMainMenu.MainMenuCount
            ).toFixed(2)}
          </span>
        </div>
        {/* Hemen asagi yorum satiri extra itemlarin da fiyatini getiriyor. Design yapilacak */}
      </Col>
      <Col className='p-0' xl='1' lg='1' md='1' sm='1' xs='1'>
        <div className='item-close-btn-wrapper '>
          <i
            className='fas fa-times'
            onClick={() => {
              removeProduct(cartItems)
            }}></i>
        </div>
      </Col>

      {cartItems.GeneralInformationAddOns.map((x, index) => {
        if (x.AddOnsCount !== 0) {
          return (
            <React.Fragment key={index}>
              <Col className='p-0 d-flex align-items-center' xl='1' lg='1' md='1' sm='1' xs='1'>
                <div className='bag-item'>
                  <span className='extra-item'>x{x.AddOnsCount}</span>
                </div>
              </Col>
              <Col className='p-0' xl='8' lg='8' md='8' sm='8' xs='8'>
                <div className='bag-item'>
                  <span className='extra-item'>{x.AddOnsName}</span>
                </div>
              </Col>

              <Col className='p-0' xl='2' lg='2' md='2' sm='2' xs='2'>
                <div className='bag-price'>
                  <span className='extra-item-price'>
                    {x.AddOnsPrice !== 0 && '$' + (x.AddOnsPrice * x.AddOnsCount).toFixed(2)}
                  </span>
                </div>
              </Col>
              <Col className='p-0' xl='1' lg='1' md='1' sm='1' xs='1'></Col>
            </React.Fragment>
          )
        } else {
          return null
        }
      })}
    </Row>
  )
}

//TODO clearCart metodu tüm localStorage u temizliyor.

import React, { useState, useEffect, useContext } from 'react'

import './App.css'
import Dashboard from './Pages/DashboardPage/DashboardPage'
import RestaurantPage from './Pages/RestaurantPage/RestaurantPage'
import CategoryPage from './Pages/CategoryPage/CategoryPage'
import RestaurantRegistrationPage from 'Pages/RestaurantRegistrationPage/RestaurantRegistrationPage'
import SingleRestaurantPage from 'Pages/SingleRestaurantPage/SingleRestaurantPage'

import TempVillage from 'Pages/SingleRestaurantPage/TempVillage'
import OrderPage from 'Pages/OrderPage/OrderPage'
import OrderPage2 from 'Pages/OrderPage/OrderPage2'
import TermsOfUsePage from 'Pages/PoliciesPages/TermsOfUse'
import CookiePolicyPage from 'Pages/PoliciesPages/CookiePolicy'
import PrivacyAndPolicyPage from 'Pages/PoliciesPages/PrivacyAndPolicy'
//Component imports
import Navi from './Components/Navi/Navi'
import Footer from './Components/Footer/Footer'
import Cart from './Components/Cart/Cart'
import AuthPage from './Pages/AuthPages/AuthPage'
import AccountPage from './Pages/AccountPage/Accountpage'
import ScrollToTop from './Components/ScrollToTop/ScrollToTop'
import FixedSocialIcons from './Components/FixedSocialIcons/FixedSocialIcons'
import NotFound from './Components/NotFound/NotFound'
//Package imports
import { createBrowserHistory } from 'history'
import { Router, Switch, Route } from 'react-router-dom'

// import './app.css'
import { CartContext } from 'contexts/CartContext/CartContext'
import { CompanyContext } from './contexts/CompanyContext'
import TypeOneSR from 'Pages/SingleRestaurantPages/TypeOnePages/TypeOneSR'
const hist = createBrowserHistory()

function App() {
  const { cartItems } = useContext(CartContext)
  const { restaurants } = useContext(CompanyContext)

  const [menuIsOpen, setMenuIsOpen] = useState(() => {
    if (cartItems.length > 0) {
      return true
    } else {
      return false
    }
  })
  const [theme, setTheme] = useState(false)
  const toggleMenu = () => setMenuIsOpen(!menuIsOpen)
  const toggleTheme = () => setTheme(!theme)
  const outerContainerStyle = {
    background: '#121212',
    color: 'white',
  }

  const getRestaurantRoutes = () => {
    return restaurants.map((item) => {
      return (
        <Route
          exact
          path={'/' + item.urlStub}
          render={(props) => {
            return <SingleRestaurantPage {...props} />
          }}
        />
      )
    })
  }

  return (
    <Router history={hist}>
      <div style={theme ? outerContainerStyle : null} id='outer-container'>
        <div id='wrapper'>
          <Cart toggleMenu={toggleMenu} menuIsOpen={menuIsOpen} theme={theme} />

          <Navi toggleMenu={toggleMenu} toggleTheme={toggleTheme} theme={theme} />
          <ScrollToTop />
          <Switch>
            <Route
              exact
              path='/order'
              render={(props) => {
                return <OrderPage {...props} />
              }}
            />
            <Route
              exact
              path='/checkout'
              render={(props) => {
                return <OrderPage2 {...props} />
              }}
            />
            <Route
              exact
              path='/'
              render={(props) => {
                return <TypeOneSR singleRestaurant={restaurants[0]} themeToggle={theme} />
              }}
            />

            <Route
              exact
              path='/auth'
              render={(props) => {
                return <AuthPage {...props} />
              }}
            />
            <Route
              exact
              path='/account'
              render={(props) => {
                return <AccountPage {...props} />
              }}
            />
            <Route exact path='/terms-of-use' component={TermsOfUsePage} />
            <Route exact path='/cookie-policy' component={CookiePolicyPage} />
            <Route exact path='/privacy-and-policy' component={PrivacyAndPolicyPage} />

            {getRestaurantRoutes()}

            {/* <Route
              exact
              path='/:restaurantName'
              render={(props) => {
                return <SingleRestaurantPage {...props} />
              }}
            /> */}
            <Route
              path='*'
              render={(props) => {
                return <NotFound {...props} />
              }}
            />
          </Switch>
        </div>
        <Footer theme={theme} />
      </div>
    </Router>
  )
}

export default App

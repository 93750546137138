import React from 'react'

import { Button } from 'reactstrap'

import StandardInput from 'Components/Inputs/StandardInput/StandardInput'
function ForgotPassword({ register, errors }) {
  return (
    <>
      <StandardInput
        labelTitle='Username'
        placeHolder=''
        maxLen={255}
        inputType='text'
        isRequired={true}
        inputName='usernameForgotPassword'
        refTemp={register({
          required: 'Username is required!',
        })}
        emptyControl={errors.usernameForgotPassword}
      />

      <Button className='signin-btn' type='submit'>
        Send Code
      </Button>
    </>
  )
}

export default ForgotPassword

import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import './termsofuse.scss'
function TermsOfUse() {
   
  return (
    <Container className='terms-of-use-cont'>
      <Row className='title-row'>
        <Col className='title-cont' xl='12' lg='12' md='12' sm='12' xs='12'>
          Terms of Use - United States
        </Col>
        <Col xl='12' lg='12' md='12' sm='12' xs='12'>
          Artichokee Consumers
        </Col>
      </Row>
      <Row className='content-row'>
        <Col className='content-col'>
          <h1 className='title'>
            <b>Artichokee, Inc. Terms of Service</b>
          </h1>
          <p>
            Please read these Terms of Service fully and carefully before using
            www.artichokee.com (the “Site”) and the services, features, content
            or mobile applications offered by Artichokee, Inc. (“Artichokee”,
            “we”, “us” or “our”) (together with the Site, the “Services”). The
            term "you" or "You" shall refer to any person or entity who views,
            uses, accesses, browses or submits any content or material to the
            Site. These Terms of Service set forth the legally binding terms and
            conditions for your use of the Site and the Services.
          </p>
          <p>
            <br />
          </p>
          <h2>
            <b>Acceptance of Terms of Service</b>
          </h2>
          <p>
            (i) By registering for and/or using the Services in any manner,
            including but not limited to visiting or browsing the Site, you
            agree to these Terms of Service and all other operating rules,
            policies and procedures that may be published from time to time on
            the Site by us, each of which is incorporated by reference and each
            of which may be updated from time to time without notice to you.
          </p>
          <p>
            (ii) Certain of the Services may be subject to additional terms and
            conditions specified by us from time to time; your use of such
            Services is subject to those additional terms and conditions, which
            are incorporated into these Terms of Service by this reference.
          </p>
          <p>
            (iii) These Terms of Service apply to all users of the Services,
            including, without limitation, users who are contributors of
            content, information, and other materials or services, registered or
            otherwise.
          </p>
          <p>
            (iv) ARBITRATION NOTICE AND CLASS ACTION WAIVER: EXCEPT FOR CERTAIN
            TYPES OF DISPUTES DESCRIBED IN THE ARBITRATION SECTION BELOW, YOU
            AGREE THAT DISPUTES BETWEEN YOU AND US WILL BE RESOLVED BY BINDING,
            INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A
            CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.
          </p>
          <h2>
            <br />
          </h2>
          <h2>
            <b>The Site and Services</b>
          </h2>
          <p>
            (i) The Site and Services are a third-party marketplace for you to
            view, discuss, and place orders for food and beverages, and pick-up
            and/or delivery services in connection therewith, with participating
            restaurants (the “Restaurants”). Artichokee is a means of order
            placement services only. Artichokee does not sell and is not a
            merchant of food or beverages, or any pickup or delivery services in
            connection therewith, and does not sell or control the Restaurants
            or the production of any food or beverages, or any pick-up or
            delivery services therewith, produced at or provided by the
            Restaurants. Further, Artichokee is not a party to any transaction
            between you and the Restaurants from which you order.
          </p>
          <p>
            (ii) The Restaurants have entered into agreements with Artichokee
            that require them to comply with federal, state and local laws,
            rules, regulations, and standards pertaining to food preparation,
            sale, marketing and safety. Artichokee, including the Site and the
            Services, does not in any way independently verify the credentials
            or representations of any of the Restaurants, the ingredients or the
            quality of any their products or services, or any Restaurant’s
            compliance with applicable laws.
          </p>
          <p>
            (iii) You are responsible for reviewing the information provided by
            the Restaurants on the Site and contacting the Restaurants directly
            for any questions on the reliability and quality of the Restaurants
            and the Restaurants’ compliance with applicable laws. Artichokee,
            including the Site and the Services, does not in any way guaranty
            the quality of any Restaurant or any food or beverage, or any
            pickup-up or delivery service in connection therewith, or any
            compliance thereof with applicable laws.
          </p>
          <p>
            (iv) In addition, a Restaurant may represent certain standards with
            respect to their food preparation (or other services) such as
            “organic,” “kosher,” “macrobiotic” or allergen-specific standards
            such as “nut-free,” “gluten-free,” or “lactose-free”; Artichokee
            does not investigate or verify any such representations. Please
            instruct the Restaurant of food allergy concerns in the special
            comment boxes or in the final comment box before sending your order.
            Although this information is transmitted to the Restaurant exactly
            as it appears on the Services, Artichokee DOES NOT GUARANTEE THAT
            YOUR ORDER WILL BE PREPARED AS REQUESTED. If you have food-related
            allergies and you are concerned about the preparation of the order,
            we suggest you contact the Restaurant directly after submitting your
            order. Artichokee shall not be liable or responsible for any food or
            beverages, or any other services, offered by the Restaurants or any
            errors or misrepresentations made by them (including on the Site and
            through the Services).
          </p>
          <p>
            (v) Certain Restaurants may utilize DoorDash to make delivery of
            your orders. Ordering from these Restaurants may subject you to
            DoorDash’s terms and conditions, including the privacy policies.
            Further, by using such third-party delivery providers you may be
            consenting to receive mobile communications and text messages from
            such providers. Please review the DoorDash terms and conditions for
            more information about their rights to send you such mobile
            communications. We do take your satisfaction very seriously and, in
            addition to contacting the applicable Restaurant directly, we ask
            that you notify Artichokee of all complaints or concerns that you
            might have regarding the Restaurants.
          </p>
          <h2>
            <br />
          </h2>
          <h2>
            <b>Eligibility</b>
          </h2>
          <p>
            You represent and warrant that you are at least 13 years of age. If
            you are under age 13, you may not, under any circumstances or for
            any reason, use the Services. We may, in our sole discretion, refuse
            to offer the Services to any person or entity and change its
            eligibility criteria at any time. You are solely responsible for
            ensuring that these Terms of Service are in compliance with all
            laws, rules and regulations applicable to you and the right to
            access the Services is revoked where these Terms of Service or use
            of the Services is prohibited or to the extent offering, sale or
            provision of the Services conflicts with any applicable law, rule or
            regulation. Further, the Services are offered only for your use, and
            not for the use or benefit of any third party.
          </p>
          <h2>
            <br />
          </h2>
          <h2>
            <b>Alcohol</b>
          </h2>
          <p>
            You may not use the Services to purchase alcohol products unless you
            and the intended recipient of the products meet the minimum age
            requirements under applicable state or provincial laws and present a
            valid photo identification verifying your age at the time you (or
            the intended recipient) pick(s) up or receive(s) delivery of your
            order. Restaurant, not Artichokee, is responsible for complying with
            all applicable laws concerning the sale and delivery of alcohol
            products. All sales of alcohol products are between you and the
            Restaurant, and Artichokee is not a party to any such transaction.
            You may not elect contactless delivery for any orders containing
            alcohol.
          </p>
          <h2>
            <br />
          </h2>
          <h2>
            <b>Registration</b>
          </h2>
          <p>
            Use of Services may require that you register and/or create an
            account ("Account") or use the Services as a guest. To register and
            create an Account, you must select an account designation and
            password and provide certain personal information. You must provide
            accurate and complete information and keep your Account information
            updated. You shall not:
          </p>
          <p>
            (i) select or use as a username a name of another person with the
            intent to impersonate that person;
          </p>
          <p>
            (ii) use as a username a name subject to any rights of a person
            other than you without appropriate authorization; or
          </p>
          <p>
            (iii) use, as a username, a name that is otherwise offensive, vulgar
            or obscene. If you provide any information that is untrue,
            inaccurate, not current or incomplete, or Artichokee has reasonable
            grounds to suspect that such information is untrue, inaccurate, not
            current or incomplete, Artichokee has the right to refuse
            registration of any individual, or to cancel any individual’s
            registration and prohibit all future use of the Services, for any
            reason at Artichokee’s sole and exclusive discretion. You are solely
            responsible for the activity that occurs on your Account, and for
            keeping your Account password secure. You may never use another
            person’s user account or registration information for the Services
            without permission. You must notify us immediately of any change in
            your eligibility to use the Services (including any changes to or
            revocation of any licenses from state authorities), breach of
            security or unauthorized use of your Account. You should never
            publish, distribute or post login information for your Account. You
            agree to ensure that you exit from your Account at the end of each
            session. You shall have the ability to delete your Account, either
            directly or through a request made to one of our employees or
            affiliates. Artichokee will not be liable for any injury, loss or
            damage of any kind arising from or relating to your failure to
            comply with the foregoing or for any acts or omissions by you or
            someone else using your Account and/or password.
          </p>
          <h2>
            <br />
          </h2>
          <h2>
            <b>Content</b>
          </h2>
          <p>
            (i) Definition. For purposes of these Terms of Service, the term
            “Content” includes, without limitation, information, data, text,
            photographs, videos, audio clips, written posts and comments,
            software, scripts, graphics, and interactive features generated,
            provided, or otherwise made accessible on or through the Services.
            For the purposes of this Agreement, “Content” also includes all User
            Content (as defined below).
          </p>
          <p>
            (ii) User Content. All Content added, created, uploaded, submitted,
            distributed, or posted to the Services by you (collectively “User
            Content”), whether publicly posted or privately transmitted, is
            solely your responsibility. You represent and warrant to Artichokee
            that
          </p>
          <p className='pl-4'>
            (i) all User Content provided by you is accurate, complete,
            up-to-date, and in compliance with all applicable laws, rules and
            regulations,
          </p>
          <p className='pl-4'>
            (ii) you own or have the necessary licenses, rights, consents, and
            permissions to all trademark, trade secret, copyright, or other
            proprietary, privacy, and publicity rights in and to your User
            Content and any other works that you incorporate into your User
            Content, and all the rights necessary to grant the licenses and
            permissions you grant hereunder, and
          </p>
          <p className='pl-4'>
            (iii) our use of your User Content in the manners contemplated in
            this Agreement shall not violate or misappropriate the intellectual
            property, privacy, publicity, contractual, or other rights of any
            third party. You acknowledge that all Content, including User
            Content, accessed by you using the Services is at your own risk and
            you will be solely responsible for any damage or loss to you or any
            other party resulting therefrom. We do not guarantee that any
            Content you access on or through the Services is or will continue to
            be accurate.
          </p>
          <p>
            (iii) Reviews. The Services may allow you to post reviews of
            restaurants and other businesses ("Reviews"). Such Reviews are
            considered User Content and are governed by the terms and conditions
            of this Agreement, including, without limitation, the rules of
            conduct set forth below. Reviews are not endorsed by Artichokee, and
            do not represent the views of Artichokee or of any affiliate or
            partner of Artichokee. Artichokee does not assume liability for
            Reviews or for any claims, liabilities or losses resulting from any
            Reviews. All Reviews must comply with the following criteria:
          </p>
          <p className='pl-4'>
            (1) before posting a Review, you must have had first-hand experience
            with the restaurant;
          </p>
          <p className='pl-4'>
            (2) you may not have a proprietary or other affiliation with either
            the Restaurant or any of its competitors;
          </p>
          <p className='pl-4'>
            (3) you may not draw any legal conclusions regarding the
            Restaurants’ products, services or conduct; and
          </p>
          <p className='pl-4'>
            (4) your Review must otherwise comply with the terms of this
            Agreement. Any Review that Artichokee determines, in its sole
            discretion, could diminish the integrity of the Reviews and/or the
            Services may be removed or excluded by Artichokee without notice.
          </p>
          <p>
            (iv) Notices and Restrictions. This Site, in its entirety, is the
            sole property of Artichokee or its content suppliers and the
            Services may contain Content provided by us, our partners or our
            users that is protected by copyrights, trademarks, service marks,
            patents, trade secrets or other proprietary rights and laws. You
            shall abide by and maintain all copyright notices, information, and
            restrictions contained in any Content accessed through the Services.
          </p>
          <p>
            (v) Use License. Subject to these Terms of Service, we grant each
            user of the Services a worldwide, non-exclusive, non-sublicensable
            and non-transferable license to use (i.e., to download and display
            locally) Content solely for purposes of using the Services. Use,
            reproduction, modification, distribution or storage of any Content
            for other than purposes of using the Services is expressly
            prohibited without prior written permission from us. You shall not
            sell, license, rent, or otherwise use or exploit any Content for
            commercial use or in any way that violates any third party right.
          </p>
          <p>
            (vi) License Grant. By submitting User Content through the Services,
            you hereby do and shall grant us a worldwide, non-exclusive,
            perpetual, royalty-free, fully paid, sublicensable and transferable
            license to use, edit, modify, truncate, aggregate, reproduce,
            distribute, prepare derivative works of, display, perform, and
            otherwise fully exploit the User Content in connection with the
            Site, the Services and our (and our successors’ and assigns’)
            businesses, including without limitation for promoting and
            redistributing part or all of the Site or the Services (and
            derivative works thereof) in any media formats and through any media
            channels (including, without limitation, third party websites and
            feeds), and including after your termination of your Account or the
            Services, in all cases without consideration or attribution to you.
            You also hereby do and shall grant each user of the Site and/or the
            Services a non-exclusive, perpetual license to access your User
            Content through the Site and/or the Services, and to use, edit,
            modify, reproduce, distribute, prepare derivative works of, display
            and perform such User Content, including after your termination of
            your Account or the Services. You further grant Artichokee a
            royalty-free, non-exclusive, perpetual license to use, edit, modify,
            truncate, aggregate, reproduce, distribute, prepare derivative works
            of, display, perform, and otherwise fully exploit the name, image,
            and likeness of any person identifiable in any Review you post to
            the Services. By posting a Review, you waive any and all rights of
            publicity, privacy or any other rights of a similar nature, in
            addition to any moral rights you may have in your Review. For
            clarity, the foregoing license grants to us and our users does not
            affect your other ownership or license rights in your User Content,
            including the right to grant additional licenses to your User
            Content, unless otherwise agreed in writing. You represent and
            warrant that you have all rights to grant such licenses to us
            without infringement or violation of any third party rights,
            including without limitation, any privacy rights, publicity rights,
            copyrights, trademarks, contract rights, or any other intellectual
            property or proprietary rights.
          </p>
          <p>
            (vii) Availability of Content. We do not guarantee that any Content
            will be made available on the Site or through the Services. We
            reserve the right to, but do not have any obligation to,
          </p>
          <p className='pl-4'>
            (i) remove, edit or modify any Content in our sole discretion, at
            any time, without notice to you and for any reason (including, but
            not limited to, upon receipt of claims or allegations from third
            parties or authorities relating to such Content or if we are
            concerned that you may have violated these Terms of Service), or for
            no reason at all and
          </p>
          <p className='pl-4'>
            (ii) to remove or block any Content from the Services.
          </p>

          <h2>
            <br />
          </h2>
          <h2>
            <b>Rules of Conduct</b>
          </h2>
          <p>
            (i) As a condition of use, you promise not to use the Services for
            any purpose that is prohibited by these Terms of Service. You are
            responsible for all of your activity in connection with the
            Services. ii. You shall not (and shall not permit any third party
            to) either (a) take any action or (b) upload, download, post, submit
            or otherwise distribute or facilitate distribution of any Content on
            or through the Service, including without limitation any User
            Content, that: i. infringes any patent, trademark, trade secret,
            copyright, right of publicity or other right of any other person or
            entity or violates any law or contractual duty;
          </p>
          <p>
            (ii) you know is false, misleading, untruthful or inaccurate; iii.
            is unlawful, threatening, abusive, harassing, defamatory, libelous,
            deceptive, fraudulent, invasive of another's privacy, tortious,
            obscene, vulgar, pornographic, offensive, profane, contains or
            depicts nudity, contains or depicts sexual activity, or is otherwise
            inappropriate as determined by us in our sole discretion; iv.
            constitutes unauthorized or unsolicited advertising, junk or bulk
            e-mail (“spamming”); v. contains software viruses or any other
            computer codes, files, or programs that are designed or intended to
            disrupt, damage, limit or interfere with the proper function of any
            software, hardware, or telecommunications equipment or to damage or
            obtain unauthorized access to any system, data, password or other
            information of ours or of any third party; vi. impersonates any
            person or entity, including any of our employees or representatives;
            or vii. includes anyone’s identification documents or sensitive
            financial information.
          </p>
          <p>(iii) You shall not:</p>
          <p className='pl-4'>
            (i) take any action that imposes or may impose (as determined by us
            in our sole discretion) an unreasonable or disproportionately large
            load on our (or our third party providers’) infrastructure;
          </p>
          <p className='pl-4'>
            (ii) interfere or attempt to interfere with the proper working of
            the Services or any activities conducted on the Services;
          </p>
          <p className='pl-4'>
            (iii) bypass, circumvent or attempt to bypass or circumvent any
            measures we may use to prevent or restrict access to the Services
            (or other accounts, computer systems or networks connected to the
            Services);
          </p>
          <p className='pl-4'>
            (iv) run any form of auto-responder or “spam” on the Services;
          </p>
          <p className='pl-4'>
            (v) use manual or automated software, devices, or other processes to
            “crawl” or “spider” any page of the Site;
          </p>
          <p className='pl-4'>
            (vi) harvest or scrape any Content from the Services; or (vii)
            otherwise take any action in violation of our guidelines and
            policies.
          </p>
          <p>(iv) You shall not (directly or indirectly):</p>
          <p className='pl-4'>
            (i) decipher, decompile, disassemble, reverse engineer or otherwise
            attempt to derive any source code or underlying ideas or algorithms
            of any part of the Services (including without limitation any
            application), except to the limited extent applicable laws
            specifically prohibit such restriction,
          </p>
          <p className='pl-4'>
            (ii) modify, translate, or otherwise create derivative works of any
            part of the Services, or
          </p>
          <p className='pl-4'>
            (iii) copy, rent, lease, distribute, or otherwise transfer any of
            the rights that you receive hereunder. You shall abide by all
            applicable local, state, national and international laws and
            regulations.
          </p>
          <p>
            (v) We also reserve the right to access, read, preserve, and
            disclose any information as we reasonably believe is necessary to{' '}
          </p>

          <p className='pl-4'>
            (i) satisfy any applicable law, regulation, legal process or
            governmental request,
          </p>
          <p className='pl-4'>
            (ii) enforce these Terms of Service, including investigation of
            potential violations hereof,
          </p>
          <p className='pl-4'>
            (iii) detect, prevent, or otherwise address fraud, security or
            technical issues,
          </p>
          <p className='pl-4'>(iv) respond to user support requests, or</p>
          <p className='pl-4'>
            (v) protect the rights, property or safety of us, our users and the
            public.
          </p>

          <h2>
            <br />
          </h2>
          <h2>
            <b>Third Party Services</b>
          </h2>
          <p>
            The Services may permit you to link to other websites, services or
            resources (collectively, “Third Party Services”) on or over the
            Internet, and Third Party Services may contain links to the
            Services. When you access Third Party Services, you do so at your
            own risk. Third Party Services are not under our control, and you
            acknowledge that we are not responsible or liable for the content,
            functions, accuracy, legality, appropriateness or any other aspect
            of such websites or resources. The inclusion of any such link does
            not imply our endorsement or any association between us and the
            Third Party Services or their operators. You further acknowledge and
            agree that we shall not be responsible or liable, directly or
            indirectly, for any damage or loss caused or alleged to be caused by
            or in connection with the use of or reliance on any such content,
            goods or services available on or through any such Third Party
            Service. The Artichokee site may not be linked to any other site
            without prior consent of Artichokee.
          </p>
          <h2>
            <br />
          </h2>
          <h2>
            <b>Apple, Inc. Device and Service Terms</b>
          </h2>
          <p>
            If you are accessing the Services on a device provided by Apple,
            Inc. (“Apple”) or otherwise obtained access to the Services through
            the Apple App Store, the following terms shall apply:
          </p>
          <p>
            (i) Both you and we acknowledge that these Terms of Service are
            concluded between you and us only, and not with Apple, and that
            Apple is not responsible for, does not endorse, and is not involved
            in the Services or Content;
          </p>
          <p>
            (ii) The Services are licensed to you on a limited, non-exclusive,
            non-transferrable, non-sublicensable basis, solely to be used in
            connection with the Services for your private, personal,
            non-commercial use, subject to all the terms and conditions of these
            Terms of Service as applicable;
          </p>
          <p>
            (iii) You will only use the Services in connection with an Apple
            device that you own or control;
          </p>
          <p>
            (iv) You acknowledge and agree that Apple has no obligation
            whatsoever to furnish any maintenance and support services with
            respect to the Services;
          </p>
          <p>
            (v) In the event of any failure of the Services to conform to any
            applicable warranty, including those implied by law, you may notify
            Apple of such failure; upon notification, Apple’s sole warranty
            obligation to you will be to refund to you the purchase price, if
            any, of the Services;
          </p>
          <p>
            (vi) You acknowledge and agree that we, and not Apple, are
            responsible for addressing any claims you or any third party may
            have in relation to our Services;
          </p>
          <p>
            (vii) You acknowledge and agree that, in the event of any third
            party claim that the Services or your possession and use of the
            Services infringes that third party’s intellectual property rights,
            we, and not Apple, will be responsible for the investigation,
            defense, settlement and discharge of any such infringement claim;
          </p>
          <p>
            (viii) You represent and warrant that you are not located in a
            country subject to a U.S. Government embargo, or that has been
            designated by the U.S. Government as a “terrorist supporting”
            country, and that you are not listed on any U.S. Government list of
            prohibited or restricted parties;
          </p>
          <p>
            (ix) Both you and we acknowledge and agree that, in your use of the
            Services, you will comply with any applicable third party terms of
            agreement which may affect or be affected by such use; and
          </p>
          <p>
            (x) Both you and we acknowledge and agree that Apple and Apple’s
            subsidiaries are third party beneficiaries of these Terms of
            Service, and that upon your acceptance of these Terms of Service,
            Apple will have the right (and will be deemed to have accepted the
            right) to enforce these Terms of Service against you as the third
            party beneficiary hereof.
          </p>
          <h2>
            <br />
          </h2>
          <h2>
            <b>Payments and Billing</b>
          </h2>
          <p>
            (i) Paid Services. Certain of our Services may be subject to
            payments now or in the future, including when you place delivery
            orders for food and beverages (the “Paid Services”). Please note
            that any payment terms presented to you in the process of using or
            signing up for a Paid Service are deemed part of this Agreement. The
            final charges for your order may be different than those stated on
            the Services. Artichokee does not assume any responsibility or
            liability for prices quoted on Artichokee Restaurant web page(s), or
            the actions, products, and content of any third parties related to
            the creation or delivery of your order. You agree to pay all fees
            and charges incurred in connection with the Paid Services (including
            any applicable taxes) at the rates in effect when the charges were
            incurred. We may require additional verification or information
            before accepting any order.
          </p>
          <p>
            (ii) Billing. When you chose to use a credit card for your payment,
            we use a third-party payment processor (the “Payment Processor”) to
            bill you through a payment account linked to your Account on the
            Services (your “Billing Account”) for use of the Paid Services. The
            processing of payments will be subject to the terms, conditions and
            privacy policies of the Payment Processor in addition to this
            Agreement. We are not responsible for error by the Payment
            Processor. By choosing to use Paid Services, you agree to pay us,
            through the Payment Processor, all charges at the prices then in
            effect for any use of such Paid Services in accordance with the
            applicable payment terms and you authorize us, through the Payment
            Processor, to charge your chosen payment provider (your “Payment
            Method”). You agree to make payment using that selected Payment
            Method. We reserve the right to correct any errors or mistakes that
            it makes even if it has already requested or received payment.
            Payments are also subject to our Payment Processor’s terms which can
            be found here.
          </p>
          <p>
            (iii) Payment Method. The terms of your payment will be based on
            your Payment Method and may be determined by agreements between you
            and the financial institution, credit card issuer or other provider
            of your chosen Payment Method. If we, through the Payment Processor,
            do not receive payment from you, you agree to pay all amounts due on
            your Billing Account upon demand. If you select to use cash as a
            method payment, you agree to provide the Restaurant payment in the
            form of U.S. Dollars upon receipt of your order.
          </p>
          <p>
            (iv) Current Information Required. If you select to use a credit
            card as your payment method, prior to the purchase of any Services,
            you must provide the third party payment processor with a valid
            credit card number and associated payment information including all
            of the following:
          </p>
          <p className='pl-4'>(i) your name as it appears on the card;</p>
          <p className='pl-4'>(ii) your credit card number;</p>
          <p className='pl-4'>(iii) the credit card type;</p>
          <p className='pl-4'>(iv) the date of expiration; and</p>

          <p className='pl-4'>
            (v) any activation numbers or codes needed to charge your card. YOU
            MUST PROVIDE CURRENT, COMPLETE AND ACCURATE INFORMATION FOR YOUR
            BILLING ACCOUNT. YOU MUST PROMPTLY UPDATE ALL INFORMATION TO KEEP
            YOUR BILLING ACCOUNT CURRENT, COMPLETE AND ACCURATE (SUCH AS A
            CHANGE IN BILLING ADDRESS, CREDIT CARD NUMBER, OR CREDIT CARD
            EXPIRATION DATE), AND YOU MUST PROMPTLY NOTIFY US OR OUR PAYMENT
            PROCESSOR IF YOUR PAYMENT METHOD IS CANCELED (E.G., FOR LOSS OR
            THEFT) OR IF YOU BECOME AWARE OF A POTENTIAL BREACH OF SECURITY,
            SUCH AS THE UNAUTHORIZED DISCLOSURE OR USE OF YOUR USER NAME OR
            PASSWORD. CHANGES TO SUCH INFORMATION CAN BE MADE AT
            WWW.ARTICHOKEE.COM. IF YOU FAIL TO PROVIDE ANY OF THE FOREGOING
            INFORMATION, YOU AGREE THAT WE MAY CONTINUE CHARGING YOU FOR ANY USE
            OF PAID SERVICES UNDER YOUR BILLING ACCOUNT UNLESS YOU HAVE
            TERMINATED YOUR PAID SERVICES AS SET FORTH ABOVE. If Restaurant does
            not receive payment from your credit card issuer or its agent, you
            agree to pay all amounts due upon demand by Artichokee or its
            agents. The final charges on your credit card may be different than
            those stated on the Services. By using Artichokee you acknowledge
            that your credit card information (as stated above) will be
            transmitted to the Restaurant via facsimile or another electronic
            transmission to process your order.
          </p>
          <p>
            (v) Change in Amount Authorized. If the amount to be charged to your
            Billing Account varies from the amount you preauthorized (other than
            due to the imposition or change in the amount of state sales taxes),
            you have the right to receive, and we shall provide, notice of the
            amount to be charged and the date of the charge before the scheduled
            date of the transaction. Any agreement you have with your payment
            provider will govern your use of your Payment Method. You agree that
            we may accumulate charges incurred and submit them as one or more
            aggregate charges during or at the end of each billing cycle.
          </p>
          <p>
            (vi) Free Trials. Any free trial or other promotion that provides
            access to a Paid Service must be used within the specified time of
            the trial. You must stop using a Paid Service before the end of the
            trial period in order to avoid being charged for that Paid Service.
            If you cancel prior to the end of the trial period and are
            inadvertently charged for a Paid Service, please contact us at
            support@artichokee.com.
          </p>
          <p>
            (vii) Promotions and Gift Cards. Artichokee may, in its sole
            discretion, offer promotional codes or other gift cards from time to
            time (“Artichokee Promos”) that may be redeemed for discounts on
            future orders through the Services. The following terms shall apply
            to Artichokee Promos:
          </p>
          <p className='pl-4'>
            (i) Artichokee Promos must be used in a lawful manner;
          </p>
          <p className='pl-4'>
            (ii) Artichokee Promos may not be duplicated, sold or transferred in
            any manner, or made available to the general public (whether posted
            to a public forum or otherwise), unless expressly permitted by
            Artichokee, except that Users may post their unique referral link to
            their own personal social media pages;
          </p>
          <p className='pl-4'>
            (iii) Artichokee Promos may be disabled by Artichokee at any time
            for any reason without liability to Artichokee;
          </p>
          <p className='pl-4'>
            (iv) Artichokee Promos may only be used pursuant to the specific
            terms that Artichokee establishes for such Artichokee Promos;
          </p>
          <p className='pl-4'>
            (v) Artichokee Promos are not redeemable for cash;
          </p>
          <p className='pl-4'>
            (vi) Artichokee Promos cannot be combined with cash;
          </p>
          <p className='pl-4'>
            (vii) Artichokee Promos are redeemable only with online payments;
          </p>
          <p className='pl-4'>
            (viii) lost, stolen or damaged Artichokee Promos may be replaced
            only with valid proof of purchase and to the extent the remaining
            Artichokee Promo balance appears in Artichokee records;
          </p>
          <p className='pl-4'>
            (ix) Artichokee Promos are not a credit or debit card;
          </p>
          <p className='pl-4'>
            (x) there are no expiration dates and no fees for purchased
            Artichokee Promos;
          </p>
          <p className='pl-4'>
            (xi) expiration dates or other terms may apply to Artichokee Promos
            not purchased directly by a consumer;
          </p>
          <p className='pl-4'>
            (xii) Artichokee is not responsible for any lost or stolen
            Artichokee Promos or use without your permission; and
          </p>
          <p className='pl-4'>
            (xiii) there are no implied warranties for Artichokee Promos.
            Invalid or unidentifiable Artichokee Promos will not be redeemed. To
            check the available balance on a Artichokee Promo, visit your
            Account profile. Artichokee reserves the right to correct the
            Artichokee Promo balance if we believe that a clerical, billing or
            accounting error has occurred. You also agree that we may
            deactivate, cancel or suspend any Artichokee Promos if we suspect
            fraud, unlawful activity or improper Artichokee Promo use.
            Artichokee Promos terms and conditions are subject to change without
            notice. You also understand that Artichokee Promos must be used
            within the time period specified if not purchased Artichokee Promos,
            and therefore Artichokee Promos may expire prior to your use. ii.
            Qualified Referral Discounts. A Qualified Referral may also receive
            a discount towards their first order (“Qualified Referral
            Discount”). The Qualified Referral Discount must be used by
            Qualified Referral when placing their first order from their Account
            and on no other order. Qualified Referral Discounts are valid for
            one-time use. If Qualified Referral does not use the Qualified
            Referral Discount within thirty (30) days of receipt of a referral
            invitation to set up an Account on the Services, the Qualified
            Referral Discount will expire. Qualified Referral Discounts will
            also automatically expire if your Account is terminated for any
            reason. Qualified Referral Discounts may only be applied to minimum
            orders on the Services as set forth by individual Restaurants and
            are otherwise subject to any individual Restaurant requirements.
          </p>
          <p>(ix) Qualified Referral Discounts are</p>
          <p className='pl-4'>(i) non-transferrable between user Accounts;</p>
          <p className='pl-4'>(ii) are not redeemable as cash;</p>
          <p className='pl-4'>
            (iii) may not be used to pay any past due balance; and
          </p>
          <p className='pl-4'>
            (iv) cannot be combined with Artichokee Promos or other promotional
            codes. Artichokee reserves the right to change any aspect of the
            referral program at any time, including, without limitation, the
            redemption value of any Qualified Referral Discounts that have not
            already been issued. We reserve the right to suspend your right to
            receive Qualified Referral Discounts or to not issue you Qualified
            Referral Discounts if you have violated any of these Terms.
          </p>

          <p>
            (x) Return Policy. If any food or delivery issues arise after you
            submit your order, please contact the Restaurant directly for the
            fastest service. All purchases are final unless an issue arises
            which is confirmed with a Artichokee Restaurant, or otherwise
            determined by Artichokee, at its sole discretion. If you require
            further assistance please contact us within 24 hours of the order
            date. No cash refunds will be issued by Artichokee.
          </p>
          <h2>
            <br />
          </h2>
          <h2>
            <b>Termination</b>
          </h2>
          <p>
            We may terminate your access to all or any part of the Services at
            any time, with or without cause, with or without notice, effective
            immediately, which may result in the forfeiture and destruction of
            all information associated with your membership. Notwithstanding the
            foregoing, if you:
          </p>
          <p className='pl-4'>(i) are a Restaurant,</p>
          <p className='pl-4'>
            (ii) are not in violation of these Terms of Service, and
          </p>
          <p className='pl-4'>
            (iii) have used the Services in conjunction with Third Party
            Services, we will allow you a reasonable period of time, not to
            exceed 30 days, to export your data to those Third Party Services.
            If you wish to terminate your Account, you may do so by following
            the instructions on the Site or through the Services. Any fees paid
            hereunder are non-refundable. All provisions of these Terms of
            Service which by their nature should survive termination shall
            survive termination, including, without limitation, licenses of User
            Content, ownership provisions, warranty disclaimers, indemnity and
            limitations of liability.
          </p>
          <h2>
            <br />
          </h2>
          <h2>
            <b>Warranty Disclaimer</b>
          </h2>
          <p>
            (i) We have no special relationship with or fiduciary duty to you.
            You acknowledge that We have no duty to take any action regarding:
          </p>
          <p className='pl-4'>i. which users gain access to the Services;</p>
          <p className='pl-4'>
            ii. what Content you access via the Services; or
          </p>
          <p className='pl-4'>iii. how you may interpret or use the Content.</p>
          <p>
            (ii) You release us from all liability for you having acquired or
            not acquired Content through the Services. We make no
            representations concerning any Content contained in or accessed
            through the Services, and we will not be responsible or liable for
            the accuracy, copyright compliance, or legality of material or
            Content contained in or accessed through the Services.
          </p>
          <p>
            (iii) THE SERVICES AND CONTENT ARE PROVIDED “AS IS”, “AS AVAILABLE”
            AND WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT
            NOT LIMITED TO, THE IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT,
            MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, AND ANY
            WARRANTIES IMPLIED BY ANY COURSE OF PERFORMANCE OR USAGE OF TRADE,
            ALL OF WHICH ARE EXPRESSLY DISCLAIMED. WE, AND OUR DIRECTORS,
            EMPLOYEES, AGENTS, SUPPLIERS, PARTNERS AND CONTENT PROVIDERS DO NOT
            WARRANT THAT: (I) THE SERVICES WILL BE SECURE OR AVAILABLE AT ANY
            PARTICULAR TIME OR LOCATION; (II) ANY DEFECTS OR ERRORS WILL BE
            CORRECTED; (III) ANY CONTENT OR SOFTWARE AVAILABLE AT OR THROUGH THE
            SERVICES IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; OR (IV) THE
            RESULTS OF USING THE SERVICES WILL MEET YOUR REQUIREMENTS. YOUR USE
            OF THE SERVICES IS SOLELY AT YOUR OWN RISK. ARTICHOKEE.COM RESERVES
            THE RIGHT TO LIMIT PURCHASE AND PURCHASE QUANTITY. IN THE EVENT OF
            ANY SITE ERROR, CUSTOMERS WILL BE LIMITED TO A SINGLE UNIT PURCHASE.
            PRICING ON THIS SITE MAY BE SUBJECT TO CHANGE WITHOUT EXPRESS
            WRITTEN NOTICE.
          </p>
          <p>
            (iv) Artichokee further disclaims any and all liability for the
            acts, omissions, conduct of any third-party users, Artichokee users,
            advertisers, and/or sponsors related to the Services. Artichokee is
            not responsible for the products, services, actions, or failure to
            act of any third party in the connection with the Services. Without
            limiting the foregoing, you may contact us regarding the misconduct
            of users and/or third-party advertisers, service, and/or product
            providers referenced on, or included in the Services, to Artichokee.
            Artichokee may investigate the claim and take appropriate action, at
            its sole discretion.
          </p>
          <h2>
            <br />
          </h2>
          <h2>
            <b>Indemnification</b>
          </h2>
          <p>
            You shall defend, indemnify, and hold harmless us, our affiliates
            and each of our and their respective employees, contractors,
            directors, suppliers and representatives from all liabilities,
            claims, and expenses, including reasonable attorneys’ fees, that
            arise from or relate to your use or misuse of, or access to, the
            Services, Content, or otherwise from your User Content, violation of
            these Terms of Service (including, without limitation, the purchase,
            attempt to purchase, receipt, or consumption of alcohol while
            underage), gross negligence, fraud, or infringement by you, or any
            third party using your Account or identity in the Services, of any
            intellectual property or other right of any person or entity,
            including your use of the Services to provide a link to another
            website or to upload content or other information to the Services.
            Without limiting the generality of the foregoing, your indemnity
            obligations hereunder extend to your or a third party’s personal
            injury, illness, or death resulting from the consumption of any food
            or beverages ordered through the Services, including as a result of
            allergies, and any violation by you or any third party of applicable
            law in connection with orders you have placed through the Services
            including, without limitation, alcohol purchase and consumption laws
            and regulations. We reserve the right to assume the exclusive
            defense and control of any matter otherwise subject to
            indemnification by you, in which event you will assist and cooperate
            with us in asserting any available defenses.
          </p>
          <h2>
            <br />
          </h2>
          <h2>
            <b>Limitation of Liability</b>
          </h2>
          <p>
            IN NO EVENT SHALL WE, NOR OUR DIRECTORS, EMPLOYEES, AGENTS,
            PARTNERS, SUPPLIERS OR CONTENT PROVIDERS, BE LIABLE UNDER CONTRACT,
            TORT, STRICT LIABILITY, NEGLIGENCE OR ANY OTHER LEGAL OR EQUITABLE
            THEORY WITH RESPECT TO THE SERVICES
          </p>
          <p>
            (I) FOR ANY LOST PROFITS, DATA LOSS, COST OF PROCUREMENT OF
            SUBSTITUTE GOODS OR SERVICES, OR SPECIAL, INDIRECT, INCIDENTAL,
            PUNITIVE, COMPENSATORY OR CONSEQUENTIAL DAMAGES OF ANY KIND
            WHATSOEVER (HOWEVER ARISING),
          </p>
          <p>
            (II) FOR ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE (REGARDLESS
            OF THE SOURCE OF ORIGINATION), OR
          </p>
          <p>
            (III) FOR ANY DIRECT DAMAGES IN EXCESS OF (IN THE AGGREGATE) OF the
            greater of (A) fees paid to us for the particular Services during
            the immediately previous three (3) month period or (B) $500.00. SOME
            JURISDICTIONS, INCLUDING THE STATE OF NEW JERSEY, DO NOT ALLOW THE
            EXCLUSION OF CERTAIN WARRANTIES OR LIMITATION OF LIABILITIES.
            THEREFORE, SOME OF THE ABOVE LIMITATIONS AND EXCLUSIONS MAY NOT
            APPLY TO YOU. NOTHING IN THESE TERMS OF SERVICE SHALL AFFECT ANY
            NON-WAIVABLE STATUTORY RIGHTS THAT APPLY TO YOU. ARBITRATION CLAUSE
            & CLASS ACTION WAIVER – IMPORTANT – PLEASE REVIEW AS THIS AFFECTS
            YOUR LEGAL RIGHTS
          </p>
          <p className='pl-4'>
            i. Arbitration. YOU AGREE THAT ALL DISPUTES BETWEEN YOU AND US OR
            OUR OFFICERS, DIRECTORS OR EMPLOYEES IN THEIR CAPACITY AS SUCH
            (WHETHER OR NOT SUCH DISPUTE INVOLVES A THIRD PARTY) WITH REGARD TO
            YOUR RELATIONSHIP WITH US, INCLUDING WITHOUT LIMITATION DISPUTES
            RELATED TO THESE TERMS OF SERVICE, YOUR USE OF THE SERVICES, AND/OR
            RIGHTS OF PRIVACY AND/OR PUBLICITY, WILL BE RESOLVED BY BINDING,
            INDIVIDUAL ARBITRATION IN ACCORDANCE WITH THE STREAMLINED
            ARBITRATION RULES AND PROCEDURES OF JAMS, INC. THEN IN EFFECT, AND
            YOU AND WE HEREBY EXPRESSLY WAIVE TRIAL BY JURY; PROVIDED, HOWEVER,
            THAT TO THE EXTENT THAT YOU HAVE IN ANY MANNER VIOLATED OR
            THREATENED TO VIOLATE OUR INTELLECTUAL PROPERTY RIGHTS, WE MAY SEEK
            INJUNCTIVE OR OTHER APPROPRIATE RELIEF IN ANY STATE OR FEDERAL COURT
            IN THE STATE OF GEORGIA. DISCOVERY AND RIGHTS TO APPEAL IN
            ARBITRATION ARE GENERALLY MORE LIMITED THAN IN A LAWSUIT, AND OTHER
            RIGHTS THAT YOU AND WE WOULD HAVE IN COURT MAY NOT BE AVAILABLE IN
            ARBITRATION. AS AN ALTERNATIVE, YOU MAY BRING YOUR CLAIM IN YOUR
            LOCAL “SMALL CLAIMS” COURT, IF PERMITTED BY THAT SMALL CLAIMS
            COURT'S RULES AND IF WITHIN SUCH COURT’S JURISDICTION, UNLESS SUCH
            ACTION IS TRANSFERRED, REMOVED OR APPEALED TO A DIFFERENT COURT. YOU
            MAY BRING CLAIMS ONLY ON YOUR OWN BEHALF. NEITHER YOU NOR WE WILL
            PARTICIPATE IN A CLASS ACTION OR CLASS-WIDE ARBITRATION FOR ANY
            CLAIMS COVERED BY THIS AGREEMENT TO ARBITRATE. YOU ARE GIVING UP
            YOUR RIGHT TO PARTICIPATE AS A CLASS REPRESENTATIVE OR CLASS MEMBER
            ON ANY CLASS CLAIM YOU MAY HAVE AGAINST US INCLUDING ANY RIGHT TO
            CLASS ARBITRATION OR ANY CONSOLIDATION OF INDIVIDUAL ARBITRATIONS.
            You also agree not to participate in claims brought in a private
            attorney general or representative capacity, or consolidated claims
            involving another person's account, if we are a party to the
            proceeding. This dispute resolution provision will be governed by
            the Federal Arbitration Act and not by any state law concerning
            arbitration. In the event JAMS, Inc. is unwilling or unable to set a
            hearing date within one hundred and sixty (160) days of filing the
            case, then either we or you can elect to have the arbitration
            administered instead by the American Arbitration Association.
            Judgment on the award rendered by the arbitrator may be entered in
            any court having competent jurisdiction. The arbitration shall be
            conducted in the English language. Any provision of applicable law
            notwithstanding, the arbitrator will not have authority to award
            damages, remedies or awards that conflict with these Terms of
            Service. You agree that regardless of any statute or law to the
            contrary, any claim or cause of action arising out of, related to or
            connected with the use of the Services or these Terms of Services
            must be filed within one (1) year after such claim of action arose
            or be forever banned.
          </p>
          <p className='pl-4'>
            ii. 30-Day Opt-Out Period. If you do not wish to be bound by the
            arbitration and class-action waiver provisions in this Section 14,
            you must notify us in writing within 30 days of the date that you
            first accept these Terms (unless a longer period is required by
            applicable law). Your written notification must be mailed to us at
            the following address: 4780 Ashford Dunwoody Rd. A310, Atlanta, GA
            30338. If you do not notify us in accordance with this Section
            14(b), you agree to be bound by the arbitration and class-action
            waiver provisions of these Terms, including such provisions in any
            Terms revised after the date of your first acceptance. Such
            notification must include: (i) your name; (ii) your mailing address
            and (iv) a statement that you do not wish to resolve disputes with
            us through arbitration. If we make any changes to the Arbitration
            and Class Action Waiver section of these Terms (other than a change
            to the address at which we will receive notices of dispute, opt-out
            notices, or rejections of future changes to the Arbitration and
            Clause Action Waiver section), you may reject any such change by
            sending us written notice within 30 days of the change to the
            address set forth in this Section 14(b). It is not necessary to send
            us a rejection of a future change to the Arbitration and Class
            Action Waiver section of these Terms if you had properly opted out
            of the arbitration and class-action waiver provisions in this
            Section 14 within the first 30 days after you first accepted these
            Terms. If you have not properly opted out of the arbitration and
            class-action waiver provisions in this Section 14, by rejecting a
            future change, you are agreeing that you will arbitrate any dispute
            between us in accordance with the language of this arbitration
            provision, as modified by any changes you did not reject. This
            notification affects these Terms only; if you previously entered
            into other arbitration agreements with us or enter into other such
            agreements in the future, your notification that you are opting out
            of the arbitration provision in these Terms shall not affect the
            other arbitration agreements between you and us.
          </p>
          <p className='pl-4'>
            iii. Severability. If the prohibition against class actions and
            other claims brought on behalf of third parties contained above is
            found to be unenforceable, then all of the preceding language in
            this Arbitration section will be null and void. This arbitration
            agreement will survive the termination of your relationship with us.
          </p>
          <h2>
            <br />
          </h2>
          <h2>
            <b>Disputes with Restaurants</b>
          </h2>
          <p>
            If there is a dispute between users of the Services, or between
            users and any third party (including any Restaurant), you understand
            and agree that Artichokee is under no obligation to become involved.
            In the event that you have any such dispute between users and any
            third party (including any Restaurant), you hereby release
            Artichokee (including the Site and the Services), its directors,
            officers, employees, service providers, affiliates, agents and
            successors from all claims, demands, and damages (actual and
            consequential) of every kind or nature, known or unknown, suspected
            and unsuspected, disclosed and undisclosed, arising out of or in any
            way related to such disputes.
          </p>
          <h2>
            <br />
          </h2>
          <h2>
            <b>Governing Law; Equitable Relief and Jurisdiction</b>
          </h2>
          <p>
            These Terms of Service shall be governed by and construed in
            accordance with the laws of the State of Georgia, including its
            conflicts of law rules, and the United States of America. You
            acknowledge and agree that in the event of a breach or threatened
            violation of our intellectual property rights and confidential and
            proprietary information by you, we will suffer irreparable harm and
            will therefore be entitled to injunctive relief to enforce this
            Agreement. Notwithstanding the arbitration provisions herein, we
            may, without waiving any other remedies under this Agreement, seek
            from any court having jurisdiction any interim, equitable,
            provisional, or injunctive relief that is necessary to protect our
            rights and property, pending the outcome of the arbitration
            referenced above. You hereby irrevocably and unconditionally consent
            to the personal and subject matter jurisdiction of the federal and
            state courts in the State of Georgia for purposes of any such action
            by us.
          </p>
          <h2>
            <br />
          </h2>
          <h2>
            <b>Modification</b>
          </h2>
          <p>
            We reserve the right, in our sole discretion, to modify or replace
            any of these Terms of Service, or change, suspend, or discontinue
            the Services (including without limitation, the availability of any
            feature, database, or content) at any time by posting a notice on
            the Site or by sending you notice through the Services, via e-mail
            or by another appropriate means of electronic communication. We may
            also impose limits on certain features and services or restrict your
            access to parts or all of the Services without notice or liability.
            While we will timely provide notice of modifications, it is also
            your responsibility to check these Terms of Service periodically for
            changes. Your continued use of the Services following notification
            of any changes to these Terms of Service constitutes acceptance of
            those changes, which will apply to your continued use of the
            Services going forward. Your use of the Services is subject to the
            Terms of Service in effect at the time of such use.
          </p>
          <h2>
            <br />
          </h2>
          <h2>
            <b>Feedback</b>
          </h2>
          <p>
            We welcome and encourage you to provide feedback, comments and
            suggestions for improvements to the Services (“Feedback”). You may
            submit Feedback by emailing us at support@artichokee.com. You
            acknowledge and agree that all Feedback will be the sole and
            exclusive property of us and you hereby irrevocably assign to us and
            agree to irrevocably assign to us all of your right, title, and
            interest in and to all Feedback, including without limitation all
            worldwide patent, copyright, trade secret, moral and other
            proprietary or intellectual property rights therein. At our request
            and expense, you will execute documents and take such further acts
            as we may reasonably request to assist us to acquire, perfect, and
            maintain its intellectual property rights and other legal
            protections for the Feedback.
          </p>
          <h2>
            <br />
          </h2>
          <h2>
            <b>Email</b>
          </h2>
          <p>
            By registering or placing an order with us, you are consenting to
            receive E-mails from us or the Restaurant regarding your order.
            Additionally, by registering or placing an order with us, you are
            consenting to receive commercial E-mail from us. If you do not wish
            to receive commercial E-mail from us, you may remove your name from
            the E-mail list by contacting us. SMS. By registering or placing an
            order with us, you are consenting to receive SMS messages from us or
            on behalf of the Restaurant regarding your order. Additionally, by
            registering or placing an order with us, you are consenting to
            receive transactional and commercial SMS messages from us. If you do
            not wish to receive SMS messages, you can unsubscribe at any time.
            To unsubscribe, respond ‘STOP’ to the received text. Standard data
            and message rates may apply for text message alerts. Please contact
            your mobile phone carrier for details. If you require assistance,
            you can call our customer support team by email at
            support@artichokee.com.
          </p>
          <h2>
            <br />
          </h2>
          <h2>
            <b>Mobile Application</b>
          </h2>
          <p>
            Our mobile application operates on an application linked to a
            particular mobile device and operating system. Upon downloading our
            mobile application, you will have the option to opt in to receive
            push notifications from us on your mobile device. Such notifications
            may include promotional communications, offers, and transactional
            messages pushed to your device or within the app. You may choose to
            turn off such push and in-app notifications by changing your
            settings on your mobile device or through the settings in the mobile
            application.
          </p>
          <h2>
            <br />
          </h2>
          <h2>
            <b>Miscellaneous</b>
          </h2>
          <p>
            (i) Entire Agreement and Severability. These Terms of Service are
            the entire agreement between you and us with respect to the
            Services, including use of the Site, and supersede all prior or
            contemporaneous communications and proposals (whether oral, written
            or electronic) between you and us with respect to the Services. If
            any provision of these Terms of Service is found to be unenforceable
            or invalid, that provision will be limited or eliminated to the
            minimum extent necessary so that these Terms of Service will
            otherwise remain in full force and effect and enforceable. The
            failure of either party to exercise in any respect any right
            provided for herein shall not be deemed a waiver of any further
            rights hereunder.
          </p>
          <p>
            (ii) Force Majeure. We shall not be liable for any failure to
            perform our obligations hereunder where such failure results from
            any cause beyond our reasonable control, including, without
            limitation, mechanical, electronic or communications failure or
            degradation.
          </p>
          <p>
            (iii) Assignment. These Terms of Service are personal to you, and
            are not assignable, transferable or sublicensable by you except with
            our prior written consent. We may assign, transfer or delegate any
            of our rights and obligations hereunder without consent.
          </p>
          <p>
            (iv) Agency. No agency, partnership, joint venture, or employment
            relationship is created as a result of these Terms of Service and
            neither party has any authority of any kind to bind the other in any
            respect.
          </p>
          <p>
            (v) Notices. Unless otherwise specified in these Term of Service,
            all notices under these Terms of Service will be in writing and will
            be deemed to have been duly given when received, if personally
            delivered or sent by certified or registered mail, return receipt
            requested; when receipt is electronically confirmed, if transmitted
            by facsimile or e-mail; or the day after it is sent, if sent for
            next day delivery by recognized overnight delivery service.
            Electronic notices should be sent to legal@Artichokee.com.
          </p>
          <p>
            (vi) No Waiver. Our failure to enforce any part of these Terms of
            Service shall not constitute a waiver of our right to later enforce
            that or any other part of these Terms of Service. Waiver of
            compliance in any particular instance does not mean that we will
            waive compliance in the future. In order for any waiver of
            compliance with these Terms of Service to be binding, we must
            provide you with written notice of such waiver through one of our
            authorized representatives.
          </p>
          <p>
            (vii) Headings. The section and paragraph headings in these Terms of
            Service are for convenience only and shall not affect their
            interpretation.
          </p>
        </Col>
      </Row>
    </Container>
  )
}

export default TermsOfUse

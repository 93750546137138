import React, { useState, useEffect, useContext } from 'react'
import Card from './Card/index'
import { CompanyContext } from 'contexts/CompanyContext'
// import { CartContext } from 'contexts/CartContext/CartContext'
import RestaurantDetail from 'Components/RestaurantDetail/RestaurantDetail'
import { Container, Row, Col, ModalHeader, Modal, ModalBody, Spinner } from 'reactstrap'
import moment from 'moment'
import { API } from 'aws-amplify'
import { getCompanyCategories } from 'graphql/custom-queries'
// import { useHistory } from 'react-router-dom'
//Scss Imports
import './typeonesr.scss'
const TypeOneSR = ({ singleRestaurant }) => {
  const [menuAlert, setMenuAlert] = useState(false)
  const [categories, setCategories] = useState([])
  const [isCategoriesLoading, setisCategoriesLoading] = useState(false)
  // const { clearCart, cartItems } = useContext(CartContext)
  // const history = useHistory()

  // useEffect(() => {
  //   if (cartItems.length > 0) {
  //     if (cartItems[0].GeneralInformationRestaurant.RestaurantType === 'type1') {
  //       history.push('/order')
  //     } else if (cartItems[0].GeneralInformationRestaurant.RestaurantType === 'type2') {
  //       history.push('/checkout')
  //     }
  //   }
  // }, [])
  const extractProducts = (products) => {
    // We parse the products and return our arrays in the last object.
    //active ve dayofweekliler icin
    let dayOfWeekProducts = []
    //active ve iki tarih araligi
    let rangeOfWeekProducts = []

    products.map((product) => {
      const {
        Product: { isActive, dayOfWeek, isArchive },
      } = product
      if (dayOfWeek && isActive === true && isArchive === false) {
        dayOfWeekProducts.push(product.Product)
      } else if (dayOfWeek === '' && isActive === true && isArchive === false) {
        rangeOfWeekProducts.push(product.Product)
      }
    })

    let sortedDayOfWeekProducts = dayOfWeekProducts.sort(
      (a, b) => moment(a.dayOfWeek, 'YYYY-MM-DD') - moment(b.dayOfWeek, 'YYYY-MM-DD')
    )

    return { dayOfWeekProducts: sortedDayOfWeekProducts, rangeOfWeekProducts }
  }

  async function getCategories(restID) {
    setisCategoriesLoading(true)
    try {
      const res = await API.graphql({
        variables: { id: restID },
        query: getCompanyCategories,
        authMode: 'AWS_IAM',
      })

      res.data.getCompany.categories.items.sort((a, b) =>
        a.categorySequence > b.categorySequence ? 1 : b.categorySequence > a.categorySequence ? -1 : 0
      )

      setCategories(res.data.getCompany.categories.items)
      setisCategoriesLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getCategories(singleRestaurant.id)
  }, [])

  return (
    <>
      <Container className='pt-4'>
        <RestaurantDetail restaurant={singleRestaurant} isDetail={true} />
      </Container>

      <hr></hr>
      {isCategoriesLoading ? (
        <div className='d-flex justify-content-center'>
          <Spinner color='warning' />
        </div>
      ) : (
        <Container className='main-card-cont'>
          {categories.map((category) => {
            const {
              categoryName,
              categoryDescription,
              id,
              CategoryProducts: { items: products },
            } = category
            const { dayOfWeekProducts, rangeOfWeekProducts } = extractProducts(products)
            return (
              <React.Fragment key={id}>
                <Container>
                  <Row>
                    <Col>
                      <h3>{categoryName}</h3>
                      <p>{categoryDescription}</p>
                    </Col>
                  </Row>
                  <Row className='menu-card-row'>
                    {/* isactive passive ise card componentine menuyu hic gondermiyoruz */}
                    {dayOfWeekProducts.map((product, index) => (
                      <Card
                        alertToggle={setMenuAlert}
                        menuAlert={menuAlert}
                        key={index}
                        product={product}
                        singleRestaurant={singleRestaurant}
                      />
                    ))}
                    {rangeOfWeekProducts.map((product, index) => (
                      <Card
                        alertToggle={setMenuAlert}
                        menuAlert={menuAlert}
                        key={index}
                        product={product}
                        singleRestaurant={singleRestaurant}
                      />
                    ))}
                  </Row>
                </Container>
              </React.Fragment>
            )
          })}
          <Row className='menu-card-row'>
            {categories.map((category) => {
              const {
                CategoryProducts: { items: products },
              } = category
              const { dayOfWeekProducts, rangeOfWeekProducts } = extractProducts(products)

              return products.map((product, index) => {
                return (
                  !product.Product.isActive && (
                    <Card
                      alertToggle={setMenuAlert}
                      menuAlert={menuAlert}
                      key={index}
                      product={product.Product}
                      singleRestaurant={singleRestaurant}
                    />
                  )
                )
              })
            })}
          </Row>
          <Row className='py-0'>
            <Col>
              {categories.length === 0 ? (
                <div className='d-flex justify-content-center align-items-center flex-column'>
                  <h3 className='font-weight-bold py-3'>Thank you for your interest in {singleRestaurant.name}!</h3>
                  <p style={{ fontSize: '1.25rem' }}>
                    We are currently in the process of adding them to the Artichokee community.
                    <br />
                    If you would like to order from them in the meantime please
                    <a style={{ color: '#FF4C01' }} href={'https://' + singleRestaurant.companyUrl} target='_blank'>
                      {' '}
                      Click Here.
                    </a>
                  </p>
                </div>
              ) : null}
            </Col>
          </Row>
        </Container>
      )}
      <Modal className='menu-alert' isOpen={menuAlert} toggle={() => setMenuAlert(!menuAlert)}>
        <ModalHeader toggle={() => setMenuAlert(!menuAlert)}>
          <span className='alert-header'>Warning</span>
        </ModalHeader>
        <ModalBody>
          You can not add more than one menu to your order cart. <br />
          <b>Please complete your shopping.</b>
        </ModalBody>
      </Modal>
    </>
  )
}

export default TypeOneSR

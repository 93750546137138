import React, { useState, useEffect, useContext } from 'react'
import { API } from 'aws-amplify'
import { listCategorys } from '../../graphql/custom-queries'
import { CartContext } from '../../contexts/CartContext/CartContext'

import { Container, Row, Col, Spinner, Alert, Modal, Button, ModalBody, ModalHeader } from 'reactstrap'
import ScrollMenu from 'react-horizontal-scrolling-menu'
import TempVillageModal from '../../Components/TempVillageModal/TempVillageModal'
import CategoryMenuCard from '../../Components/CategoryMenuCard/CategoryMenuCard'
import SocialLinks from 'Components/SocialLinks/SocialLinks'

import moment from 'moment'

import './tempvillage.scss'
function AddedSuccessfullModal({ toggle, modal, setVisibleSuccessfulModal }) {
  return (
    <Modal className='added-to-cart-successful-modal' size='lg' isOpen={modal} toggle={toggle}>
      {/* toggle kullanılcak modalı kapatmak için */}

      <div className='pt-3 pr-3'>
        <div className='times-cont' onClick={() => setVisibleSuccessfulModal(false)}>
          <i class='fas fa-times'></i>
        </div>
      </div>

      <ModalBody className='d-flex justify-content-center align-items-center flex-column'>
        <div className='check-cont'>
          <i class='fas fa-check fa-3x'></i>
        </div>
        <h4 className='message'>Product has been added to your cart.</h4>
      </ModalBody>
    </Modal>
  )
}
const MenuItem = ({ text, isSelected, categoryId, index, list }) => {
  // We get index and category list because when click a menu item we want to send scroll to specific component
  // But there are various use cases about scroll position so we positioned scroll with element offsetHeights
  function goToElement(categoryId, index) {
    var horizontalMenu = document.getElementsByClassName('horizontal-menu')
    var restDetailSection = document.getElementById('rest-detail-section')
    let totalCategoryHeight = 0
    for (let i = 0; i < index; i++) {
      totalCategoryHeight += document.getElementById(list[i].id).offsetHeight
    }
    let totalHeight = restDetailSection.offsetHeight + totalCategoryHeight + horizontalMenu[0].offsetHeight - 60
    window.scrollTo({
      top: totalHeight,
      behavior: 'smooth',
    })
  }
  return (
    <div
      className={`menu-item ${text === isSelected.selected ? 'active-border' : ''}`}
      onClick={() => goToElement(categoryId, index)}>
      <h2 className={`${text === isSelected.selected ? 'active' : ''}`}>{text}</h2>
      {text === isSelected.selected && <div className='sub-border'></div>}
    </div>
  )
}
export const Menu = (list, isSelected) =>
  list.map((el, index) => {
    const { name, id } = el

    return <MenuItem text={name} key={name} isSelected={isSelected} categoryId={id} index={index} list={list} />
  })

const Arrow = ({ text, className }) => {
  return (
    <div className={className}>
      {text === 'right' ? <i class='fas fa-chevron-right'></i> : <i class='fas fa-chevron-left'></i>}
    </div>
  )
}

const ArrowLeft = Arrow({ text: 'left', className: 'arrow-common arrow-left' })
const ArrowRight = Arrow({
  text: 'right',
  className: 'arrow-common arrow-right',
})
const selected = 'Populer Items'

function TempVillage({ singleRestaurant }) {
  const { cartItems } = useContext(CartContext)
  const [categories, setCategories] = useState(null)
  const [isSelected, setIsSelected] = useState({ selected })
  const [categoryTitles, setCategoryTitles] = useState([])
  const [menuItems, setmenuItems] = useState(Menu(categoryTitles, isSelected))
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)
  const [singleProduct, setSingleProduct] = useState(null)
  const [visibleSuccessfulModal, setVisibleSuccessfulModal] = useState(false)
  const [menuAlert, setMenuAlert] = useState(false)

  const toggleSuccessfulModal = () => setVisibleSuccessfulModal(!visibleSuccessfulModal)

  function getCategoryTitles(categories) {
    const tempCategoryTitle = []
    categories.map(({ categoryName, id }) => {
      tempCategoryTitle.push({
        name: categoryName,
        id: id,
      })
    })
    setCategoryTitles(tempCategoryTitle)
    setmenuItems(Menu(tempCategoryTitle, isSelected))
  }

  async function bringMenuCategories() {
    try {
      const res = await API.graphql({
        query: listCategorys,
        authMode: 'AWS_IAM',
      })

      res.data.listCategorys.items.sort((a, b) =>
        a.categorySequence > b.categorySequence ? 1 : b.categorySequence > a.categorySequence ? -1 : 0
      )

      setCategories(res.data.listCategorys.items)
      getCategoryTitles(res.data.listCategorys.items)
    } catch (err) {
      console.log(err)
    }
  }
  const onSelect = (key) => {
    setmenuItems(Menu(categoryTitles, { selected: key }))
    setIsSelected({ selected: key })
  }
  const [groupMinMaxQty, setGroupMinMaxQty] = useState([])

  const [singleRequiredList, setSingleRequiredList] = useState([])

  function openProductModal(product) {
    if (cartItems.length === 0 || singleRestaurant.name === cartItems[0].GeneralInformationRestaurant.RestaurantName) {
      let tempArr = []

      product.extraItemGroup.items.map((x, i) => {
        if (x.isOptional === true) {
          tempArr.push({
            GroupName: x.GroupName,
            GroupMinQty: 0,
            GroupMaxQty: x.opMaximumExtraItem,
          })
        } else {
          tempArr.push({
            GroupName: x.GroupName,
            GroupMinQty: x.reqMinimumExtraItem,
            GroupMaxQty: x.reqMaximumExtraItem,
          })
        }
      })

      setGroupMinMaxQty(tempArr)
      toggle()

      let pro = product.extraItemGroup.items.map((x) => {
        return { ...x, control: true }
      })

      delete product.extraItemGroup.items
      product['extraItemGroup']['items'] = pro

      let requiredList = []
      product.extraItemGroup.items.map((x) => {
        if (x.isOptional === false) {
          requiredList.push(x)
        }
      })
      setSingleRequiredList(requiredList)

      setSingleProduct(product)
    } else {
      setMenuAlert(!menuAlert)
    }

    //setSingleProductGroups(product.extraItemGroup.items)
    //setTotal(product.price)
  }

  const closeSuccessModalTimeOut = () => {
    setVisibleSuccessfulModal(true)
    setTimeout(() => {
      setVisibleSuccessfulModal(false)
    }, 1500)
  }
  function isWorkTime() {
    // Return true if locale time is between Village Burger working hours
    const timeStart = moment(singleRestaurant.workingHours.openingTime, 'hh:mm A')
    const timeEnd = moment(singleRestaurant.workingHours.closingTime, 'hh:mm A')

    if (moment().isBetween(timeStart, timeEnd)) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    bringMenuCategories()
  }, [])

  return categories === null ? (
    <div className='text-center pt-5'>
      <Spinner color='warning' />
    </div>
  ) : (
    <Container className='tempvillage-cont'>
      <Row className='restaurant-detail-row' id='rest-detail-section'>
        <Container fluid>
          <Row className='jumbotron-logo'>
            <Col>
              <img
                className='header-jumbotron'
                src='https://img.cdn4dd.com/cdn-cgi/image/fit=contain,width=1920,format=auto,quality=50/https://cdn.doordash.com/media/store%2Fheader%2F6d5c6ec4-50c7-4d6c-a9cb-f4b1e93eca8d.jpg'
                alt='jumbotron'
              />
              <div className='logo-container'>
                <img className='logo' src={singleRestaurant.logo} alt='logo' />
              </div>
            </Col>
          </Row>
          <Row className='restaurant-details'>
            <Col xl='8' lg='8' md='8' sm='12' xs='12'>
              {' '}
              <h1 className='restaurant-name'>{singleRestaurant.name}</h1>
              <ul className='m-0 pl-4'>
                <li className='description'>
                  {singleRestaurant.foodType.typeOne +
                    ', ' +
                    singleRestaurant.foodType.typeTwo +
                    ', ' +
                    singleRestaurant.foodType.typeThree}
                </li>
              </ul>
            </Col>
            <Col xl='4' lg='4' md='4' sm='12' xs='12'>
              <div className=' social-icons-cont'>
                <SocialLinks restaurant={singleRestaurant} />
              </div>
            </Col>
          </Row>
          <Row className='info'>
            <Col xl='9' lg='9' md='9' sm='9' xs='7'>
              {isWorkTime() ? (
                <div className='calufe-open'>
                  <span color='SystemWhite' display='block'>
                    Open
                  </span>
                  <div>
                    <span display='block'>
                      <span color='SystemWhite' display='block'>
                        closes at 8:30 pm
                      </span>
                    </span>
                  </div>
                </div>
              ) : (
                <div className='calufe-closed'>
                  <span color='SystemWhite' display='block'>
                    Closed
                  </span>
                  <div>
                    <span display='block'>
                      <span color='SystemWhite' display='block'>
                        opens at 11:00 am
                      </span>
                    </span>
                  </div>
                </div>
              )}
            </Col>
            <Col className='d-flex justify-content-end align-items-center' xl='3' lg='3' md='3' sm='3' xs='5'>
              <div className='text-right pickup-btn'>
                <div className='pickup-span'>
                  Pickup
                  <div className='triangle'></div>
                </div>
              </div>
            </Col>
            <Col xl='12' lg='12' md='12' sm='12' xs='12'>
              <div className='pickup-address-cont'>
                <div className='sub-cont'>
                  <span className='pickup-address-desc'>This is a Pickup order</span>
                  <span className='pickup-address'>
                    You'll need to go to Village Burger to pick up this order:
                    {singleRestaurant.location.street}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <hr />
          <Row className='full-menu-row'>
            <Col>
              <div>
                <span className='full-menu'>Full Menu</span>
              </div>
              <span className='rest-opening-time'>11:00 am - 8:30 pm</span>
            </Col>
          </Row>
        </Container>
      </Row>

      <Row className='horizontal-menu-row'>
        <Col>
          <ScrollMenu
            data={menuItems}
            arrowLeft={ArrowLeft}
            arrowRight={ArrowRight}
            selected={isSelected}
            onSelect={onSelect}
          />
        </Col>
      </Row>
      <Row id='inclusive-section'>
        {/* We will return this section for every category and their menus. */}
        {categories.map((category, index) => {
          return (
            <Container key={index} id={category.id} className='menus-section'>
              <Row className='category-info-cont'>
                <Col className='category-header-cont'>
                  <h2>{category.categoryName}</h2>
                  <h3>{category.categoryDescription}</h3>
                </Col>
              </Row>

              <Row className='category-body-row'>
                {category.product.items.map((product) => {
                  return (
                    <Col className='py-2' xl='5' lg='5' md='6' sm='8' xs='12'>
                      <CategoryMenuCard product={product} openProductModal={openProductModal} />
                    </Col>
                  )
                })}
              </Row>
            </Container>
          )
        })}
      </Row>
      <TempVillageModal
        modal={modal}
        toggle={toggle}
        setSingleProduct={setSingleProduct}
        singleProduct={singleProduct}
        groupMinMaxQty={groupMinMaxQty}
        villageRes={singleRestaurant}
        toggleSuccessfulModal={closeSuccessModalTimeOut}
        singleRequiredList={singleRequiredList}
      />
      <AddedSuccessfullModal
        toggle={toggleSuccessfulModal}
        modal={visibleSuccessfulModal}
        setVisibleSuccessfulModal={setVisibleSuccessfulModal}
      />
      <Modal className='menu-alert' isOpen={menuAlert} toggle={() => setMenuAlert(!menuAlert)}>
        <ModalHeader toggle={() => setMenuAlert(!menuAlert)}>
          <span className='alert-header'>Warning</span>
        </ModalHeader>
        <ModalBody>
          You can not add a menu from different restaurant <br />
          <b>Please complete your shopping or remove your items from your cart</b>
        </ModalBody>
      </Modal>
    </Container>
  )
}

export default TempVillage

//TODO Food type ın olduğu kısımda food type var mı yok mu diye kontrol yapılmıyor şuan ki restaurantın 2 tipi olduğu için sonrasında tekrar bakılması gerekiyor

import React from 'react'
import {
  Label,
  Input,
  FormGroup,
  InputGroupText,
  InputGroupAddon,
  InputGroup,
} from 'reactstrap'
import './standardinput.scss'
function StandardInput({
  labelTitle,
  isRequired,
  inputName,
  emptyControl,
  inputType,
  refTemp,
  maxLen,
  placeHolder,
  isPassword = false,
  togglePasswordEye,
  defVal,
  disable=false
}) {
  return (
    <>
      <FormGroup className='standard-input'>
        {placeHolder !== '' ? null : (
          <Label className='lable-title' for='exampleName'>
            {labelTitle} {isRequired && <span className='star'>*</span>}
          </Label>
        )}
        <InputGroup>
          <Input
            maxLength={maxLen}
            type={inputType}
            name={inputName}
            placeholder={placeHolder}
            innerRef={refTemp}
            defaultValue={defVal !== '' ? defVal : ''}
            disabled={disable}
          />
          {isPassword ? (
            <InputGroupAddon className='input-group-text' addonType='append'>
              <i
                class={
                  inputType === 'password' ? 'fas fa-eye-slash' : 'fas fa-eye'
                }
                aria-hidden='true'
                style={{ color: '#675cff', fontSize: 18 }}
                onClick={togglePasswordEye}
              ></i>
            </InputGroupAddon>
          ) : null}
        </InputGroup>
        {emptyControl ? (
          <p className='error-message'>{emptyControl.message}</p>
        ) : null}
      </FormGroup>
    </>
  )
}

export default StandardInput

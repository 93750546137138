/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import { listCompanys } from '../graphql/custom-queries'
import { Spinner } from 'reactstrap'

export const CompanyContext = createContext()
export const CompanyContextProvider = (props) => {
  const [restaurants, setRestaurants] = useState([])

  const [progressCircle, setProgressCircle] = useState(true)
  const getAllCompanys = async () => {
    const filter = {
      id: {
        eq: '23d569ef-96b3-42cb-a8c9-5823cc3f4e10', // renzosfire
      },
    }
    try {
      const res = await API.graphql({
        query: listCompanys,
        authMode: 'AWS_IAM',
        variables: { filter: filter },
      })

      // const temp1 = res.data.listCompanys.items[0];
      // const temp2 = res.data.listCompanys.items[3];

      // res.data.listCompanys.items[0] = temp2;
      // res.data.listCompanys.items[3] = temp1;

      setRestaurants(res.data.listCompanys.items)
      setProgressCircle(false)
    } catch (err) {
      console.log('error getting all companies:', err)
    }
  }

  const sortRest = (resta) => {}
  useEffect(() => {
    getAllCompanys()
    sortRest(restaurants)
  }, [])

  return (
    <div>
      {progressCircle ? (
        <div style={{ minHeight: '100vh' }} className='d-flex justify-content-center align-items-center'>
          <Spinner color='warning' />
        </div>
      ) : (
        <CompanyContext.Provider value={{ restaurants }}>{props.children}</CompanyContext.Provider>
      )}
    </div>
  )
}

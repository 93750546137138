export const AddOnPriceCalculate = (AddOnInformation, flag) => {
  // fonsiyonu eger CART.JS den cagiriyorsak flag =1 olarak gonderiyoz.
  let addOnsPrice = 0

  if (flag === 1) {
    AddOnInformation.map((x) => {
      addOnsPrice += parseFloat((x.AddOnsCount * x.AddOnsPrice).toFixed(2))
    })
  } else {
    AddOnInformation.map((x) => {
      addOnsPrice += parseFloat((x.addOnsCount * x.addOnsPrice).toFixed(2))
    })
  }

  return addOnsPrice
}

export const MainMenuPriceCalculate = (MainMenuInformation, flag) => {
  let mainMenuPrice = 0
  if (flag === 1) {
    MainMenuInformation.map((x) => {
      mainMenuPrice += parseFloat((x.MainMenuCount * x.MainMenuPrice).toFixed(2))
    })
  } else {
    mainMenuPrice = parseFloat((MainMenuInformation.mainMenuCount * MainMenuInformation.mainMenuPrice).toFixed(2))
  }

  return mainMenuPrice
}

import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Container,
  Row,
  Col,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Form,
} from 'reactstrap'

import StandardInput from 'Components/Inputs/StandardInput/StandardInput'
function SigninForm({
  register,
  errors,
  formState,
  updateFormState,
  setErrorMessage,
}) {
  const [passwordEye, setPasswordEye] = useState(true)
  const togglePasswordEye = () => setPasswordEye(!passwordEye)
  return (
    <>
      {/* <StandardInput
        labelTitle='Email'
        placeHolder=''
        maxLen={255}
        inputType='text'
        isRequired={true}
        inputName='email'
        refTemp={register({
          required: 'Email is required!',
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Invalid email address!',
          },
        })}
        emptyControl={errors.email}
      /> */}
      <StandardInput
        labelTitle='Username'
        placeHolder=''
        maxLen={255}
        inputType='text'
        isRequired={true}
        inputName='username'
        refTemp={register({
          required: 'Username is required!',
        })}
        emptyControl={errors.username}
      />
      <StandardInput
        labelTitle='Password'
        placeHolder=''
        maxLen={120}
        inputType={passwordEye === true ? 'password' : 'text'}
        isRequired={true}
        inputName='password'
        refTemp={register({
          required: 'Password is required!',
        })}
        emptyControl={errors.password}
        isPassword={true}
        togglePasswordEye={togglePasswordEye}
      />
      <div className='d-flex justify-content-end align-items-center'>
        <span
          className='signup-forgot'
          onClick={() => {
            updateFormState(() => ({
              ...formState,
              formType: 'forgotPassword',
            }))
            setErrorMessage(null)
          }}
        >
          Forgot password?
        </span>
      </div>
      <Button className='signin-btn' type='submit'>
        Sign In
      </Button>
    </>
  )
}

export default SigninForm

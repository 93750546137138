import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom'
//Scss Imports
import './footer.scss'
const Footer = (props) => {
  return (
    <footer>
      <Container className='footer-container' fluid='sm'>
        <Row className='footer-row'>
          <Col className='footer-left' xl='6' lg='6' md='6' sm='6' xs='12'>
            <div className='terms-cont'>
              <Link to='/terms-of-use'>Terms of use </Link>
            </div>
            <div className='terms-cont'>
              <Link to='/privacy-policy'>Privacy Policy</Link>
            </div>
            <div className='terms-cont'>
              <Link to='/cookie-policy'> Cookie Policy</Link>
            </div>
          </Col>
          <Col className='footer-right' xl='6' lg='6' md='6' sm='6' xs='12'>
            <div className='icon-container'>
              <ul className='icons-list'>
                <li>
                  <a href='https://www.instagram.com/artichokee_app/?hl=tr' target='_blank' rel='noopener noreferrer'>
                    <i className='fab fa-instagram fa-md'></i>
                  </a>
                </li>
                <li>
                  <a href='https://twitter.com/Artichokeeapp' target='_blank' rel='noopener noreferrer'>
                    <i className='fab fa-twitter fa-md'></i>
                  </a>
                </li>
                <li>
                  <a
                    href='https://www.facebook.com/profile.php?id=100055677011031'
                    target='_blank'
                    rel='noopener noreferrer'>
                    <i className='fab fa-facebook-f fa-md'></i>
                  </a>
                </li>
                <li>
                  <a
                    href='https://www.linkedin.com/in/artichokee-admin-55aa291b7/'
                    target='_blank'
                    rel='noopener noreferrer'>
                    <i className='fab fa-linkedin-in fa-md'></i>
                  </a>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
export default Footer

import { AddOnPriceCalculate, MainMenuPriceCalculate } from '../../helperFunctions/PriceCalculate/PriceCalculate'

// Storage(cartItems)
//   let itemCount = cartItems.reduce(
//     (total, product) => total + product.quantity,
//     0
//   )
//

//   let total = cartItems
//     .reduce((total, product) => product.mainPrice, 0)
//     .toFixed(2)

//   return {  total }

export const sumItems = (storage) => {
  if (storage.length !== 0) {
    const addOnsPrice = AddOnPriceCalculate(storage[0].GeneralInformationAddOns, 1)
    const mainMenuPrice = MainMenuPriceCalculate(storage[0].GeneralInformationMainMenu, 0)
    const total = addOnsPrice + mainMenuPrice

    return { total }
  } else return { total: 0 }
}

export const CartReducer = (state, action) => {
  //action type ve payload var
  //type case e giren yer
  //payload ise data
  switch (action.type) {
    case 'ADD_MULTIPLE_ITEM':
      let beforeMenuLS = JSON.parse(localStorage.getItem('art_id_03M'))
      let beforeAddOnsLS = JSON.parse(localStorage.getItem('art_id_04A'))
      let beforeOrderLS = JSON.parse(localStorage.getItem('art_id_02O'))
      let nowMenu = action.payload[0].GeneralInformationMainMenu
      let tempMenuArr = []
      if (beforeMenuLS.length > 0) {
        beforeMenuLS.map((x) => {
          tempMenuArr.push(x)
        })
      } else {
        tempMenuArr.push(beforeMenuLS)
      }
      tempMenuArr.push(nowMenu)

      let nowAddOns = action.payload[0].GeneralInformationAddOns

      let tempAddOnsArr = []
      if (beforeAddOnsLS.length > 0) {
        beforeAddOnsLS.map((x) => {
          tempAddOnsArr.push(x)
        })
      }

      if (nowAddOns.length !== 0) {
        nowAddOns.map((item) => tempAddOnsArr.push(item))
      }
      const newOrder = action.payload[0].GeneralInformationOrder
      const tempOrder = {
        OrderOwner: newOrder.OrderOwner,
        OrderSubTotal: parseFloat((newOrder.OrderSubTotal + beforeOrderLS.OrderSubTotal).toFixed(2)),
        OrderTotal: parseFloat((newOrder.OrderTotal + beforeOrderLS.OrderTotal).toFixed(2)),
        OrderTax: parseFloat((newOrder.OrderTax + beforeOrderLS.OrderTax).toFixed(2)),
      }

      // tempAddOnsArr.push(nowAddOns)
      localStorage.setItem('art_id_03M', JSON.stringify(tempMenuArr))
      localStorage.setItem('art_id_04A', JSON.stringify(tempAddOnsArr))

      localStorage.setItem('art_id_01R', JSON.stringify(action.payload[0].GeneralInformationRestaurant))
      localStorage.setItem('art_id_02O', JSON.stringify(tempOrder))

      state.cartItems[0].GeneralInformationOrder = {
        OrderOwner: action.payload[0].GeneralInformationOrder.OrderOwner,
        OrderSubTotal: parseFloat(
          (
            action.payload[0].GeneralInformationOrder.OrderSubTotal +
            state.cartItems[0].GeneralInformationOrder.OrderSubTotal
          ).toFixed(2)
        ),
        OrderTax: parseFloat(
          (
            action.payload[0].GeneralInformationOrder.OrderTax + state.cartItems[0].GeneralInformationOrder.OrderTax
          ).toFixed(2)
        ),
        OrderTotal: parseFloat(
          (
            action.payload[0].GeneralInformationOrder.OrderTotal + state.cartItems[0].GeneralInformationOrder.OrderTotal
          ).toFixed(2)
        ),
      }

      state.cartItems[0].GeneralInformationAddOns = state.cartItems[0].GeneralInformationAddOns.concat(
        action.payload[0].GeneralInformationAddOns
      )

      state.cartItems[0].GeneralInformationMainMenu = state.cartItems[0].GeneralInformationMainMenu.concat(
        action.payload[0].GeneralInformationMainMenu
      )
      return {
        ...state,
        ...sumItems(state.cartItems),
      }
    case 'ADD_ITEM':
      if (action.payload[0].GeneralInformationRestaurant.RestaurantOwnerName === 'villageburger') {
        const orderInfo = action.payload[0].GeneralInformationOrder
        const tempOrderForAddItem = {
          OrderOwner: orderInfo.OrderOwner,
          OrderSubTotal: parseFloat(orderInfo.OrderSubTotal.toFixed(2)),
          OrderTotal: parseFloat(orderInfo.OrderTotal.toFixed(2)),
          OrderTax: parseFloat(orderInfo.OrderTax.toFixed(2)),
        }
        action.payload[0].GeneralInformationOrder = tempOrderForAddItem
        localStorage.setItem('art_id_01R', JSON.stringify(action.payload[0].GeneralInformationRestaurant))
        localStorage.setItem('art_id_02O', JSON.stringify(action.payload[0].GeneralInformationOrder))

        localStorage.setItem('art_id_03M', JSON.stringify(action.payload[0].GeneralInformationMainMenu))

        localStorage.setItem('art_id_04A', JSON.stringify(action.payload[0].GeneralInformationAddOns))
        state.cartItems.push({
          ...action.payload[0],
        })
        return {
          ...state,
          ...sumItems(state.cartItems),
        }
      } else {
        const orderInfo = action.payload[0].GeneralInformationOrder
        const tempOrderForAddItem = {
          OrderOwner: orderInfo.OrderOwner,
          OrderSubTotal: parseFloat(orderInfo.OrderSubTotal),
          OrderTotal: parseFloat(orderInfo.OrderTotal),
          OrderTax: parseFloat(orderInfo.OrderTax),
        }
        action.payload[0].GeneralInformationOrder = tempOrderForAddItem
        localStorage.setItem('art_id_01R', JSON.stringify(action.payload[0].GeneralInformationRestaurant))
        localStorage.setItem('art_id_02O', JSON.stringify(action.payload[0].GeneralInformationOrder))

        localStorage.setItem('art_id_03M', JSON.stringify(action.payload[0].GeneralInformationMainMenu))

        localStorage.setItem('art_id_04A', JSON.stringify(action.payload[0].GeneralInformationAddOns))
        state.cartItems.push({
          ...action.payload[0],
        })
        return {
          ...state,
          ...sumItems(state.cartItems),
        }
      }

    case 'REMOVE_ITEM':
      let cartItems = state.cartItems[0]

      let localStorageData = action.payload

      if (cartItems.GeneralInformationMainMenu.length > 1) {
        let tempGeneralInformationAddOns = cartItems.GeneralInformationAddOns.filter(
          (addOn) => addOn.AddOnsTempID !== localStorageData.GeneralInformationMainMenu.MainMenuTempID
        )

        let tempGeneralInformationMainMenu = cartItems.GeneralInformationMainMenu.filter(
          (item) => item.MainMenuTempID !== localStorageData.GeneralInformationMainMenu.MainMenuTempID
        )
        let orderSubTotal = parseFloat(
          (
            AddOnPriceCalculate(tempGeneralInformationAddOns, 1) +
            MainMenuPriceCalculate(tempGeneralInformationMainMenu, 1)
          ).toFixed(2)
        )
        let orderTax = parseFloat(
          (orderSubTotal * localStorageData.GeneralInformationRestaurant.RestaurantTax).toFixed(2)
        )
        let orderTotal = parseFloat((orderSubTotal + orderTax).toFixed(2))
        let tempGeneralInformationOrder = {
          OrderOwner: localStorageData.GeneralInformationOrder.OrderOwner,
          OrderSubTotal: orderSubTotal,
          OrderTax: orderTax,
          OrderTotal: orderTotal,
        }

        cartItems.GeneralInformationAddOns = tempGeneralInformationAddOns
        cartItems.GeneralInformationMainMenu = tempGeneralInformationMainMenu
        cartItems.GeneralInformationOrder = tempGeneralInformationOrder
        localStorage.setItem('art_id_03M', JSON.stringify(cartItems.GeneralInformationMainMenu))
        localStorage.setItem('art_id_04A', JSON.stringify(cartItems.GeneralInformationAddOns))

        localStorage.setItem('art_id_02O', JSON.stringify(cartItems.GeneralInformationOrder))

        return {
          ...state,

          cartItems: [cartItems],
        }
      } else {
        return (
          localStorage.removeItem('art_id_01R'),
          localStorage.removeItem('art_id_02O'),
          localStorage.removeItem('art_id_03M'),
          localStorage.removeItem('art_id_04A'),
          sumItems([]),
          {
            cartItems: [],
          }
        )
      }

    case 'CHECKOUT':
      return {
        cartItems: [],
        checkout: true,
      }
    case 'CLEAR':
      return (
        localStorage.removeItem('art_id_01R'),
        localStorage.removeItem('art_id_02O'),
        localStorage.removeItem('art_id_03M'),
        localStorage.removeItem('art_id_04A'),
        sumItems([]),
        {
          cartItems: [],
        }
      )

    default:
      return state
  }
}

export default CartReducer

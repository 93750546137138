import React, { useContext, useState, useEffect } from 'react'
import { API } from 'aws-amplify'
import { getClickedProduct } from 'graphql/custom-queries'
import { Container, Row, Col, Button } from 'reactstrap'
import CardModal from './modal'
import { CartContext } from 'contexts/CartContext/CartContext'
import moment from 'moment'
//Scss Imports
import './index.scss'
export default function Card({ product, alertToggle, menuAlert, singleRestaurant }) {
  const width = window.screen.width
  const [modal, setModal] = useState(false)
  const [currentProduct, setCurrentProduct] = useState(null)
  const toggle = () => setModal(!modal)

  const { addProduct, cartItems } = useContext(CartContext)

  const controlMenuDate = () => {
    const currentDate = moment().format('YYYY-MM-DD')

    let singleMenuDate = ''
    let rangeMenuDate = ''

    if (product.dayOfWeek !== '') {
      singleMenuDate = product.dayOfWeek
    } else if (product.endDate !== '') {
      rangeMenuDate = product.endDate
    }

    if (singleMenuDate !== '') {
      if (moment(currentDate).isAfter(moment(singleMenuDate))) {
        return true
      } else {
        return false
      }
    } else {
      if (moment(currentDate).isAfter(moment(rangeMenuDate))) {
        return true
      } else {
        return false
      }
    }
  }
  async function getProduct() {
    let clickedProduct = {}
    try {
      const res = await API.graphql({
        query: getClickedProduct,
        variables: { id: product.id },
        authMode: 'AWS_IAM',
      })

      clickedProduct = res.data.getProduct
      toggle()
      setCurrentProduct(clickedProduct)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Col className='menu-card-col py-4' xl='6' lg='6' md='8' sm='12' xs='12'>
      {product.isActive ? (
        product.dayOfWeek ? (
          <span className='dayofweek'>{moment(product.dayOfWeek).format('dddd MMMM Do, YYYY')}</span>
        ) : (
          <span className='start-with-end-date'>Dummy textx</span>
        )
      ) : (
        <span className='start-with-end-date'>Dummy text</span>
      )}
      <Container
        className='menu-card-cont card p-0'
        onClick={() => {
          if (!controlMenuDate() && product.isActive && cartItems.length < 1) {
            getProduct()
          } else if (!controlMenuDate() && product.isActive === true) {
            alertToggle(!menuAlert)
          }
        }}>
        <Row className='card-body p-0 no-gutters'>
          <Col className='p-0' xl='4' lg='4' md='4' sm='4' xs='5'>
            <div id='product-pic-cont'>
              <img className='card-img' src={product.picture} alt='menu-img' id='menu-card-img'></img>
            </div>

            {/* {product.isActive ? null : <div class="sold-out-text">SOLD OUT</div>} */}
          </Col>
          <Col className='right-content position-relative' xl='8' lg='8' md='8' sm='8' xs='7'>
            <div className='d-flex justify-content-between align-items-center'>
              <h3>
                {width <= 425
                  ? product.name.length > 10
                    ? product.name.slice(0, 10) + '...'
                    : product.name
                  : product.name.length > 50
                  ? product.name.slice(0, 50) + '...'
                  : product.name}
              </h3>
              <span>${parseFloat(product.price).toFixed(2)}</span>
            </div>
            <p>{product.description}</p>

            {controlMenuDate() === true ? <span className='choose-btn start-with-end-date'>Dummy Text</span> : null}
            {product.isActive && !controlMenuDate() ? (
              <span className='choose-btn'>Add to cart</span>
            ) : (
              //<span className='choose-btn'>Sold Out</span>
              <span className='sold-out-text'>SOLD OUT</span>
            )}
          </Col>
        </Row>
      </Container>

      {currentProduct && (
        <CardModal
          modal={modal}
          toggle={toggle}
          product={currentProduct}
          addProduct={addProduct}
          single={singleRestaurant}
        />
      )}
    </Col>
  )
}

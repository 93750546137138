import React, { useEffect, useState } from 'react'
//Date
import { DatePicker, Space, TimePicker } from 'antd'
import moment from 'moment'
//Styling
import { Container, Row, Col, FormGroup, Input, Label } from 'reactstrap'
import './dateTimePicker.css'
const DateTimePicker = ({
  mainMenuDate,
  mainMenuEndDate,
  mainMenuEndTime,
  mainMenuIsFixMenu,
  mainMenuStartDate,
  mainMenuStartTime,
  restStartTime,
  restEndTime,
  restWorkingDays,
  setOrderPickupDate,
  setDatePickerValidation,
  setOrderPickupTime,
  orderPickupTime,
  localCartItems,
  setPickupOption,
  pickupOption,
}) => {
  const [totalHour, setTotalHour] = useState([])
  const [asapHour, setAsapHour] = useState(calculateAsapHour)

  useEffect(() => {
    //Eger ASAP enabled ise default secim o calculateASAP fonk cagir
    // Degilse calculateTotalHours cagir
    if (localCartItems.GeneralInformationRestaurant.RestaurantAsapPickupTimeEnabled && asapHour) {
      calculateAsapHour()
    } else if (localCartItems.GeneralInformationRestaurant.RestaurantLaterPickupTimeEnabled) {
      calculateTotalHours()
    }
  }, [])

  function calculateAsapHour() {
    const timeStart = moment(restStartTime, 'hh:mm A')
    const endStart = moment(restEndTime, "'hh:mm A'")

    const tempAsapTime = moment()
      .add(localCartItems.GeneralInformationRestaurant.RestaurantPrepTime, 'minutes')
      .format('hh:mm A')

    if (
      moment()
        .add(localCartItems.GeneralInformationRestaurant.RestaurantPrepTime, 'minutes')
        .isBetween(timeStart, endStart)
    ) {
      // setAsapHour(tempAsapTime) //For UI
      setOrderPickupTime(tempAsapTime) // For Order State
      return tempAsapTime
    } else {
      return null
    }
  }

  const calculateTotalHours = () => {
    //Fix menu ise direk restaurant calisma saatlerine gore al degil ise starttime end time a bak simdilik sadece fix menuler burda
    let timeStart = 0
    let endStart = 0
    if (mainMenuIsFixMenu) {
      timeStart = new Date('01/01/2005 ' + restStartTime).getHours()
      endStart = new Date('01/01/2005 ' + restEndTime).getHours()
    } else if ((mainMenuIsFixMenu === false && mainMenuDate) || (mainMenuIsFixMenu === false && mainMenuDate === '')) {
      timeStart = new Date('01/01/2005 ' + mainMenuStartTime).getHours()
      endStart = new Date('01/01/2005 ' + mainMenuEndTime).getHours()
    }

    let eksilen = 0
    let subs = Math.abs(endStart - timeStart) //14
    if (timeStart < endStart) {
      eksilen = subs // 10
    } else {
      eksilen = 24 - subs
    }
    let disArr = []
    let b = 0
    for (var i = 0; i <= eksilen; i++) {
      if (timeStart === 24) {
        disArr.push(b++)
      } else {
        disArr.push(timeStart++)
      }
    }
    //setDisabledTime(disArr)
    let tempPmArr = []
    let tempPmArrHalf = []
    let tempAmArr = []
    let tempAmArrHalf = []
    disArr.map((x) => {
      if (x < 12) {
        if (x === 0) {
          tempAmArr.push(`${x}:00 AM`)
          tempAmArrHalf.push(`${x}:30 AM`)
        } else {
          tempAmArr.push(`${x}:00 AM`)
          tempAmArrHalf.push(`${x}:30 AM`)
        }
      } else {
        if (x === 12) {
          tempPmArr.push(`${x}:00 PM`)
          tempPmArrHalf.push(`${x}:30 PM`)
        } else {
          tempPmArr.push(`${x - 12}:00 PM`)
          tempPmArrHalf.push(`${x - 12}:30 PM`)
        }
      }
    })

    let newRangeAmArr = []
    let newRangePmArr = []
    for (let i = 0; i < tempAmArr.length; i++) {
      let str = tempAmArr[i] + ' - ' + tempAmArrHalf[i]
      newRangeAmArr.push(str)
      let lastStr = ''
      let str1 = ''
      if (i + 1 >= tempAmArr.length) {
        lastStr = tempAmArrHalf[i] + ' - ' + tempPmArr[0]
        newRangeAmArr.push(lastStr)
      } else {
        str1 = tempAmArrHalf[i] + ' - ' + tempAmArr[i + 1]
        newRangeAmArr.push(str1)
      }
    }
    for (let k = 0; k < tempPmArr.length; k++) {
      let str = tempPmArr[k] + ' - ' + tempPmArrHalf[k]
      newRangePmArr.push(str)
      let str1 = tempPmArrHalf[k] + ' - ' + tempPmArr[k + 1]
      newRangePmArr.push(str1)
    }

    let x = newRangePmArr.pop()
    let y = newRangePmArr.pop()

    let newArr = [...newRangeAmArr, ...newRangePmArr]
    //Slice ve 1 in sebebi gecici olarak 11 - 11:30 arasini kapatmak. Ilerde dinamik hale getir
    setOrderPickupTime(newArr[1])
    setTotalHour(newArr.slice(1))
  }
  const disabledDateFixMenu = (current) => {
    // Can not select days before today and after 7 days
    // return current && current < moment().endOf('day').subtract(1, 'day')
    return current < moment().endOf('day').subtract(1, 'day') || current > moment().add(7, 'd')
  }
  const disabledDateFixMenuForLater = (current) => {
    // Can not select days before today and after 7 days
    // return current && current < moment().endOf('day').subtract(1, 'day')
    return current < moment().endOf('day').subtract(1, 'day') || current > moment()
  }
  // function disabledDateRangeMenu(current) {
  //   let start = mainMenuStartDate
  //   let end = mainMenuEndDate
  //   if (
  //     disabledDates.find(
  //       (date) => date === moment(current).format('YYYY-MM-DD')
  //     )
  //   ) {
  //     return true
  //   } else if (current < moment(start)) {
  //     return true
  //   } else if (current > moment(end).add(1, 'day')) {
  //     return true
  //   } else {
  //     return false
  //   }
  // }
  function handlePicker(e) {
    setOrderPickupDate(e)
  }
  const radioPickupOptionChange = (e) => {
    if (e.target.value === 'asap') {
      setOrderPickupDate(moment().format('YYYY-MM-DD'))
      setOrderPickupTime(asapHour)
    } else {
      // setOrder({ ...order, ORDERDELIVERY:"DELIVERY" })
      calculateTotalHours()
    }
    setPickupOption(e.target.value)
  }
  return (
    <Container>
      <Row>
        {mainMenuIsFixMenu ? (
          <>
            <Col xs='4'>
              <Container>
                <Row>
                  <FormGroup>
                    <Label>
                      <Input
                        className='method-input'
                        type='radio'
                        name='asap'
                        value='asap'
                        checked={pickupOption === 'asap' && asapHour}
                        onChange={radioPickupOptionChange}
                        disabled={
                          !localCartItems.GeneralInformationRestaurant.RestaurantAsapPickupTimeEnabled ||
                          asapHour === null
                        }
                      />
                      <span className='heading-text'> ASAP</span>
                      &nbsp;
                      {!localCartItems.GeneralInformationRestaurant.RestaurantAsapPickupTimeEnabled ||
                      asapHour === null ? (
                        <span style={{ color: 'darkgray' }}>(Unavailable)</span>
                      ) : null}
                    </Label>
                  </FormGroup>
                </Row>
                <Row>
                  <FormGroup>
                    <Label>
                      <Input
                        className={
                          !localCartItems.GeneralInformationRestaurant.RestaurantLaterPickupTimeEnabled
                            ? 'not-allowed'
                            : ''
                        }
                        name='later'
                        type='radio'
                        value='later'
                        checked={pickupOption === 'later' || !asapHour}
                        onChange={radioPickupOptionChange}
                        disabled={!localCartItems.GeneralInformationRestaurant.RestaurantLaterPickupTimeEnabled}
                      />
                      <span
                        className={
                          !localCartItems.GeneralInformationRestaurant.RestaurantLaterPickupTimeEnabled
                            ? 'not-allowed heading-text'
                            : 'heading-text'
                        }>
                        Schedule For Later
                      </span>
                      &nbsp;
                      {!localCartItems.GeneralInformationRestaurant.RestaurantLaterPickupTimeEnabled ? (
                        <span className='no-delivery'>(Unavailable)</span>
                      ) : null}
                    </Label>
                  </FormGroup>
                </Row>
              </Container>
            </Col>

            {pickupOption === 'asap' && asapHour ? (
              <>
                <Col xs='4'>
                  {/* <DatePicker
                  className='date-picker'
                  showToday={false}
                  format={'YYYY-MM-DD'}
                  disabledDate={disabledDateFixMenuForLater}
                  disabled
                  defaultValue={moment()}
                  // onChange={(e) => {
                  //   if (e !== null) {
                  //     setDatePickerValidation(false)
                  //     handlePicker(moment(e._d).format('YYYY-MM-DD'))
                  //   } else {
                  //     setDatePickerValidation(true)
                  //     handlePicker('null')
                  //   }
                  // }}
                /> */}
                  {moment().format('YYYY-MM-DD')}
                </Col>
                <Col className='d-flex' xs='4'>
                  {/* <select
                  className='pickup-time-select'
                  name='hours'
                  id='hours'
                  onChange={(e) => {
                    setOrderPickupTime(e.target.value)
                  }}
                >
                  {totalHour
                    ? totalHour.map((x) => {
                        return <option value={x}>{x}</option>
                      })
                    : null}
                </select> */}
                  <p>{asapHour}</p>

                  <p>
                    <span style={{ fontSize: '13px' }}>
                      ({localCartItems.GeneralInformationRestaurant.RestaurantPrepTime}
                      Minutes Later)
                    </span>
                  </p>
                </Col>{' '}
              </>
            ) : (
              <>
                <Col xs='4'>
                  <DatePicker
                    className='date-picker'
                    showToday={false}
                    format={'YYYY-MM-DD'}
                    disabledDate={disabledDateFixMenu}
                    defaultValue={moment()}
                    onChange={(e) => {
                      if (e !== null) {
                        setDatePickerValidation(false)
                        handlePicker(moment(e._d).format('YYYY-MM-DD'))
                      } else {
                        setDatePickerValidation(true)
                        handlePicker('null')
                      }
                    }}
                  />
                </Col>
                <Col className='d-flex' xs='4'>
                  <select
                    className='pickup-time-select'
                    name='hours'
                    id='hours'
                    onChange={(e) => {
                      setOrderPickupTime(e.target.value)
                    }}>
                    {totalHour
                      ? totalHour.map((x) => {
                          return <option value={x}>{x}</option>
                        })
                      : null}
                  </select>
                </Col>{' '}
              </>
            )}
          </>
        ) : null}
      </Row>
    </Container>
  )
}

export default DateTimePicker

{
  /* Bunlar simdi acil degil ilerde yapilir */
}
{
  /* {mainMenuIsFixMenu === false && mainMenuDate !== '' ? (
            <DatePicker
              className='date-picker'
              showToday={false}
              format={'YYYY-MM-DD'}
              defaultValue={moment(mainMenuDate, 'YYYY-MM-DD')}
              disabled
              onChange={(e) => {
                if (e !== null) {
                  setDatePickerValidation(false)
                  handlePicker(moment(e._d).format('YYYY-MM-DD'))
                } else {
                  setDatePickerValidation(true)
                }
              }}
            />
          ) : null} */
}
{
  /* {mainMenuIsFixMenu === false && mainMenuDate === '' ? (
            <DatePicker
              className='date-picker'
              showToday={false}
              format={'YYYY-MM-DD'}
              disabledDate={(current) => disabledDateRangeMenu(current)}
              onChange={(e) => {
                if (e !== null) {
                  setDatePickerValidation(false)
                  handlePicker(moment(e._d).format('YYYY-MM-DD'))
                } else {
                  setDatePickerValidation(true)
                }
              }}
            />
          ) : null} */
}
{
  /* Bunlar simdi acil degil ilerde yapilir */
}

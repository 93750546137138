import React, { useState, useContext } from 'react'
import StandardInput from 'Components/Inputs/StandardInput/StandardInput'
import MaskedPhoneInput from 'Components/Inputs/MaskedPhone/MaskedPhoneInput'
import MaskedInput from 'react-input-mask'
import { CartContext } from '../../../contexts/CartContext/CartContext'
import { Form, Alert } from 'reactstrap'
import { useHistory } from 'react-router-dom'
//Scss Imports
import './tabpersonalinfo.scss'
function TabPersonalInfo({
  persInfoControl,
  setPersInfoControl,
  handleSubmit,
  onSubmit,
  errors,
  register,
  visible,
  onDismiss,
  user,
  isUserDataBlank,
}) {
  const { cartItems } = useContext(CartContext)
  const history = useHistory()

  if (visible === true) {
    setTimeout(() => {
      onDismiss()
      setPersInfoControl(!persInfoControl)
    }, 3000)
  }

  return (
    <div className='pers-info-cont'>
      {user ? (
        persInfoControl === true ? (
          <div className='settings'>
            <div className='head'>
              <h1 className='title'>Personal Information</h1>
              <span
                className='edit-btn'
                onClick={() => setPersInfoControl(!persInfoControl)}
              >
                EDIT
              </span>
            </div>
            <div className='content'>
              <div className='settings-item'>
                <div className='inform-box'>
                  <span className='t1'>Name</span>
                  <span className='t2'>{user.attributes.given_name}</span>
                </div>
              </div>

              <div className='settings-item'>
                <div className='inform-box'>
                  <span className='t1'>Email</span>
                  <span className='t2'>{user.attributes.email}</span>
                </div>
              </div>
              <div className='settings-item'>
                <div className='inform-box'>
                  <span className='t1'>Phone Number</span>
                  <span className='t2'>{user.attributes.phone_number}</span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='settings'>
            <Alert color='success' isOpen={visible} toggle={onDismiss}>
              Your profile information has been updated successfully.
            </Alert>
            {isUserDataBlank === true ? (
              <Alert color='danger'>
                Please fill in your personal information.
              </Alert>
            ) : null}
            <div className='head'>
              <h1 className='title'>Personal Information</h1>
              <span
                className='edit-btn'
                onClick={() => setPersInfoControl(!persInfoControl)}
              >
                CANCEL
              </span>
            </div>
            <div className='form-content'>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className='form-item'>
                  <StandardInput
                    labelTitle='Name'
                    placeHolder=''
                    defVal={user.attributes.given_name}
                    maxLen={40}
                    inputType='text'
                    isRequired={true}
                    disable={
                      (user.username === 'Facebook' ||
                        user.username === 'Google') &&
                      user.attributes.given_name
                        ? true
                        : false
                    }
                    inputName='name'
                    refTemp={register({
                      required: 'Please fill out this field.',
                    })}
                    emptyControl={errors.name}
                  />
                </div>

                <div className='form-item'>
                  <StandardInput
                    labelTitle='Email'
                    placeHolder=''
                    defVal={user.attributes.email}
                    maxLen={255}
                    inputType='text'
                    isRequired={true}
                    disable={true}
                    inputName='email'
                    refTemp={register({
                      required: 'Please fill out this field.',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'Invalid email address!',
                      },
                    })}
                    emptyControl={errors.email}
                  />
                </div>
                <div className='form-item'>
                  <MaskedPhoneInput
                    labelTitle='Phone'
                    placeHolder=''
                    defVal={user.attributes.phone_number}
                    inputName='phone'
                    isRequired={true}
                    refTemp={register({
                      required: 'Please fill out this field.',
                    })}
                    emptyControl={errors.phone}
                    maskedInput={MaskedInput}
                    inputMask='+1 999 999 9999'
                  />
                </div>
                <div className='form-item'>
                  <button className='save-edit' type='submit' value='Submit'>
                    SAVE CHANGES
                  </button>
                </div>
              </Form>
            </div>
          </div>
        )
      ) : null}
    </div>
  )
}

export default TabPersonalInfo

export const listCompanys = /* GraphQL */ `
  query ListCompanys($filter: ModelCompanyFilterInput, $limit: Int, $nextToken: String) {
    listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        location {
          street
          state
          city
          zipCode
          addressName
          id
          isDefault
        }
        phoneNumber
        companyUrl
        facebookLink
        instagramLink
        twitterLink
        logo
        description
        urlStub
        companyType {
          label
          value
        }
        workingHours {
          openingTime
          closingTime
          openingDays {
            label
            value
          }
        }
        foodType {
          typeOne
          typeTwo
          typeThree
        }
        tax
        paymentId
        restaurantType
        RestaurantCardPaymentEnabled
        RestaurantPayatDoorEnabled
        RestaurantDeliveryEnabled
        RestaurantPickupEnabled
        RestaurantAsapPickupTimeEnabled
        RestaurantLaterPickupTimeEnabled
        RestaurantPrepTime
        restaurantOwner
        owner
      }
      nextToken
    }
  }
`

export const getCompanyCategories = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      categories {
        items {
          id
          categoryName
          categoryDescription
          categorySequence
          createdAt
          updatedAt
          owner
          CategoryProducts {
            items {
              id
              CategoryID
              ProductID
              createdAt
              updatedAt
              Product {
                id
                isFixMenu
                dayOfWeek
                startDate
                endDate
                name
                picture
                price
                stock {
                  endTime
                  startTime
                  dailyStock
                  uniqueDays {
                    id
                    date
                    stockCount
                    isControlStock
                  }
                }
                description
                isActive
                isArchive
                locationID
                createdAt
                updatedAt
                owner
              }
              owner
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`
export const getClickedProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      isFixMenu
      dayOfWeek
      startDate
      endDate
      name
      picture
      price
      stock {
        endTime
        startTime
        dailyStock
        uniqueDays {
          id
          date
          stockCount
          isControlStock
        }
      }
      description
      isActive
      isArchive
      locationID
      createdAt
      updatedAt
      owner
      ProductExtraItemGroups {
        items {
          id
          ProductID
          ExtraItemGroupID
          createdAt
          updatedAt
          ExtraItemGroup {
            id
            GroupName
            isOptional
            isIncrement
            opMaximumExtraItem
            reqMaximumExtraItem
            reqMinimumExtraItem
            isArchive
            createdAt
            updatedAt
            owner
            ExtraItemGroupExtraItems {
              items {
                id
                ExtraItemGroupID
                ExtraItemID
                createdAt
                updatedAt
                ExtraItem {
                  id
                  extraItemName
                  extraItemPrice
                  ispreselection
                  createdAt
                  updatedAt
                  owner
                }
                owner
              }
              nextToken
            }
          }

          owner
        }
        nextToken
      }
    }
  }
`

export const listMenuStockControls = /* GraphQL */ `
  query ListMenuStockControls($filter: ModelMenuStockControlFilterInput, $limit: Int, $nextToken: String) {
    listMenuStockControls(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        pickUpTime {
          startTime
          startClock
          startAmPm
          endTime
          endClock
          endAmPm
          capacity
        }
        custompickUpTimes {
          id
          time
          capacity
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`

export const getMenuStockControl = /* GraphQL */ `
  query GetMenuStockControl($id: ID!) {
    getMenuStockControl(id: $id) {
      id
      name
      pickUpTime {
        startTime
        startClock
        startAmPm
        endTime
        endClock
        endAmPm
        capacity
      }
      custompickUpTimes {
        id
        time
        capacity
      }
      createdAt
      updatedAt
      owner
    }
  }
`
export const listOrders = /* GraphQL */ `
  query ListOrders($filter: ModelOrderFilterInput, $limit: Int, $nextToken: String) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        menu {
          mainMenuId
          mainMenuCount
          mainMenuInstruction
          mainMenuName
          mainMenuPrice
          mainMenuTempID
        }
        menuItem {
          addOnsCount
          addOnsName
          addOnsPrice
          addOnsTempID
        }
        subTotalPrice
        taxPrice
        totalPrice
        mainAddOnsCount
        mainMenusCount
        customerName
        customerPhone
        customerEmail
        pickUpDate
        pickUpTime
        orderStatus
        paymentIntentId
        paymentStatus
        owner
        user
        locationID
        createdAt
        updatedAt
        company {
          id
          name
          email
          phoneNumber
          createdAt
          updatedAt
          owner
        }
      }
      nextToken
    }
  }
`

export const listCategorys = /* GraphQL */ `
  query ListCategorys($filter: ModelCategoryFilterInput, $limit: Int, $nextToken: String) {
    listCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        categoryName
        categoryDescription
        categorySequence
        createdAt
        updatedAt
        owner
        product {
          items {
            id
            isFixMenu
            dayOfWeek
            startDate
            endDate
            name
            picture
            price
            stock {
              endTime
              startTime
              dailyStock
              uniqueDays {
                id
                date
                stockCount
                isControlStock
              }
            }
            description
            isActive
            isArchive
            locationID
            createdAt
            updatedAt
            extraItemGroup {
              items {
                id
                GroupName
                isOptional
                isIncrement
                opMaximumExtraItem
                reqMaximumExtraItem
                reqMinimumExtraItem
                isArchive
                createdAt
                updatedAt
                owner
                extraItems {
                  items {
                    id
                    extraItemName
                    extraItemPrice
                    ispreselection
                    createdAt
                    updatedAt
                    owner
                  }
                  nextToken
                }
              }
              nextToken
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`

export const listCategory1s = /* GraphQL */ `
  query ListCategory1s($filter: ModelCategory1FilterInput, $limit: Int, $nextToken: String) {
    listCategory1s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        categoryName
        categoryDescription
        categorySequence
        createdAt
        updatedAt
        owner
        Category1Product1s {
          items {
            id
            category1ID
            product1ID
            createdAt
            updatedAt
            product1 {
              id
              isFixMenu
              dayOfWeek
              startDate
              endDate
              name
              picture
              price
              stock {
                endTime
                startTime
                dailyStock
              }
              description
              isActive
              isArchive
              locationID
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
      }
      nextToken
    }
  }
`
export const getProduct1 = /* GraphQL */ `
  query GetProduct1($id: ID!) {
    getProduct1(id: $id) {
      id
      isFixMenu
      dayOfWeek
      startDate
      endDate
      name
      picture
      price
      stock {
        endTime
        startTime
        dailyStock
      }
      description
      isActive
      isArchive
      locationID
      createdAt
      updatedAt
      owner
      Product1ExtraItemGroup1s {
        items {
          id
          product1ID
          extraitemgroup1ID
          createdAt
          updatedAt
          extraitemgroup1 {
            id
            GroupName
            isOptional
            isIncrement
            opMaximumExtraItem
            reqMaximumExtraItem
            reqMinimumExtraItem
            isArchive
            createdAt
            updatedAt
            owner
            ExtraItemGroup1ExtraItem1s {
              items {
                id
                extraitemgroup1ID
                extraitem1ID
                createdAt
                updatedAt
                extraitem1 {
                  id
                  extraItemName
                  extraItemPrice
                  ispreselection
                  createdAt
                  updatedAt
                  owner
                }

                owner
              }
              nextToken
            }
          }
          owner
        }
        nextToken
      }
    }
  }
`
export const getCompanyAddresses = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      location {
        street
        state
        city
        zipCode
        id
        addressName
      }
    }
  }
`

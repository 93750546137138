import React from 'react'
import './sociallinks.scss'

function SocialLinks({ restaurant }) {
  return (
    <ul className='icons-list'>
      <li>
        {restaurant.companyUrl === '' ? (
          <i className='fas fa-globe-americas fa-lg disactive-icon'></i>
        ) : (
          <a href={'https://' + restaurant.companyUrl} rel='noopener noreferrer' target='_blank'>
            <i className='fas fa-globe-americas fa-lg' id='active-website'></i>
          </a>
        )}
      </li>
      <li>
        {restaurant.instagramLink === '' ? (
          <i className='fab fa-instagram fa-lg disactive-icon'></i>
        ) : (
          <a href={'https://' + restaurant.instagramLink} target='_blank' rel='noopener noreferrer'>
            <i className='fab fa-instagram fa-lg' id='active-insta'></i>
          </a>
        )}
      </li>
      <li>
        {restaurant.twitterLink === '' ? (
          <i className='fab fa-twitter fa-lg disactive-icon'></i>
        ) : (
          <a href={'https://' + restaurant.twitterLink} target='_blank' rel='noopener noreferrer'>
            <i className='fab fa-twitter fa-lg' id='active-twitter'></i>
          </a>
        )}
      </li>
      <li>
        {restaurant.facebookLink === '' ? (
          <i className='fab fa-facebook-f fa-lg disactive-icon'></i>
        ) : (
          <a href={'https://' + restaurant.facebookLink} target='_blank' rel='noopener noreferrer'>
            <i className='fab fa-facebook-f fa-lg' id='active-facebook'></i>
          </a>
        )}
      </li>
      <li>
        <a href={'tel:+' + restaurant.phoneNumber}>
          {' '}
          <i className='fas fa-phone-alt fa-lg' id='active-phone'></i>
        </a>
      </li>
    </ul>
  )
}

export default SocialLinks

import React from 'react'
import ReactDOM from 'react-dom'
//Theme Imports
import App from './App'
//Context Imports
import { CompanyContextProvider } from './contexts/CompanyContext'
import { SearchProvider } from './contexts/SearchContext/SearchContext'
import { CartContextProvider } from 'contexts/CartContext/CartContext'
import { UserContextProvider } from 'contexts/UserContext/UserContext'
//CSS imports
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.scss'
//Amplify imports

import Amplify from 'aws-amplify'
import awsconfig from './aws-exports'
import reportWebVitals from './reportWebVitals'
const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.

    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.

    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
)

const signInURI = awsconfig.oauth.redirectSignIn.split(',')
const signOutURI = awsconfig.oauth.redirectSignOut.split(',')

if (isLocalhost) {
  awsconfig.oauth.redirectSignIn = signInURI[1]
  awsconfig.oauth.redirectSignOut = signOutURI[1]
} else if (window.location.hostname === 'dev.artichokee.com') {
  awsconfig.oauth.redirectSignIn = signInURI[0]
  awsconfig.oauth.redirectSignOut = signOutURI[0]
} else if (window.location.hostname === 'www.renzosfire.com') {
  awsconfig.oauth.redirectSignIn = signInURI[3]
  awsconfig.oauth.redirectSignOut = signOutURI[3]
} else {
  awsconfig.oauth.redirectSignIn = signInURI[2]
  awsconfig.oauth.redirectSignOut = signOutURI[2]
}

Amplify.configure(awsconfig)

ReactDOM.render(
  <UserContextProvider>
    <CompanyContextProvider>
      <SearchProvider>
        <CartContextProvider>
          <App />
        </CartContextProvider>
      </SearchProvider>
    </CompanyContextProvider>
  </UserContextProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import React from 'react'
import { useHistory } from 'react-router-dom'
//Scss Imports
import './tabaccount.scss'
function TabAccount({ orders, user }) {
  const history = useHistory()

  return (
    <div className='account-cont'>
      <div className='welcome'>
        {user !== null ? (
          user.attributes.picture !== undefined ? (
            <img className='user-pic' src={user.attributes.picture} width='75' height='75' alt='User profile'></img>
          ) : null
        ) : null}
        {user !== null ? (
          <h1 className='title'>
            Welcome,&nbsp;
            <span className='given-name'>
              {user.attributes.given_name !== undefined ? user.attributes.given_name : user.username}
            </span>
          </h1>
        ) : null}

        <p className='text'>
          From this page, you can edit your personal information, view your past orders and adjust your account
          settings.
        </p>
      </div>
      {orders.length === 0 ? (
        <div className='non-order'>
          <span className='title'>You have no orders yet.</span>
          <div className='others'>
            <span
              className='continue'
              onClick={() => {
                history.push('/renzos-fire')
              }}>
              START SHOPPING
            </span>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default TabAccount

export const createOrder = /* GraphQL */ `
  mutation CreateOrder($input: CreateOrderInput!, $condition: ModelOrderConditionInput) {
    createOrder(input: $input, condition: $condition) {
      id
      menu {
        mainMenuId
        mainMenuCount
        mainMenuInstruction
        mainMenuName
        mainMenuPrice
        mainMenuTempID
      }
      menuItem {
        addOnsCount
        addOnsName
        addOnsPrice
        addOnsTempID
      }
      subTotalPrice
      taxPrice
      totalPrice
      mainAddOnsCount
      mainMenusCount
      customerName
      customerPhone
      customerEmail
      pickUpDate
      pickUpTime
      orderStatus
      paymentIntentId
      paymentStatus
      owner
      user
      locationID
      createdAt
      updatedAt
      company {
        id
      }
    }
  }
`

export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder($input: UpdateOrderInput!, $condition: ModelOrderConditionInput) {
    updateOrder(input: $input, condition: $condition) {
      id
      orderStatus
    }
  }
`

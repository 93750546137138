import React, { useState, useEffect } from 'react'
// import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
// import { API, Auth, graphqlOperation } from 'aws-amplify'
// import CardSection from '../../Components/StripeElements/CardSection'
// import './StripeMiddleware.scss'
//Graphql API for Order Table
import { createOrder } from 'graphql/custom-mutations'

const StripeMiddleware = ({
  toggle,
  userEmail,
  userName,
  userPhone,
  order,
  sendMailCustomer,
  sendMailOwner,
  accountID,
  callButton,
}) => {
  // const [amount, setAmount] = useState(0)
  // const [currency, setCurrency] = useState('')
  // const [clientSecret, setClientSecret] = useState(null)
  // const [error, setError] = useState(null)
  // const [metadata, setMetadata] = useState(null)
  // const [succeeded, setSucceeded] = useState(false)
  // const [processing, setProcessing] = useState(false)
  // const stripe = useStripe()
  // const elements = useElements()

  // useEffect(() => {
  //   createPaymentIntent()
  //     .then((clientSecret) => {
  //       setClientSecret(clientSecret)
  //     })
  //     .catch((err) => {
  //       setError(err.message)
  //     })
  // }, [])

  // async function createPaymentIntent() {
  //   let apiName = 'paymentApi'
  //   let path = '/createPaymentIntent'
  //   const user = await Auth.currentAuthenticatedUser()
  //   const token = user.signInUserSession.idToken.jwtToken
  //   let myInit = {
  //     body: {
  //       accountID: accountID, // ORDERPAGE icinde single restauranttan alinmali
  //       // amount: parseInt(order.totalPrice) * 100,
  //       amount: 10 * 100,
  //     },
  //     headers: {
  //       Authorization: token,
  //     },
  //   }

  //   const response = await API.post(apiName, path, myInit)
  //   return response.clientSecret
  // }

  // const handleSubmit = async (event) => {
  //   event.preventDefault()
  //   setProcessing(true)

  //   if (!stripe || !elements) {
  //     return
  //   }
  //   const billingDetails = {
  //     name: userName,
  //     email: userEmail,
  //     phone: userPhone,
  //   }
  //   const result = await stripe.confirmCardPayment(clientSecret, {
  //     payment_method: {
  //       card: elements.getElement(CardElement),
  //       billing_details: billingDetails,
  //     },
  //   })

  //   if (result.error) {
  //     setError(`Payment failed: ${result.error.message}`)
  //     setProcessing(false)
  //   } else {
  //     // The payment has been processed!

  //     if (result.paymentIntent.status === 'succeeded') {
  //       // Show a success message to your customer
  //       // There's a risk of the customer closing the window before callback
  //       // execution. Set up a webhook or plugin to listen for the
  //       // payment_intent.succeeded event that handles any business critical
  //       // post-payment actions.

  //       try {
  //         const orderWithPaymentID = {
  //           ...order,
  //           paymentIntentId: result.paymentIntent.id,
  //           orderStatus: 'accept',
  //           paymentStatus: 'paid',
  //         }

  //         const res = await API.graphql(
  //           graphqlOperation(createOrder, { input: orderWithPaymentID })
  //         )
  //         sendMailCustomer(order)
  //         sendMailOwner(order)
  //         setError(null)
  //         setSucceeded(true)
  //         setProcessing(false)
  //         setMetadata(result.paymentIntent)

  //         toggle()
  //       } catch (err) {
  //         setError(`Order Create failed: ${err}`)
  //       }
  //     }
  //   }
  // }

  return (
    // <div className='container-fluid p-0'>
    //   <form onSubmit={handleSubmit}>
    //     <div className='py-3 text-center'>
    //       <CardSection />
    //       {error && (
    //         <div className='message sr-field-error float-left'>{error}</div>
    //       )}
    //       {/* <button
    //         className='order-btn'
    //         disabled={processing || !clientSecret || !stripe}
    //       >
    //         {processing ? 'Processing…' : 'Place Order'}
    //       </button> */}

    //       {/* {callButton(true)} */}
    //     </div>
    //   </form>
    // </div>
    <div>sfsdf</div>
  )
}

export default StripeMiddleware

import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from 'reactstrap'
import StandardInput from 'Components/Inputs/StandardInput/StandardInput'
function ConfirmSignup({ register, errors }) {
  const [passwordEye, setPasswordEye] = useState(true)
  const togglePasswordEye = () => setPasswordEye(!passwordEye)
  return (
    <>
      <StandardInput
        labelTitle='Verification Code'
        placeHolder=''
        maxLen={6}
        inputType='text'
        isRequired={true}
        inputName='authCodeForgotPassword'
        refTemp={register({
          required: 'Verification code is required!',
        })}
        emptyControl={errors.authCodeForgotPassword}
      />
      <StandardInput
        labelTitle='New Password'
        placeHolder=''
        maxLen={120}
        inputType={passwordEye === true ? 'password' : 'text'}
        isRequired={true}
        inputName='passwordForgotPassword'
        refTemp={register({
          required: 'New Password is required!',
        })}
        emptyControl={errors.passwordForgotPassword}
        isPassword={true}
        togglePasswordEye={togglePasswordEye}
      />
      <Button className='signin-btn' type='submit'>
        Confirm
      </Button>
    </>
  )
}

export default ConfirmSignup

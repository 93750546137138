import React, { useEffect, useState, useContext } from 'react'
//Context Imports
import { CompanyContext } from 'contexts/CompanyContext'
//Page Imports
import TypeOneSR from 'Pages/SingleRestaurantPages/TypeOnePages/TypeOneSR'
import TypeTwoSR from 'Pages/SingleRestaurantPages/TypeTwoPages/TypeTwoSR'
//Scss Imports
import './singlerestpage.scss'

const SingleRestaurantPage = (props) => {
  const { restaurants } = useContext(CompanyContext)

  const getAllCompanys = () => {
    const Sr = restaurants.filter(
      // (restaurant) => restaurant.urlStub === props.match.params.restaurantName
      (restaurant) => restaurant.urlStub === props.location.pathname.substr(1)
    )
    return Sr[0]
  }

  const [singleRestaurant, setSingleRestaurant] = useState(() => getAllCompanys())

  useEffect(() => {
    getAllCompanys()
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='single-rest-cont'>
      {singleRestaurant.restaurantType === 'type1' ? (
        <TypeOneSR singleRestaurant={singleRestaurant} />
      ) : (
        <TypeTwoSR singleRestaurant={singleRestaurant} />
      )}
    </div>
  )
}

export default SingleRestaurantPage

import React, { useState, useEffect, useContext } from 'react'
import { API } from 'aws-amplify'
import { getCompanyCategories, getClickedProduct } from 'graphql/custom-queries'
import { CartContext } from 'contexts/CartContext/CartContext'
import { Container, Row, Col, Spinner, Modal, ModalBody, ModalHeader } from 'reactstrap'
import ScrollMenu from 'react-horizontal-scrolling-menu'
import ProductModal from './ProductModal/ProductModal'
import CategoryMenuCard from 'Components/CategoryMenuCard/CategoryMenuCard'
import SocialLinks from 'Components/SocialLinks/SocialLinks'
import moment from 'moment'
import './typetwosr.scss'
// Helper functions
import { getDefaultLocation } from 'helperFunctions/GetDefaultLocation/GetDefaultLocation'

function AddedSuccessfullModal({ toggle, modal, setVisibleSuccessfulModal }) {
  return (
    <Modal className='added-to-cart-successful-modal' size='lg' isOpen={modal} toggle={toggle}>
      {/* toggle kullanılcak modalı kapatmak için */}

      <div className='pt-3 pr-3'>
        <div className='times-cont' onClick={() => setVisibleSuccessfulModal(false)}>
          <i className='fas fa-times'></i>
        </div>
      </div>

      <ModalBody className='d-flex justify-content-center align-items-center flex-column'>
        <div className='check-cont'>
          <i className='fas fa-check fa-3x'></i>
        </div>
        <h4 className='message'>Product has been added to your cart.</h4>
      </ModalBody>
    </Modal>
  )
}

const MenuItem = ({ text, isSelected, categoryId, index, list }) => {
  // We get index and category list because when click a menu item we want to send scroll to specific component
  // But there are various use cases about scroll position so we positioned scroll with element offsetHeights
  function goToElement(categoryId, index) {
    var horizontalMenu = document.getElementsByClassName('horizontal-menu')
    var restDetailSection = document.getElementById('rest-detail-section')
    let totalCategoryHeight = 0
    for (let i = 0; i < index; i++) {
      totalCategoryHeight += document.getElementById(list[i].id).offsetHeight
    }
    let totalHeight = restDetailSection.offsetHeight + totalCategoryHeight + horizontalMenu[0].offsetHeight - 60
    window.scrollTo({
      top: totalHeight,
      behavior: 'smooth',
    })
  }
  return (
    <div
      className={`menu-item ${text === isSelected.selected ? 'active-border' : ''}`}
      onClick={() => goToElement(categoryId, index)}>
      <h2 className={`${text === isSelected.selected ? 'active' : ''}`}>{text}</h2>
      {text === isSelected.selected && <div className='sub-border'></div>}
    </div>
  )
}
export const Menu = (list, isSelected) =>
  list.map((el, index) => {
    const { name, id } = el

    return <MenuItem text={name} key={name} isSelected={isSelected} categoryId={id} index={index} list={list} />
  })

const Arrow = ({ text, className }) => {
  return (
    <div className={className}>
      {text === 'right' ? <i className='fas fa-chevron-right'></i> : <i className='fas fa-chevron-left'></i>}
    </div>
  )
}

const ArrowLeft = Arrow({ text: 'left', className: 'arrow-common arrow-left' })
const ArrowRight = Arrow({
  text: 'right',
  className: 'arrow-common arrow-right',
})

const selected = 'Populer Items'

const TypeTwoSR = ({ singleRestaurant }) => {
  const { cartItems } = useContext(CartContext)
  const [categories, setCategories] = useState(null)
  const [isSelected, setIsSelected] = useState({ selected })
  const [categoryTitles, setCategoryTitles] = useState([])
  const [menuItems, setmenuItems] = useState(Menu(categoryTitles, isSelected))
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)
  const [singleProduct, setSingleProduct] = useState(null)
  const [visibleSuccessfulModal, setVisibleSuccessfulModal] = useState(false)
  const [menuAlert, setMenuAlert] = useState(false)
  const [groupMinMaxQty, setGroupMinMaxQty] = useState([])
  const [singleRequiredList, setSingleRequiredList] = useState([])
  const toggleSuccessfulModal = () => setVisibleSuccessfulModal(!visibleSuccessfulModal)

  function getCategoryTitles(categories) {
    const tempCategoryTitle = []
    categories.map(({ categoryName, id }) => {
      tempCategoryTitle.push({
        name: categoryName,
        id: id,
      })
    })
    setCategoryTitles(tempCategoryTitle)
    setmenuItems(Menu(tempCategoryTitle, isSelected))
  }
  const defaultLocation = getDefaultLocation(singleRestaurant.location)

  async function getCategories(restID) {
    try {
      const res = await API.graphql({
        variables: { id: restID },
        query: getCompanyCategories,
        authMode: 'AWS_IAM',
      })

      res.data.getCompany.categories.items.sort((a, b) =>
        a.categorySequence > b.categorySequence ? 1 : b.categorySequence > a.categorySequence ? -1 : 0
      )

      setCategories(res.data.getCompany.categories.items)
      getCategoryTitles(res.data.getCompany.categories.items)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getCategories(singleRestaurant.id)
  }, [])

  function isWorkTime() {
    // Return true if locale time is between Type Two Restaurant working hours
    const timeStart = moment(singleRestaurant.workingHours.openingTime, 'hh:mm A')
    const timeEnd = moment(singleRestaurant.workingHours.closingTime, 'hh:mm A')

    if (moment().isBetween(timeStart, timeEnd)) {
      return true
    } else {
      return false
    }
  }

  const onSelect = (key) => {
    setmenuItems(Menu(categoryTitles, { selected: key }))
    setIsSelected({ selected: key })
  }

  async function openProductModal(product) {
    let clickedProduct = {}
    try {
      const res = await API.graphql({
        query: getClickedProduct,
        variables: { id: product.id },
        authMode: 'AWS_IAM',
      })

      clickedProduct = res.data.getProduct
      prepareProductModal(clickedProduct)
    } catch (err) {
      console.log(err)
    }
  }

  function prepareProductModal(product) {
    if (cartItems.length === 0 || singleRestaurant.name === cartItems[0].GeneralInformationRestaurant.RestaurantName) {
      let tempArr = []

      product.ProductExtraItemGroups.items.map((group, i) => {
        if (group.ExtraItemGroup.isOptional === true) {
          tempArr.push({
            GroupName: group.ExtraItemGroup.GroupName,
            GroupMinQty: 0,
            GroupMaxQty: group.ExtraItemGroup.opMaximumExtraItem,
          })
        } else {
          tempArr.push({
            GroupName: group.ExtraItemGroup.GroupName,
            GroupMinQty: group.ExtraItemGroup.reqMinimumExtraItem,
            GroupMaxQty: group.ExtraItemGroup.reqMaximumExtraItem,
          })
        }
      })

      setGroupMinMaxQty(tempArr)

      toggle()

      let pro = product.ProductExtraItemGroups.items.map((group) => {
        return { ...group, ExtraItemGroup: { ...group.ExtraItemGroup, control: true } }
      })

      product['ProductExtraItemGroups']['items'] = sortGroups(pro)

      let requiredList = []
      product.ProductExtraItemGroups.items.map((group) => {
        if (group.ExtraItemGroup.isOptional === false) {
          requiredList.push(group)
        }
      })
      setSingleRequiredList(requiredList)

      setSingleProduct(product)
    } else {
      setMenuAlert(!menuAlert)
    }
  }
  const closeSuccessModalTimeOut = () => {
    setVisibleSuccessfulModal(true)
    setTimeout(() => {
      setVisibleSuccessfulModal(false)
    }, 1500)
  }

  function sortGroups(groups) {
    // First of all, we sort out the required groups so that the required groups come in. The reason for our sorting is that we can scroll up when the user does not select the required group.
    let requiredGroups = []
    let optionalGroups = []
    groups.forEach((group) => {
      if (group.ExtraItemGroup.isOptional) {
        optionalGroups.push(group)
      }
    })
    groups.forEach((group) => {
      if (!group.ExtraItemGroup.isOptional) {
        requiredGroups.push(group)
      }
    })
    return requiredGroups.concat(optionalGroups)
  }
  return categories === null ? (
    <div className='text-center pt-5'>
      <Spinner color='warning' />
    </div>
  ) : (
    <Container className='tempvillage-cont'>
      <Row className='restaurant-detail-row' id='rest-detail-section'>
        <Container fluid>
          <Row className='jumbotron-logo'>
            <Col>
              <img
                className='header-jumbotron'
                src='https://img.cdn4dd.com/cdn-cgi/image/fit=contain,width=1920,format=auto,quality=50/https://cdn.doordash.com/media/store%2Fheader%2F6d5c6ec4-50c7-4d6c-a9cb-f4b1e93eca8d.jpg'
                alt='jumbotron'
              />
              <div className='logo-container'>
                <img className='logo' src={singleRestaurant.logo} alt='logo' />
              </div>
            </Col>
          </Row>
          <Row className='restaurant-details'>
            <Col xl='8' lg='8' md='8' sm='12' xs='12'>
              {' '}
              <h1 className='restaurant-name'>{singleRestaurant.name}</h1>
              <ul className='m-0 pl-4'>
                <li className='description'>
                  {singleRestaurant.foodType.typeOne +
                    ', ' +
                    singleRestaurant.foodType.typeTwo +
                    ', ' +
                    singleRestaurant.foodType.typeThree}
                </li>
              </ul>
            </Col>
            <Col xl='4' lg='4' md='4' sm='12' xs='12'>
              <div className=' social-icons-cont'>
                <SocialLinks restaurant={singleRestaurant} />
              </div>
            </Col>
          </Row>
          <Row className='info'>
            <Col xl='9' lg='9' md='9' sm='9' xs='7'>
              {isWorkTime() ? (
                <div className='calufe-open'>
                  <span color='SystemWhite' display='block'>
                    Open
                  </span>
                  <div>
                    <span display='block'>
                      <span color='SystemWhite' display='block'>
                        closes at {singleRestaurant.workingHours.closingTime}
                      </span>
                    </span>
                  </div>
                </div>
              ) : (
                <div className='calufe-closed'>
                  <span color='SystemWhite' display='block'>
                    Closed
                  </span>
                  <div>
                    <span display='block'>
                      <span color='SystemWhite' display='block'>
                        opens at {singleRestaurant.workingHours.openingTime}
                      </span>
                    </span>
                  </div>
                </div>
              )}
            </Col>
            <Col className='d-flex justify-content-end align-items-center' xl='3' lg='3' md='3' sm='3' xs='5'>
              <div className='text-right pickup-btn'>
                <div className='pickup-span'>
                  {`${singleRestaurant.RestaurantPickupEnabled ? 'Pickup' : ''}`}
                  <div className='triangle'></div>
                </div>
              </div>
            </Col>
            <Col xl='12' lg='12' md='12' sm='12' xs='12'>
              <div className='pickup-address-cont'>
                <div className='sub-cont'>
                  <span className='pickup-address-desc'>
                    {`${singleRestaurant.RestaurantPickupEnabled ? 'This is a Pickup order' : ''}`}{' '}
                  </span>
                  <span className='pickup-address'>
                    {`You'll need to go to ${singleRestaurant.name} to pick up this order:
                    ${defaultLocation.street}`}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <hr />
          <Row className='full-menu-row'>
            <Col>
              <div>
                <span className='full-menu'>Full Menu</span>
              </div>
              <span className='rest-opening-time'>{`${singleRestaurant.workingHours.openingTime} - ${singleRestaurant.workingHours.closingTime}`}</span>
            </Col>
          </Row>
        </Container>
      </Row>

      <Row className='horizontal-menu-row'>
        <Col>
          <ScrollMenu
            data={menuItems}
            arrowLeft={ArrowLeft}
            arrowRight={ArrowRight}
            selected={isSelected}
            onSelect={onSelect}
          />
        </Col>
      </Row>
      <Row id='inclusive-section'>
        {/* We will return this section for every category and their menus. */}
        {categories.map((category, index) => {
          return (
            <Container key={index} id={category.id} className='menus-section'>
              <Row className='category-info-cont'>
                <Col className='category-header-cont'>
                  <h2>{category.categoryName}</h2>
                  <h3>{category.categoryDescription}</h3>
                </Col>
              </Row>

              <Row className='category-body-row'>
                {category.CategoryProducts.items.map((product) => {
                  return (
                    <Col key={product.id} className='py-2' xl='5' lg='5' md='6' sm='8' xs='12'>
                      <CategoryMenuCard product={product.Product} openProductModal={openProductModal} />
                    </Col>
                  )
                })}
              </Row>
            </Container>
          )
        })}
      </Row>

      <ProductModal
        modal={modal}
        toggle={toggle}
        singleProduct={singleProduct}
        setSingleProduct={setSingleProduct}
        groupMinMaxQty={groupMinMaxQty}
        singleRestaurant={singleRestaurant}
        toggleSuccessfulModal={closeSuccessModalTimeOut}
        singleRequiredList={singleRequiredList}
      />
      <AddedSuccessfullModal
        toggle={toggleSuccessfulModal}
        modal={visibleSuccessfulModal}
        setVisibleSuccessfulModal={setVisibleSuccessfulModal}
      />
      <Modal className='menu-alert' isOpen={menuAlert} toggle={() => setMenuAlert(!menuAlert)}>
        <ModalHeader toggle={() => setMenuAlert(!menuAlert)}>
          <span className='alert-header'>Warning</span>
        </ModalHeader>
        <ModalBody>
          You can not add a menu from different restaurant <br />
          <b>Please complete your shopping or remove your items from your cart</b>
        </ModalBody>
      </Modal>
    </Container>
  )
}

export default TypeTwoSR

import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import './cookiepolicy.scss'
function CookiePolicy() {
 
  return (
    <Container className='cookie-policy-cont'>
      <Row className='title-row'>
        <Col className='title-cont' xl='12' lg='12' md='12' sm='12' xs='12'>
          Cookie Policy - United States
        </Col>
        <Col xl='12' lg='12' md='12' sm='12' xs='12'>
          Artichokee Consumers
        </Col>
      </Row>
      <Row className='content-row'>
        <Col className='content-col'>
          <p>
            Our website uses cookies to distinguish you from other users of our
            website. This helps us to provide you with a good experience when
            you browse our website and also allows us to improve our site. By
            continuing to browse this site, you are agreeing to our use of
            cookies.
          </p>
          <p>
            What is a cookie? A "cookie" is a small file of letters and numbers
            that we store on your browser or the hard drive of your computer if
            you agree. Cookies contain information that is transferred to your
            computer's hard drive.
          </p>
          <p>
            What cookies do we use? <br />
            We use the following cookies:
          </p>
          <ul>
            <li>
              Strictly necessary/technical cookies. These are cookies that are
              required for the operation of our website. They make traffic
              control possible from the server to various users at a time. They
              include, for example, cookies that enable you to log into secure
              areas of our website, use a shopping cart or make use of e-billing
              services.
            </li>
            <li>
              Analytical/performance cookies. They allow us to recognize and
              count the number of visitors and to see how visitors move around
              our website when they are using it. This helps us to improve the
              way our website works, for example, by ensuring that users are
              finding what they are looking for easily.
            </li>
            <li>
              Functionality cookies. These are used to recognize you when you
              return to our website. This enables us to personalize our content
              for you, greet you by name and remember your preferences (for
              example, your choice of language or region).
            </li>
            <li>
              Targeting/advertising cookies. These cookies record your visit to
              our website, the pages you have visited and the links you have
              followed. These cookies allow implementing efficiency parameters
              in the advertisements offered in the websites, based on
              information about your behavior. We may also share this
              information with third parties for this purpose.
            </li>
          </ul>
          <p>
            Are there any third party cookies? <br />
            Please note that third parties (including, for example, advertising
            networks and providers of external services like web traffic
            analysis services) may also use cookies, over which we have no
            control. These cookies are likely to be analytical/performance
            cookies or targeting cookies.
          </p>
          <p>
            How do I block or opt out of cookies? <br />If you want to stop or
            restrict the placement of cookies or flush any cookies that may
            already be on your computer or device, please refer to and adjust
            your web browser preferences. Please note that cookie-based opt-outs
            are not effective on many mobile devices. Further information on
            cookies is available at <a href='https://www.allaboutcookies.org' target='_blank' without rel="noopener noreferrer">www.allaboutcookies.org</a>. By deleting our
            cookies or disabling future cookies you may not be able to access
            certain areas or features of our website or some of its
            functionality may be affected.
          </p>
        </Col>
      </Row>
    </Container>
  )
}

export default CookiePolicy

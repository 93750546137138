import React, { createContext, useReducer } from 'react'
import { CartReducer, sumItems } from './CartReducer'

export const CartContext = createContext()

const storage = localStorage.getItem('art_id_01R')
  ? [
      {
        GeneralInformationRestaurant: JSON.parse(localStorage.getItem('art_id_01R')),
        GeneralInformationOrder: JSON.parse(localStorage.getItem('art_id_02O')),
        GeneralInformationMainMenu: JSON.parse(localStorage.getItem('art_id_03M')),
        GeneralInformationAddOns: JSON.parse(localStorage.getItem('art_id_04A')),
      },
    ]
  : []

const initialState = {
  cartItems: storage,
  //Sumitemsa 2 deger geciciez => storage dan menu ve addons
  //...sumItems(storage),
  checkout: false,
}

export const CartContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CartReducer, initialState)

  const increase = (payload) => {
    dispatch({ type: 'INCREASE', payload })
  }

  const decrease = (payload) => {
    dispatch({ type: 'DECREASE', payload })
  }

  const addProduct = (payload) => {
    dispatch({ type: 'ADD_ITEM', payload })
  }

  const addMultipleProduct = (payload) => {
    dispatch({ type: 'ADD_MULTIPLE_ITEM', payload, storage })
  }

  const removeProduct = (payload) => {
    dispatch({ type: 'REMOVE_ITEM', payload })
  }

  const clearCart = () => {
    dispatch({ type: 'CLEAR' })
  }

  const handleCheckout = () => {
    dispatch({ type: 'CHECKOUT' })
  }

  const contextValues = {
    removeProduct,
    addProduct,
    addMultipleProduct,
    increase,
    decrease,
    clearCart,
    handleCheckout,
    ...state,
  }
  return <CartContext.Provider value={contextValues}>{children}</CartContext.Provider>
}

export default CartContextProvider

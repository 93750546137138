/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'us-east-2',
  aws_cognito_identity_pool_id: 'us-east-2:f0533052-4e08-4c19-a92a-ff31f53355c9',
  aws_cognito_region: 'us-east-2',
  aws_user_pools_id: 'us-east-2_sxyt5YKC7',
  aws_user_pools_web_client_id: '6mbrdi89sv6t97slmlv4srdngb',
  oauth: {
    domain: 'artichokee-auth-master.auth.us-east-2.amazoncognito.com',
    scope: ['phone', 'email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
    redirectSignIn:
      'https://dev.artichokee.com/,http://localhost:3000/,https://www.artichokee.com/,https://www.renzosfire.com/',
    redirectSignOut:
      'https://dev.artichokee.com/,http://localhost:3000/,https://www.artichokee.com/,https://www.renzosfire.com/',
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_AND_IDENTITY_POOLS',
  aws_appsync_graphqlEndpoint: 'https://6kxcscvtujgbdj3endyipejvty.appsync-api.us-east-2.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-2',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_cloud_logic_custom: [
    {
      name: 'mail',
      endpoint: 'https://imzncsfffe.execute-api.us-east-2.amazonaws.com/master',
      region: 'us-east-2',
    },
    {
      name: 'paymentApi',
      endpoint: 'https://v9afi0ynw0.execute-api.us-east-2.amazonaws.com/master',
      region: 'us-east-2',
    },
  ],
  aws_user_files_s3_bucket: 'artichokee-company-logos70421-master',
  aws_user_files_s3_bucket_region: 'us-east-2',
}

export default awsmobile

import React, { useContext, useEffect, useState, useCallback } from 'react'
//Styling
import { Container, Row, Col, Input, FormGroup, Label } from 'reactstrap'
import './orderpage.css'
//Contexts
import { UserContext } from '../../contexts/UserContext/UserContext'
import { CartContext } from '../../contexts/CartContext/CartContext'
//Components
import SuccessfulModal from './SuccessfulModal'
import DateTimePicker from './DateTimePicker'
//Stripe
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import CardSection from '../../Components/StripeElements/CardSection'

//Amplify
import { API, Auth, graphqlOperation } from 'aws-amplify'
//Graphql
import { createOrder } from 'graphql/custom-mutations'
//random
import moment from 'moment'

const InnerOrderPage = ({ props }) => {
  //useContexts
  const { user, setIsUserDataBlank } = useContext(UserContext)
  const { clearCart, cartItems } = useContext(CartContext)
  const [localCartItems, setLocalCartItems] = useState(cartItems[0])

  // UI Related States
  const [deliveryOption, setDeliveryOption] = useState(
    localCartItems !== undefined
      ? localCartItems.GeneralInformationRestaurant.RestaurantPickupEnabled
        ? 'pickup'
        : localCartItems.GeneralInformationRestaurant.RestaurantDeliveryEnabled
        ? 'delivery'
        : null
      : null
  )
  const [pickupOption, setPickupOption] = useState(
    localCartItems !== undefined
      ? localCartItems.GeneralInformationRestaurant
          .RestaurantAsapPickupTimeEnabled && isBetweenWorkHours()
        ? 'asap'
        : localCartItems.GeneralInformationRestaurant
            .RestaurantLaterPickupTimeEnabled
        ? 'later'
        : null
      : null
  )
  const [paymentOption, setPaymentOption] = useState(
    localCartItems !== undefined
      ? localCartItems.GeneralInformationRestaurant.RestaurantCardPaymentEnabled
        ? 'cardPayment'
        : localCartItems.GeneralInformationRestaurant.RestaurantPayatDoorEnabled
        ? 'payatdoor'
        : null
      : null
  )

  const [creditCardCollapse, setCreditCardCollapse] = useState(
    localCartItems !== undefined
      ? localCartItems.GeneralInformationRestaurant.RestaurantCardPaymentEnabled
        ? true
        : false
      : null
  )
  //END UI Related States

  //Modal Related States
  const [processing, setProcessing] = useState(false)
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)
  //END Modal Related States

  //Pickup Time and Date related states
  // Order mutationda pickupDate burdaki orderPickupDate olacak
  // Order mutationda pickupTime burdaki orderPickupTime olacak
  const [datePickerValidation, setDatePickerValidation] = useState(false)
  const [orderPickupDate, setOrderPickupDate] = useState(
    localCartItems !== undefined
      ? localCartItems.GeneralInformationMainMenu[0].MainMenuDate === ''
        ? moment().format('YYYY-MM-DD')
        : localCartItems.GeneralInformationMainMenu[0].MainMenuDate
      : null
  )
  const [orderPickupTime, setOrderPickupTime] = useState()
  // END Pickup Time and Date related states

  //Initial Order State (input of graphql mutation)
  const [order, setOrder] = useState()
  //Order status default is accept if payment enabled but pending if not
  const [orderStatus, setOrderStatus] = useState(
    localCartItems !== undefined
      ? localCartItems.GeneralInformationRestaurant.RestaurantCardPaymentEnabled
        ? 'accept'
        : localCartItems.GeneralInformationRestaurant.RestaurantPayatDoorEnabled
        ? 'pending'
        : null
      : null
  )

  const createPaymentIntent = useCallback(async () => {
    let apiName = 'paymentApi'
    let path = '/createPaymentIntent'
    const user = await Auth.currentAuthenticatedUser()
    const token = user.signInUserSession.idToken.jwtToken
    let myInit = {
      body: {
        accountID:
          localCartItems.GeneralInformationRestaurant.RestaurantPaymentId,
        amount: parseInt(
          localCartItems.GeneralInformationOrder.OrderTotal * 100
        ),
      },
      headers: {
        Authorization: token,
      },
    }

    const response = await API.post(apiName, path, myInit)
    return response.clientSecret
  }, [
    localCartItems.GeneralInformationOrder.OrderTotal,
    localCartItems.GeneralInformationRestaurant.RestaurantPaymentId,
  ])
  const calculateOrder = useCallback(async () => {
    if (localCartItems !== undefined && user) {
      // 1) AddOns
      let addOns = []
      localCartItems.GeneralInformationAddOns.map((addOn) => {
        addOns.push({
          addOnsCount: addOn.AddOnsCount,
          addOnsName: addOn.AddOnsName,
          addOnsPrice: addOn.AddOnsPrice,
          addOnsTempID: addOn.AddOnsTempID,
          //addOnsMenuName : Addons Menu nAme olmalui ki restaurant projesinde kullanalim
        })
      })
      let addOnsCount = 0
      localCartItems.GeneralInformationAddOns.map((x) => {
        addOnsCount += x.AddOnsCount
      })
      // 2) Main Menus
      let mainMenus = []
      localCartItems.GeneralInformationMainMenu.map((mainMenu) => {
        mainMenus.push({
          mainMenuId: mainMenu.MainMenuId,
          mainMenuCount: mainMenu.MainMenuCount,
          mainMenuInstruction: mainMenu.MainMenuInstruction,
          mainMenuName: mainMenu.MainMenuName,
          mainMenuPrice: mainMenu.MainMenuPrice,
          mainMenuTempID: mainMenu.MainMenuTempID,
        })
      })
      let mainMenusCount = 0
      localCartItems.GeneralInformationMainMenu.map((mainMenu) => {
        mainMenusCount += mainMenu.MainMenuCount
      })
      const orderInput = {
        subTotalPrice: localCartItems.GeneralInformationOrder.OrderSubTotal,
        taxPrice: localCartItems.GeneralInformationOrder.OrderTax,
        totalPrice: localCartItems.GeneralInformationOrder.OrderTotal,
        customerName: user.attributes.given_name,
        customerPhone: user.attributes.phone_number,
        customerEmail: user.attributes.email,
        pickUpDate: orderPickupDate,
        pickUpTime: orderPickupTime,
        owner: localCartItems.GeneralInformationOrder.OrderOwner,
        orderCompanyId:
          localCartItems.GeneralInformationRestaurant.RestaurantId,

        orderStatus: orderStatus,
        paymentStatus: paymentOption,
        paymentIntentId: '',
        menu: mainMenus,
        menuItem: addOns,

        mainMenusCount: mainMenusCount,
        mainAddOnsCount: addOnsCount,
      }

      setOrder(orderInput)
    }
  }, [
    localCartItems,
    orderPickupDate,
    orderPickupTime,
    orderStatus,
    paymentOption,
    user,
  ])

  useEffect(() => {
    createPaymentIntent()
      .then((clientSecret) => {
        setClientSecret(clientSecret)
      })
      .catch((err) => {
        setError(err.message)
      })
    // Bu setIsUserDataBlank ne ise yariyor?
    if (user) {
      if (
        user.attributes.phone_number === undefined ||
        user.attributes.given_name === undefined ||
        user.attributes.email === undefined
      ) {
        setIsUserDataBlank(true)
        props.history.push('/account')
      } else {
        setIsUserDataBlank(false)
      }
    }
  }, [createPaymentIntent, props.history, setIsUserDataBlank, user])

  useEffect(() => {
    calculateOrder()
    //order bir kere undefined donuyor unutma
  }, [
    orderPickupDate,
    orderPickupTime,
    paymentOption,
    orderStatus,
    calculateOrder,
  ])

  function calculateAsapHour() {
    const tempAsapTime = moment()
      .add(
        localCartItems.GeneralInformationRestaurant.RestaurantPrepTime,
        'minutes'
      )
      .format('hh:mm A')

    if (isBetweenWorkHours() && pickupOption === 'asap') {
      // setAsapHour(tempAsapTime) //For UI
      // For Order State
      return tempAsapTime
    } else if (pickupOption !== 'asap') {
      return orderPickupTime
    } else {
      //Hicbir durumda else e girmemeli kontrol edelim.
      return null
    }
  }

  function isBetweenWorkHours() {
    const timeStart = moment(
      localCartItems.GeneralInformationRestaurant.RestaurantStartTime,
      'hh:mm A'
    )
    const endStart = moment(
      localCartItems.GeneralInformationRestaurant.RestaurantEndTime,
      "'hh:mm A'"
    )

    if (
      moment()
        .add(
          localCartItems.GeneralInformationRestaurant.RestaurantPrepTime,
          'minutes'
        )
        .isBetween(
          timeStart.add(
            localCartItems.GeneralInformationRestaurant.RestaurantPrepTime,
            'minutes'
          ),
          endStart
        )
    )
      return true

    return false
  }

  // If there is no user redirect to auth pages force them to login
  const noUser = () => {
    props.history.push('/auth')
    // clearCart() neden var burda?
    //OrderPagede de sildik ClearCart i
  }

  const radioDeliveryChange = (e) => {
    if (e.target.value === 'pickup') {
      // setOrder({ ...order, ORDERDELIVERY:"PICKUP" })
    } else {
      // setOrder({ ...order, ORDERDELIVERY:"DELIVERY" })
    }
    setDeliveryOption(e.target.value)
  }
  const radioPaymentChange = (e) => {
    if (e.target.value === 'payatdoor') {
      setCreditCardCollapse(false)
      setOrderStatus('pending')
    } else if (e.target.value === 'cardPayment') {
      setCreditCardCollapse(true)
      setOrderStatus('accept')
    }
    setPaymentOption(e.target.value)
  }

  const onClosed = () => {
    props.history.push('/')
    clearCart()
  }
  //Button type can change according to payment method
  const callButton = (payment) => {
    if (!payment) {
      return (
        <button
          className='order-btn'
          onClick={handleCreateOrder}
          disabled={processing}>
          {processing ? 'Processing…' : 'Place Order'}
        </button>
      )
    } else {
      return (
        <button
          className='order-btn'
          disabled={processing || !clientSecret || !stripe}
          onClick={handleSubmit}>
          {processing ? 'Processing…' : 'Place Order'}
        </button>
      )
    }
  }
  //END Button type can change according to payment method

  //Stripe Related Functions
  const [clientSecret, setClientSecret] = useState(null)
  const [error, setError] = useState(null)
  const [metadata, setMetadata] = useState(null)
  const [succeeded, setSucceeded] = useState(false)

  const stripe = useStripe()
  const elements = useElements()

  const sendMailCustomer = async () => {
    const itemAddOns = []
    if (order.menuItem !== []) {
      order.menuItem.map((item) => {
        if (item.addOnsCount > 0) {
          itemAddOns.push({
            addOnsName: item.addOnsName,
            addOnsCount: item.addOnsCount,
            addOnsPrice: parseFloat(item.addOnsPrice).toFixed(2),
            addOnsTempID: item.addOnsTempID,
          })
        }
      })
    }
    const menusMap = []
    if (order.menu !== []) {
      order.menu.map((item) => {
        if (item.mainMenuCount > 0) {
          menusMap.push({
            mainMenuId: item.mainMenuId,
            mainMenuInstruction: item.mainMenuInstruction,
            mainMenuName: item.mainMenuName,
            mainMenuPrice: parseFloat(item.mainMenuPrice).toFixed(2),
            mainMenuTempID: item.mainMenuTempID,
            mainMenuCount: item.mainMenuCount,
          })
        }
      })
    }
    const initialOrder = {
      customerName: order.customerName,
      paymentStatus: order.paymentStatus,
      restaurantName:
        localCartItems.GeneralInformationRestaurant.RestaurantName,
      pickUpDate: order.pickUpDate,
      pickUpTime: order.pickUpTime, // PickUpTime undefined oldugu icin.
      totalPrice: parseFloat(order.totalPrice).toFixed(2),
      //Company Logo Siparis verilen restauranttan gelmeli (location gibi)
      companyLogo:
        'https://artichokee-ses-email-assets.s3.amazonaws.com/navbar-villageburger.png',
      //artichokee logo boyle gelecek sorun yok. Sonra html icine koyariz bunu hep ayni cunku
      artichokeeLogo:
        'https://artichokee-ses-email-assets.s3.amazonaws.com/artichokee_logo_128x128_stripe.png',
      menuItem: itemAddOns,
      menu: menusMap,
      isPaid: order.paymentStatus === 'payatdoor' ? false : true,
      restaurantOwner: null,
      state: localCartItems.GeneralInformationRestaurant.RestaurantState,
      city: localCartItems.GeneralInformationRestaurant.RestaurantCity,
      street: localCartItems.GeneralInformationRestaurant.RestaurantStreet,
      zipCode: localCartItems.GeneralInformationRestaurant.RestaurantZipCode,
    }
    const myInit = {
      to: order.customerEmail,
      from: 'info@artichokee.com',
      subject: `SUBJECT`,
      text: `TEXT`,
      replyTo: localCartItems.GeneralInformationRestaurant.RestaurantOwnerMail,
      order: JSON.stringify(initialOrder),
      mailTemplate: 'customer11',
    }
    try {
      let apiName = 'mail'
      let path = '/mail'
      const user = await Auth.currentAuthenticatedUser()
      const token = user.signInUserSession.idToken.jwtToken
      let request = {
        body: { myInit },
        // headers: {
        //   Authorization: token,
        // },
      }
      console.log(apiName, path, request)
      const mailData = await API.post(apiName, path, request)
    } catch (error) {
      console.log([error])
    }
  }
  const sendMailOwner = async () => {
    const itemAddOns = []
    if (order.menuItem !== []) {
      order.menuItem.map((item) => {
        if (item.addOnsCount > 0) {
          itemAddOns.push({
            addOnsName: item.addOnsName,
            addOnsCount: item.addOnsCount,
            addOnsPrice: parseFloat(item.addOnsPrice).toFixed(2),
            addOnsTempID: item.addOnsTempID,
          })
        }
      })
    }
    const menusMap = []
    if (order.menu !== []) {
      order.menu.map((item) => {
        if (item.mainMenuCount > 0) {
          menusMap.push({
            mainMenuId: item.mainMenuId,
            mainMenuInstruction: item.mainMenuInstruction,
            mainMenuName: item.mainMenuName,
            mainMenuPrice: parseFloat(item.mainMenuPrice).toFixed(2),
            mainMenuTempID: item.mainMenuTempID,
            mainMenuCount: item.mainMenuCount,
          })
        }
      })
    }
    const initialOrder = {
      customerName: order.customerName,
      customerEmail: order.customerEmail,
      customerPhone: order.customerPhone,
      paymentStatus: order.paymentStatus,
      restaurantName:
        localCartItems.GeneralInformationRestaurant.RestaurantName,
      pickUpDate: order.pickUpDate,
      pickUpTime: order.pickUpTime, // PickUpTime undefined oldugu icin.
      totalPrice: parseFloat(order.totalPrice).toFixed(2),
      //Company Logo Siparis verilen restauranttan gelmeli (location gibi)
      companyLogo:
        'https://artichokee-ses-email-assets.s3.amazonaws.com/navbar-villageburger.png',
      //artichokee logo boyle gelecek sorun yok. Sonra html icine koyariz bunu hep ayni cunku
      artichokeeLogo:
        'https://artichokee-ses-email-assets.s3.amazonaws.com/artichokee_logo_128x128_stripe.png',
      menuItem: itemAddOns,
      menu: menusMap,
      isPaid: order.paymentStatus === 'payatdoor' ? false : true,
      restaurantOwner: null, // Company tablosunda restaurant owner ismini tutalim. Zorunlu olmasin eger owner ismini vermek istemezse default olarak restaurantin adiyla ayni yapalim.
      state: localCartItems.GeneralInformationRestaurant.RestaurantState,
      city: localCartItems.GeneralInformationRestaurant.RestaurantCity,
      street: localCartItems.GeneralInformationRestaurant.RestaurantStreet,
      zipCode: localCartItems.GeneralInformationRestaurant.RestaurantZipCode,
    }

    const myInit = {
      to: localCartItems.GeneralInformationRestaurant.RestaurantOwnerMail,
      from: 'info@artichokee.com',
      subject: `SUBJECT`,
      text: `TEXT`,
      replyTo: 'canberk@prplbx.com', // restaurant owner ulasmak isterse restaurants@artichokee.com falan yapilabilir.
      order: JSON.stringify(initialOrder),
      mailTemplate: 'owner8',
    }
    try {
      let apiName = 'mail'
      let path = '/mail'
      const user = await Auth.currentAuthenticatedUser()
      const token = user.signInUserSession.idToken.jwtToken
      let request = {
        body: { myInit },
        // headers: {
        //   Authorization: token,
        // },
      }
      console.log(apiName, path, request)
      const mailData = await API.post(apiName, path, request)
    } catch (error) {
      console.log([error])
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setProcessing(true)

    if (!stripe || !elements) {
      return
    }
    const billingDetails = {
      name: user.attributes.given_name,
      email: user.attributes.email,
      phone: user.attributes.phone_number,
    }
    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: billingDetails,
      },
    })

    if (result.error) {
      setError(`Payment failed: ${result.error.message}`)
      setProcessing(false)
    } else {
      // The payment has been processed!

      if (result.paymentIntent.status === 'succeeded') {
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
        try {
          const orderWithPaymentID = {
            ...order,
            paymentIntentId: result.paymentIntent.id,
          }
          const res = await API.graphql(
            graphqlOperation(createOrder, { input: orderWithPaymentID })
          )
          sendMailCustomer(order)
          sendMailOwner(order)
          setError(null)
          setSucceeded(true)
          setProcessing(false)
          setMetadata(result.paymentIntent)
          toggle()
        } catch (err) {
          setError(`Order Create failed: ${err}`)
        }
      }
    }
  }
  //END Stripe Related Functions
  //Order Related Functions 1) calculate 2) create

  const handleCreateOrder = async (e) => {
    e.preventDefault()
    setProcessing(true)
    order.pickUpTime = calculateAsapHour()

    try {
      const res = await API.graphql(
        graphqlOperation(createOrder, { input: order })
      )
      sendMailCustomer(order)
      sendMailOwner(order)
      toggle()
    } catch (err) {
      console.log('orderError', err)
    }
  }

  const handleSubmitCouponCode = (e) => {
    e.preventDefault()
    console.dir(e.target)
  }
  return (
    <Container fluid='md'>
      {!user ? (
        noUser()
      ) : (
        <Row className='mainRow'>
          {cartItems.length === 0 ? null : (
            <>
              <Col
                // style={{
                //   'max-height': 'calc(100vh - 210px)',
                //   'overflow-y': 'auto',
                // }}
                className='colLeft'
                xl='8'
                lg='8'
                md='8'
                sm='8'
                xs='12'>
                <Row id='contact-row' className='insideRow'>
                  <Col xl='3' lg='3' md='3' sm='3' xs='12'>
                    <h5>
                      <span className='heading-name'></span>
                      Contact
                    </h5>
                  </Col>

                  <Col xl='9' lg='9' md='9' sm='9' xs='12'>
                    <Row>
                      <Col xs='2'>
                        <h6>
                          <span className='heading-text'>Name</span>
                        </h6>
                      </Col>
                      <Col xs='10'>{user.attributes.given_name}</Col>
                    </Row>
                    <Row>
                      <Col xs='2'>
                        <h6>
                          <span className='heading-text'> Phone</span>
                        </h6>
                      </Col>
                      <Col xs='10'>{user.attributes.phone_number}</Col>
                    </Row>
                    <Row>
                      <Col xs='2'>
                        <h6>
                          {' '}
                          <span className='heading-text'> Email </span>
                        </h6>
                      </Col>
                      <Col xs='10'>{user.attributes.email}</Col>
                    </Row>
                  </Col>
                </Row>
                <Row className='insideRow'>
                  <Col xl='3' lg='3' md='3' sm='3' xs='12'>
                    <h5>
                      <span className='heading-name'></span>
                      Method
                    </h5>
                  </Col>
                  <Col xl='9' lg='9' md='9' sm='9' xs='12'>
                    <Container>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>
                              <Input
                                className={
                                  localCartItems.GeneralInformationRestaurant
                                    .RestaurantPickupEnabled
                                    ? 'method-input'
                                    : 'method-input not-allowed'
                                }
                                type='radio'
                                name='pickup'
                                value='pickup'
                                checked={deliveryOption === 'pickup'}
                                onChange={radioDeliveryChange}
                                disabled={
                                  !localCartItems.GeneralInformationRestaurant
                                    .RestaurantPickupEnabled
                                }
                              />
                              <span
                                className={
                                  localCartItems.GeneralInformationRestaurant
                                    .RestaurantPickupEnabled
                                    ? 'heading-text'
                                    : 'heading-text not-allowed'
                                }>
                                {' '}
                                Pickup
                              </span>
                              &nbsp;
                              {!localCartItems.GeneralInformationRestaurant
                                .RestaurantPickupEnabled ? (
                                <span className='no-option-darkgray'>
                                  (This restaurant do not have pickup option)
                                </span>
                              ) : null}
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>
                              <Input
                                className={
                                  !localCartItems.GeneralInformationRestaurant
                                    .RestaurantDeliveryEnabled
                                    ? 'not-allowed'
                                    : ''
                                }
                                name='delivery'
                                type='radio'
                                value='delivery'
                                checked={deliveryOption === 'delivery'}
                                onChange={radioDeliveryChange}
                                disabled={
                                  !localCartItems.GeneralInformationRestaurant
                                    .RestaurantDeliveryEnabled
                                }
                              />
                              <span
                                className={
                                  !localCartItems.GeneralInformationRestaurant
                                    .RestaurantDeliveryEnabled
                                    ? 'not-allowed heading-text'
                                    : 'heading-text'
                                }>
                                Delivery
                              </span>
                              {/* If restaurantin deliverysi yoksa  */}
                              &nbsp;
                              {!localCartItems.GeneralInformationRestaurant
                                .RestaurantDeliveryEnabled ? (
                                <span className='no-option-darkgray'>
                                  (This restaurant do not have delivery option)
                                </span>
                              ) : null}
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
                <Row className='insideRow'>
                  <Col xl='3' lg='3' md='3' sm='3' xs='12'>
                    <h5>
                      <span className='heading-name'></span>
                      Pickup Location
                    </h5>
                  </Col>
                  <Col xl='9' lg='9' md='9' sm='9' xs='12'>
                    <Row>
                      <Col>
                        <span className='heading-text'>
                          {
                            localCartItems.GeneralInformationRestaurant
                              .RestaurantStreet
                          }
                        </span>
                        <div>
                          <span className='heading-text'>
                            {
                              localCartItems.GeneralInformationRestaurant
                                .RestaurantCity
                            }
                            ,&nbsp;
                          </span>
                          <span className='heading-text'>
                            {
                              localCartItems.GeneralInformationRestaurant
                                .RestaurantState
                            }
                            &nbsp;
                            {
                              localCartItems.GeneralInformationRestaurant
                                .RestaurantZipCode
                            }
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className='insideRow'>
                  <Col xl='3' lg='3' md='3' sm='3' xs='12'>
                    <h5>
                      <span className='heading-name'>Pickup Time</span>
                    </h5>
                  </Col>
                  <Col xl='9' lg='9' md='9' sm='9' xs='12'>
                    <Row>
                      <Col>
                        <DateTimePicker
                          isBetweenWorkHours={isBetweenWorkHours}
                          localCartItems={localCartItems}
                          setDatePickerValidation={setDatePickerValidation}
                          setOrderPickupDate={setOrderPickupDate}
                          setOrderPickupTime={setOrderPickupTime}
                          orderPickupTime={orderPickupTime}
                          datePickerValidation={datePickerValidation}
                          setPickupOption={setPickupOption}
                          pickupOption={pickupOption}
                          mainMenuDate={
                            localCartItems.GeneralInformationMainMenu[0]
                              .MainMenuDate
                          }
                          mainMenuEndDate={
                            localCartItems.GeneralInformationMainMenu[0]
                              .MainMenuEndDate
                          }
                          mainMenuEndTime={
                            localCartItems.GeneralInformationMainMenu[0]
                              .MainMenuEndTime
                          }
                          mainMenuIsFixMenu={
                            localCartItems.GeneralInformationMainMenu[0]
                              .MainMenuIsFixMenu
                          }
                          mainMenuStartDate={
                            localCartItems.GeneralInformationMainMenu[0]
                              .MainMenuEndTime
                          }
                          mainMenuStartTime={
                            localCartItems.GeneralInformationMainMenu[0]
                              .MainMenuStartTime
                          }
                          restStartTime={
                            localCartItems.GeneralInformationRestaurant
                              .RestaurantStartTime
                          }
                          restEndTime={
                            localCartItems.GeneralInformationRestaurant
                              .RestaurantEndTime
                          }
                          restWorkingDays={
                            localCartItems.GeneralInformationRestaurant
                              .RestaurantWorkingDays
                          }
                        />
                        {datePickerValidation === true ? (
                          <span style={{ color: 'red' }}>
                            Please fill out this field.
                          </span>
                        ) : null}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className='insideRow'>
                  <Col xl='3' lg='3' md='3' sm='3' xs='12'>
                    <h5>
                      <span className='heading-name'></span>
                      Payment
                    </h5>
                  </Col>
                  <Col xl='9' lg='9' md='9' sm='9' xs='12'>
                    <Row>
                      <Col>
                        <FormGroup check>
                          <Label check>
                            <Input
                              className={
                                localCartItems.GeneralInformationRestaurant
                                  .RestaurantPayatDoorEnabled
                                  ? ''
                                  : 'not-allowed'
                              }
                              type='radio'
                              name='payatdoor'
                              value='payatdoor'
                              checked={paymentOption === 'payatdoor'}
                              onChange={radioPaymentChange}
                              disabled={
                                !localCartItems.GeneralInformationRestaurant
                                  .RestaurantPayatDoorEnabled
                              }
                            />
                            <span
                              className={
                                localCartItems.GeneralInformationRestaurant
                                  .RestaurantPayatDoorEnabled
                                  ? 'heading-text'
                                  : 'heading-text not-allowed'
                              }>
                              Pay At Door
                            </span>
                            &nbsp;
                            {!localCartItems.GeneralInformationRestaurant
                              .RestaurantPayatDoorEnabled ? (
                              <span className='no-option-darkgray'>
                                (This restaurant do not accept payatdoor
                                payments)
                              </span>
                            ) : null}
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup check>
                          <Label check>
                            <Input
                              className={
                                localCartItems.GeneralInformationRestaurant
                                  .RestaurantCardPaymentEnabled
                                  ? ''
                                  : 'not-allowed'
                              }
                              type='radio'
                              name='cardPayment'
                              value='cardPayment'
                              checked={paymentOption === 'cardPayment'}
                              onChange={radioPaymentChange}
                              disabled={
                                !localCartItems.GeneralInformationRestaurant
                                  .RestaurantCardPaymentEnabled
                              }
                              // onClick={() => setCreditCardCollapse(true)}
                            />
                            <span
                              className={
                                localCartItems.GeneralInformationRestaurant
                                  .RestaurantCardPaymentEnabled
                                  ? 'heading-text'
                                  : 'heading-text not-allowed'
                              }>
                              Credit card
                            </span>
                            &nbsp;
                            {!localCartItems.GeneralInformationRestaurant
                              .RestaurantCardPaymentEnabled ? (
                              <span className='no-option-darkgray'>
                                (This restaurant do not accept online payments)
                              </span>
                            ) : null}
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row
                      className={
                        creditCardCollapse
                          ? 'panel-collapse'
                          : 'panel-collapse panel-close'
                      }>
                      <Col>
                        {/* <StripeMiddleware
                       toggle={toggle}
                       userEmail={user.attributes.email}
                       userName={user.attributes.given_name}
                       userPhone={user.attributes.phone_number}
                       // order={order}
                       // sendMailCustomer={sendMailCustomer}
                       // sendMailOwner={sendMailOwner}
                       accountID={'acct_1I5G4w4E6sniNwBf'}
                       callButton={callButton}
                     /> */}

                        <div className='container-fluid p-0'>
                          <form onSubmit={handleSubmit}>
                            <div className='py-3 text-center'>
                              <CardSection />
                              {error && (
                                <div className='message sr-field-error float-left'>
                                  {error}
                                </div>
                              )}
                              {/* <button
           className='order-btn'
           disabled={processing || !clientSecret || !stripe}
         >
           {processing ? 'Processing…' : 'Place Order'}
         </button> */}

                              {/* {callButton(true)} */}
                            </div>
                          </form>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className='d-flex justify-content-center py-4'>
                        {paymentOption !== 'payatdoor'
                          ? callButton(true)
                          : callButton(false)}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col xl='4' lg='4' md='4' sm='4' xs='12'>
                <Row style={{ justifyContent: 'center' }}>
                  <h5>
                    <span className='heading-name'></span>
                    SUMMARY
                  </h5>
                </Row>
                <Container>
                  <Row className='rest-logo-row'>
                    <Col xs='4'>
                      <Row>
                        <img
                          className='rest-logo-img'
                          src={
                            localCartItems.GeneralInformationRestaurant
                              .RestaurantLogo
                          }
                          alt='RestaurantLogo'
                        />
                      </Row>
                    </Col>
                    <Col xs='8'>
                      <Row>
                        <span className='rest-name-heading'>Order From</span>
                      </Row>
                      <Row className='rest-name'>
                        {
                          localCartItems.GeneralInformationRestaurant
                            .RestaurantName
                        }
                      </Row>
                    </Col>
                  </Row>
                </Container>

                {localCartItems.GeneralInformationMainMenu.map(
                  (menu, index) => {
                    return (
                      <Container style={{ marginTop: '20px' }}>
                        <Row>
                          <Col xs='2'>
                            <span className='price-span'>
                              {menu.MainMenuCount}x
                            </span>
                          </Col>
                          <Col xs='7'>
                            <Row>
                              <span className='product-name'>
                                {menu.MainMenuName}
                              </span>
                            </Row>
                          </Col>
                          <Col xs='3'>
                            <Row>
                              <span className='price-span'>
                                $
                                {parseFloat(
                                  menu.MainMenuPrice * menu.MainMenuCount
                                ).toFixed(2)}
                              </span>
                            </Row>
                          </Col>
                        </Row>

                        {localCartItems.GeneralInformationAddOns.map(
                          (addOn) => {
                            return (
                              <>
                                {addOn.AddOnsTempID === menu.MainMenuTempID && (
                                  <Row>
                                    <Col xs='2'>
                                      <span className='addOnsStyle'>
                                        {addOn.AddOnsCount}x
                                      </span>
                                    </Col>
                                    <Col xs='7'>
                                      <Row>
                                        <span className='addOnsStyle'>
                                          {addOn.AddOnsName}
                                        </span>
                                      </Row>
                                    </Col>
                                    <Col xs='3'>
                                      <span className='addOnsStyle'>
                                        {addOn.AddOnsPrice !== 0 &&
                                          '$' +
                                            parseFloat(
                                              addOn.AddOnsPrice *
                                                addOn.AddOnsCount
                                            ).toFixed(2)}
                                      </span>
                                    </Col>
                                  </Row>
                                )}
                              </>
                            )
                          }
                        )}
                      </Container>
                    )
                  }
                )}

                {/* map ends */}

                <Container style={{ marginTop: '35px' }}>
                  <Row className='flex-nowrap'>
                    <Col xs='2'></Col>
                    <Col xs='5'>
                      <span className='price-names'>Subtotal</span>
                    </Col>
                    <Col xs='5'>
                      <span className='price-span'>
                        $
                        {parseFloat(
                          localCartItems.GeneralInformationOrder.OrderSubTotal
                        ).toFixed(2)}
                      </span>
                    </Col>
                  </Row>
                  <Row className='flex-nowrap'>
                    <Col xs='2'></Col>
                    <Col xs='5'>
                      <span className='price-names'>Tax</span>{' '}
                    </Col>
                    <Col xs='5'>
                      <span className='price-span'>
                        $
                        {parseFloat(
                          localCartItems.GeneralInformationOrder.OrderTax
                        ).toFixed(2)}
                      </span>
                    </Col>
                  </Row>
                  <hr className='contact-hr'></hr>
                  <Row className='flex-nowrap'>
                    <Col xs='2'></Col>
                    <Col xs='5'>
                      <span className='price-names'>Total</span>
                    </Col>
                    <Col xs='5'>
                      <span className='price-span'>
                        $
                        {parseFloat(
                          localCartItems.GeneralInformationOrder.OrderTotal
                        ).toFixed(2)}
                      </span>
                    </Col>
                  </Row>
                </Container>

                <Container>
                  <Row>
                    <p className='coupon-code-p'>Do You have a Coupon Code?</p>
                  </Row>
                  <form
                    name='couponForm'
                    id='couponFormID'
                    action=''
                    onSubmit={handleSubmitCouponCode}>
                    <Row>
                      <Col xs='1'>
                        <span style={{ display: 'none' }}>
                          <i class='fas fa-spinner fa-spin'></i>
                          <i
                            class='far fa-times-circle fa-1x green'
                            style={{ color: 'red' }}></i>
                          <i
                            class='fas fa-check fa-1x'
                            style={{ color: 'green' }}></i>
                        </span>
                      </Col>
                      <Col xs='7'>
                        <Input
                          className='coupon-code-input'
                          name='couponCode'
                          id='couponCodeID'
                          type='text'
                          placeholder='Enter Code'
                        />
                      </Col>
                      <Col xs='3'>
                        <button className='coupon-code-button' type='submit'>
                          Control Coupon
                        </button>
                      </Col>
                    </Row>
                  </form>
                </Container>
              </Col>

              <SuccessfulModal
                onClosed={onClosed}
                modal={modal}
                toggle={toggle}
                restName={
                  localCartItems.GeneralInformationRestaurant.RestaurantName
                }
              />
            </>
          )}
        </Row>
      )}
    </Container>
  )
}
//
export default InnerOrderPage

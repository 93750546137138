import React, { useContext, useState } from 'react'
import InnerOrderPage from './InnerOrderPage'
// STRIPE
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
//Contexts
import { CartContext } from '../../contexts/CartContext/CartContext'
const OrderPage2 = (props) => {
  const { clearCart, cartItems } = useContext(CartContext)
  const [localCartItems, setLocalCartItems] = useState(cartItems[0])
  //loadStripe Function with useMemo
  //if REST have paymentenabled and has paymentID
  const stripePromise = React.useMemo(
    () =>
      cartItems.length !== 0
        ? localCartItems.GeneralInformationRestaurant.RestaurantPaymentId &&
          localCartItems.GeneralInformationRestaurant
            .RestaurantCardPaymentEnabled
          ? loadStripe(
              'pk_live_51HntzmKWYJ4D9DWptKYWW2WXZsIutE1G7cg96fXM90EI7YT4EUeMaCijMq9wHHXNZS8PUXDoToTaHudX3P0qDRtk00DcCwDlua',
              {
                stripeAccount:
                  localCartItems.GeneralInformationRestaurant
                    .RestaurantPaymentId,
              }
            )
          : null
        : null,
    [
      cartItems.length,
      localCartItems.GeneralInformationRestaurant.RestaurantCardPaymentEnabled,
      localCartItems.GeneralInformationRestaurant.RestaurantPaymentId,
    ]
  )

  return (
    <Elements stripe={stripePromise}>
      <InnerOrderPage stripePromise={stripePromise} props={props} />
    </Elements>
  )
}

export default OrderPage2

import React, { createContext, useEffect, useState } from 'react'
import { Auth, Hub } from 'aws-amplify'

export const UserContext = createContext()
const initialFormState = {
  username: '',
  password: '',
  email: '',
  authCode: '',
  formType: 'signIn',
}
export const UserContextProvider = (props) => {
  const [user, setUser] = useState(null)
  const [formState, updateFormState] = useState(initialFormState)
  const [isUserDataBlank, setIsUserDataBlank] = useState(null)

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          // getUser().then((userData) => setUser(userData))
          checkUser()
          break
        case 'signOut':
          updateFormState(() => ({ ...formState, formType: 'signIn' }))
          setUser(null)
          break
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data)
          break
        default:
          return
      }
    })

    checkUser()
  }, [])
  async function checkUser() {
    try {
      const user = await Auth.currentAuthenticatedUser()

      user.username = user.username.split('_', 1)[0]
      setUser(user)

      updateFormState(() => ({ ...formState, formType: 'signedIn' }))
    } catch (error) {
      //updateUser(null)
    }
  }

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        updateFormState,
        formState,
        checkUser,
        isUserDataBlank,
        setIsUserDataBlank,
      }}
    >
      {props.children}
    </UserContext.Provider>
  )
}

import React, { useEffect, useState, useContext } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { useHistory } from 'react-router-dom'
import { listOrders } from '../../graphql/custom-queries'
import { Auth } from 'aws-amplify'
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
  Container,
  Table,
  Form,
  Spinner,
} from 'reactstrap'
import OrderCard from 'Components/OrderCard/OrderCard'

//Tabs
import TabAccount from 'Components/ProfileTabs/TabAccount/TabAccount'
import TabPersonalInfo from 'Components/ProfileTabs/TabPersonalInfo/TabPersonalInfo'
import { useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { UserContext } from '../../contexts/UserContext/UserContext'
import { sortByDate } from '../../helperFunctions/dateFunctions'
import classnames from 'classnames'
//Scss Imports
import './accountpage.scss'
function Accountpage(props) {
  const history = useHistory()
  const [orders, setOrders] = useState([])
  const { user, checkUser, isUserDataBlank, setIsUserDataBlank } = useContext(UserContext)
  const [username, setUsername] = useState()

  const [persInfoControl, setPersInfoControl] = useState(isUserDataBlank === true ? false : true)

  const [picture, setPicture] = useState()

  const { register, handleSubmit, watch, errors } = useForm()
  const onSubmit = (data) => {
    const trimPhone = data.phone.replace(/ /g, '')
    handleChangeUserInfo(trimPhone, data.name)
  }
  const list = async () => {
    try {
      const orderData = await API.graphql(graphqlOperation(listOrders, { limit: 1000 }))
      const tempOrder = orderData.data.listOrders.items
      //sortByDate helper Function for sorting orders. if you need anywhere you can use it from helperFunctions/dateFunctions  folder.
      setOrders(sortByDate(tempOrder))
    } catch (err) {
      console.log('error ', err)
    }
  }
  useEffect(() => {
    list()
    setUsername(user.username)
  }, [])

  const [activeTab, setActiveTab] = useState(isUserDataBlank === true ? '2' : '1')

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  }
  const [visible, setVisible] = useState(false)

  const onDismiss = () => setVisible(!visible)

  async function handleChangeUserInfo(phone_number, name) {
    let result
    if ((user.username === 'Facebook' || user.username === 'Google') && user.attributes.given_name) {
      try {
        result = await Auth.updateUserAttributes(user, {
          phone_number: phone_number,
        })
        setIsUserDataBlank(false)
      } catch (e) {
        console.log(e)
      }
    } else {
      try {
        result = await Auth.updateUserAttributes(user, {
          phone_number: phone_number,
          given_name: name,
        })
        setIsUserDataBlank(false)
      } catch (e) {
        console.log(e)
      }
    }

    if (result === 'SUCCESS') {
      onDismiss()
      checkUser()
    }
  }
  return (
    <Container className='account-page-cont'>
      <Row>
        <Col className='left' xl='3' lg='3' md='4' sm='12' xs='12'>
          <Nav tabs vertical pills>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => {
                  toggleTab('1')
                }}>
                <i className='far fa-user fa-lg pr-2'></i> Account
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '2' })}
                onClick={() => {
                  toggleTab('2')
                }}>
                <i className='fas fa-cog fa-lg pr-2'></i> Personal Information
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '3' })}
                onClick={() => {
                  toggleTab('3')
                }}>
                <i className='fas fa-history fa-lg pr-2'></i> Orders
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                disabled
                className={classnames({ active: activeTab === '4' })}
                onClick={() => {
                  toggleTab('4')
                }}>
                <i className='fas fa-map-marker-alt fa-lg pr-2'></i> Addresses
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                disabled
                className={classnames({ active: activeTab === '5' })}
                onClick={() => {
                  toggleTab('5')
                }}>
                <i className='fas fa-credit-card fa-lg pr-2'></i> Cards
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                disabled
                className={classnames({ active: activeTab === '6' })}
                onClick={() => {
                  toggleTab('6')
                }}>
                <i className='far fa-question-circle fa-lg pr-2'></i> Help
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                disabled
                className={classnames({ active: activeTab === '7' })}
                onClick={() => {
                  toggleTab('7')
                }}>
                <i className='fas fa-info-circle fa-lg pr-2'></i> About Us
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                disabled
                className={classnames({ active: activeTab === '8' })}
                onClick={() => {
                  toggleTab('8')
                }}>
                <i className='fas fa-phone-alt fa-lg pr-2'></i> Contact Us
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
        <Col className='right' xl='9' lg='9' md='8' sm='12' xs='12'>
          <TabContent activeTab={activeTab}>
            <TabPane tabId='1'>
              <Container>
                <Row>
                  <Col>
                    <TabAccount orders={orders} user={user} />
                  </Col>
                </Row>
              </Container>
            </TabPane>
            <TabPane tabId='2'>
              <Container>
                <Row>
                  <Col xl='9' lg='9'>
                    <TabPersonalInfo
                      persInfoControl={persInfoControl}
                      setPersInfoControl={setPersInfoControl}
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      errors={errors}
                      register={register}
                      user={user}
                      visible={visible}
                      onDismiss={onDismiss}
                      isUserDataBlank={isUserDataBlank}
                    />
                  </Col>
                </Row>
              </Container>
            </TabPane>

            <TabPane tabId='3'>
              {orders.map((x) => (
                <OrderCard orders={x} key={x.id} />
              ))}
            </TabPane>
            <TabPane tabId='4'>
              <h4>Addresses</h4>
            </TabPane>
            <TabPane tabId='5'>
              <h4>Carts</h4>
            </TabPane>

            <TabPane tabId='6'>
              <h4>Help</h4>
            </TabPane>
            <TabPane tabId='7'>
              <h4>About Us</h4>
            </TabPane>
            <TabPane tabId='8'>
              <h4>Contact Us</h4>
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </Container>
  )
}
export default Accountpage

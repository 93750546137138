import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Container,
  Row,
  Col,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Form,
} from 'reactstrap'
import MaskedInput from 'react-input-mask'
import MaskedPhoneInput from 'Components/Inputs/MaskedPhone/MaskedPhoneInput'
import StandardInput from 'Components/Inputs/StandardInput/StandardInput'

function SignupForm({ register, errors }) {
  const [passwordEye, setPasswordEye] = useState(true)
  const togglePasswordEye = () => setPasswordEye(!passwordEye)
  return (
    <>
      <StandardInput
        labelTitle='Username'
        placeHolder=''
        maxLen={255}
        inputType='text'
        isRequired={true}
        inputName='usernameSignup'
        refTemp={register({
          required: 'Username is required!',
        })}
        emptyControl={errors.usernameSignup}
      />
      <StandardInput
        labelTitle='Password'
        placeHolder=''
        maxLen={120}
        inputType={passwordEye === true ? 'password' : 'text'}
        isRequired={true}
        inputName='passwordSignup'
        refTemp={register({
          required: 'Password is required!',
        })}
        emptyControl={errors.passwordSignup}
        isPassword={true}
        togglePasswordEye={togglePasswordEye}
      />
      <StandardInput
        labelTitle='Email'
        placeHolder=''
        maxLen={255}
        inputType='text'
        isRequired={true}
        inputName='emailSignup'
        refTemp={register({
          required: 'Email is required!',
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Invalid email address!',
          },
        })}
        emptyControl={errors.emailSignup}
      />
      {/* <MaskedPhoneInput
        labelTitle='Phone'
        placeHolder=''
        inputName='phone'
        isRequired={true}
        refTemp={register({
          required: 'Phone is required!',
        })}
        emptyControl={errors.phone}
        maskedInput={MaskedInput}
        inputMask='999 999 9999'
      /> */}
      <Button className='signin-btn' type='submit'>
        Sign Up
      </Button>
    </>
  )
}

export default SignupForm

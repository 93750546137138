import React, { useContext, useState, useEffect } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Col,
  Row,
  Container,
} from 'reactstrap'
import { CartContext } from 'contexts/CartContext/CartContext'
import { API } from 'aws-amplify'
import { getMenuStockControl, getCompanyAddresses } from 'graphql/custom-queries'
import { useHistory } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { DatePicker, Space, TimePicker } from 'antd'
import moment from 'moment'
import uuidv4 from 'helperFunctions/UUIDGenerator/uuIDGenerator'
import EncryptFunction from 'helperFunctions/Encrypt/index'
import findLocation from 'helperFunctions/FindLocation/FindLocation'
//Scss Imports
import './modal.scss'

function CardModal(props) {
  const history = useHistory()
  const [datePickerValidation, setDatePickerValidation] = useState(false)
  const { register, handleSubmit, watch, errors } = useForm()

  const [disabledTime, setDisabledTime] = useState([])

  const [disablePmTime, setDisablePmTime] = useState([])

  const [disableAmTime, setDisableAmTime] = useState([])
  const [disabledDates, setDisabledDates] = useState([])
  const [twelveHours, setTwelveHours] = useState('AM')
  const [hours, setHours] = useState(null)
  const [minutes, setMinutes] = useState('00')
  const [totalHour, setTotalHour] = useState([])
  const [pickupLocation, setPickupLocation] = useState()
  function handleChangeTime(event) {
    setMenuPickupTime(moment(event._d).format('hh:mm A'))
  }
  useEffect(() => {
    checkDisableDates(props.product.stock.uniqueDays)
    //16
    var timeStart = new Date('01/01/2005 ' + props.product.stock.startTime).getHours()
    //3
    var endStart = new Date('01/01/2005 ' + props.product.stock.endTime).getHours()
    let eksilen = 0
    let subs = Math.abs(endStart - timeStart) //14
    if (timeStart < endStart) {
      eksilen = subs // 10
    } else {
      eksilen = 24 - subs
    }
    let disArr = []
    let b = 0
    for (var i = 0; i <= eksilen; i++) {
      if (timeStart === 24) {
        disArr.push(b++)
      } else {
        disArr.push(timeStart++)
      }
    }
    //setDisabledTime(disArr)
    let tempPmArr = []
    let tempPmArrHalf = []
    let tempAmArr = []
    let tempAmArrHalf = []
    disArr.map((x) => {
      if (x < 12) {
        if (x === 0) {
          tempAmArr.push(`${x}:00 AM`)
          tempAmArrHalf.push(`${x}:30 AM`)
        } else {
          tempAmArr.push(`${x}:00 AM`)
          tempAmArrHalf.push(`${x}:30 AM`)
        }
      } else {
        if (x === 12) {
          tempPmArr.push(`${x}:00 PM`)
          tempPmArrHalf.push(`${x}:30 PM`)
        } else {
          tempPmArr.push(`${x - 12}:00 PM`)
          tempPmArrHalf.push(`${x - 12}:30 PM`)
        }
      }
    })

    let newRangeAmArr = []
    let newRangePmArr = []
    for (let i = 0; i < tempAmArr.length; i++) {
      let str = tempAmArr[i] + ' - ' + tempAmArrHalf[i]
      newRangeAmArr.push(str)
      let lastStr = ''
      let str1 = ''
      if (i + 1 >= tempAmArr.length) {
        lastStr = tempAmArrHalf[i] + ' - ' + tempPmArr[0]
        newRangeAmArr.push(lastStr)
      } else {
        str1 = tempAmArrHalf[i] + ' - ' + tempAmArr[i + 1]
        newRangeAmArr.push(str1)
      }
    }
    for (let k = 0; k < tempPmArr.length; k++) {
      let str = tempPmArr[k] + ' - ' + tempPmArrHalf[k]
      newRangePmArr.push(str)
      let str1 = tempPmArrHalf[k] + ' - ' + tempPmArr[k + 1]
      newRangePmArr.push(str1)
    }

    let x = newRangePmArr.pop()
    let y = newRangePmArr.pop()

    if (newRangeAmArr.length === 0) {
      setHours(newRangePmArr[0])
    } else {
      setHours(newRangeAmArr[0])
    }

    let newArr = [...newRangeAmArr, ...newRangePmArr]
    setTotalHour(newArr)
  }, [])
  function checkDisableDates(uniequeDays) {
    uniequeDays.map((x) => {
      if (x.isControlStock === false) {
        setDisabledDates((prev) => [...prev, x.date])
      }
    })
  }
  function handleDisableDate(current) {
    let start = props.product.startDate
    let end = props.product.endDate
    if (disabledDates.find((date) => date === moment(current).format('YYYY-MM-DD'))) {
      return true
    } else if (current < moment(start)) {
      return true
    } else if (current > moment(end).add(1, 'day')) {
      return true
    } else {
      return false
    }
  }

  const onSubmit = (data) => {
    if (singleSelectedDate !== null || props.product.dayOfWeek !== '') {
      addItems(props.product, extraItems, menuPickupTime)
      props.toggle()
      history.push('/order')
    } else {
      setDatePickerValidation(true)
    }
  }
  const [extraItems, setExtraItems] = useState([])

  const [singleSelectedDate, setSingleSelectedDate] = useState(props.product.dayOfWeek !== '' ? true : null)

  const { RangePicker } = DatePicker
  const {
    ProductExtraItemGroups: { items: groups },
  } = props.product

  // const [addOns, setAddOns] = useState(
  //   groups.length === 0
  //     ? []
  //     : groups[0].ExtraItemGroup.ExtraItemGroupExtraItems.items.map((x) => {
  //         return {
  //           ...x.ExtraItem,
  //           count: 0,
  //         }
  //       })
  // )
  const [addOns, setAddOns] = useState(() => {
    let tempItems = []

    if (groups.length !== 0) {
      groups.map((group) => {
        group.ExtraItemGroup.ExtraItemGroupExtraItems.items.map((item, index) => {
          const extraItemCopy = item.ExtraItem
          extraItemCopy.id = extraItemCopy.id + '-' + uuidv4()
          tempItems.push({
            ...extraItemCopy,
            count: 0,
          })
        })
      })
    }
    return tempItems
  })

  const [clickedAddOns, setClickedAddOns] = useState([])

  const [isEnable, setIsEnable] = useState(false)

  const { addProduct, cartItems } = useContext(CartContext)

  const [multi, setMulti] = useState(1)
  const [multiAddOns, setMultiAddOns] = useState(0)

  const [instructions, setInstructions] = useState('')
  const [menuPickupTime, setMenuPickupTime] = useState()

  const calculatePrice = (price, extraItems, multi) => {
    let addOnsPrice = addOns.map((item) => item.count * item.extraItemPrice)
    var sum = addOnsPrice.reduce(function (a, b) {
      return a + b
    }, 0)

    let total = price * multi + sum
    return total
  }

  function increment(bool) {
    setMulti((prevState) => prevState + 1)
  }

  function decrement() {
    if (multi > 1) {
      setMulti((prevState) => prevState - 1)
    } else {
      setMulti((prevState) => 1)
    }
  }

  const [triggerComponent, setTriggerComponent] = useState(true)
  function incrementAddOns(item) {
    let newAddOns = addOns.map((x) => {
      if (x.id === item.id) {
        return (item.count += 1)
      } else {
        return x.count
      }
    })
    setTriggerComponent(!triggerComponent)
  }

  function decrementAddOns(item) {
    let newAddOns = addOns.map((x) => {
      if (x.count > 0) {
        if (x.id === item.id) {
          return (item.count -= 1)
        } else {
          return x.count
        }
      }
    })
    setTriggerComponent(!triggerComponent)
  }

  function addOnsTotal() {
    let addOnsPrice = addOns.map((item) => item.count * item.extraItemPrice)

    var sum = addOnsPrice.reduce(function (a, b) {
      return a + b
    }, 0)

    return sum
  }
  async function getRestaurantCurrentAddresses() {
    let productLocation

    await API.graphql({ query: getCompanyAddresses, variables: { id: props.single.id } })
      .then((res) => {
        productLocation = res.data.getCompany.location
      })
      .catch((err) => console.log(err))
    return productLocation
  }
  async function getPickupLocation() {
    let result = findLocation(props.single.location, props.product.locationID)
    if (!result) {
      const currentAddresses = await getRestaurantCurrentAddresses()
      result = findLocation(currentAddresses, props.product.locationID)
    }
    setPickupLocation(result)
  }
  const addItems = (product, extraItems, menuPickupTime) => {
    if (cartItems.length === 0) {
      const newProduct = { ...product }

      let GeneralInformationRestaurant = {
        RestaurantName: props.single.name,
        RestaurantPaymentId: props.single.paymentId,
        RestaurantId: props.single.id,
        RestaurantCity: pickupLocation.city,
        RestaurantState: pickupLocation.state,
        RestaurantStreet: pickupLocation.street,
        RestaurantZipCode: pickupLocation.zipCode,
        RestaurantLocationID: pickupLocation.id,
        RestaurantAddressName: pickupLocation.addressName,
        RestaurantTax: props.single.tax,
        RestaurantOwnerMail: props.single.email,
        RestaurantCardPaymentEnabled: props.single.RestaurantCardPaymentEnabled,
        RestaurantPayatDoorEnabled: props.single.RestaurantPayatDoorEnabled,
        RestaurantDeliveryEnabled: props.single.RestaurantDeliveryEnabled,
        RestaurantPickupEnabled: props.single.RestaurantPickupEnabled,
        RestaurantAsapPickupTimeEnabled: props.single.RestaurantAsapPickupTimeEnabled,
        RestaurantLaterPickupTimeEnabled: props.single.RestaurantLaterPickupTimeEnabled,
        RestaurantPrepTime: props.single.RestaurantPrepTime,
        RestaurantType: props.single.restaurantType,
      }

      let GeneralInformationOrder = {
        OrderOwner: product.owner,
      }

      let GeneralInformationMainMenu = {
        MainMenuId: product.id,
        MainMenuName: product.name,
        MainMenuCount: multi,
        MainMenuPrice: product.price,
        MainMenuDescription: product.description,
        MainMenuDate: product.dayOfWeek ? product.dayOfWeek : singleSelectedDate,
        MainMenuStartDate: product.startDate,
        MainMenuEndDate: product.endDate,
        MainMenuPickUpTime: menuPickupTime,
        MainMenuInstruction: instructions,
      }

      let GeneralInformationAddOns = addOns.map((x) => {
        return {
          AddOnsCount: x.count,
          AddOnsName: x.extraItemName,
          AddOnsPrice: x.extraItemPrice,
        }
      })

      let General = [
        {
          GeneralInformationMainMenu,
          GeneralInformationAddOns,
          GeneralInformationRestaurant,
          GeneralInformationOrder,
        },
      ]

      addProduct(General)

      setInstructions('')
    } else {
      if (cartItems[0].owner === product.owner) {
        if (cartItems.length !== 0) {
          const newProduct = { ...product }
          delete newProduct.addOnMaps
          delete newProduct.MenuItem

          var MenuItems = Object.keys(addOns).map(function (name, extraItemPrice, count) {
            var obj = {}

            if (addOns[count] > 0) {
              obj['items'] = addOns[name]
              obj['extraItemPrice'] = addOns[extraItemPrice]
              obj['count'] = addOns[count]
            }

            return obj
          })

          var SingleCompany = Object.keys(props.single).map(function (name) {
            var obj1 = {}
            obj1['company'] = props.single[name]

            return obj1
          })

          const object = {
            ...newProduct,
            MenuItems,
            instructions,
            SingleCompany,
            tempCountID: uuidv4(),
            menuPickupTime: menuPickupTime,
            mainPrice: props.product.price * multi + addOnsTotal(),
          }
          const obj2 = {
            price: product.price,
            count: multi,
            name: product.name,
            addOns: addOns,
          }

          object.result = obj2

          const newPrice = calculatePrice(product.price, addOns, 1)

          object.price = newPrice

          if (singleSelectedDate !== null) {
            object.selectSingle = singleSelectedDate
          } else {
            object.selectSingle = ''
          }

          // Add product yapmadan once farkli restorandan eklettirme kontrolu yapilacak

          addProduct(object, multi, object.result.addOnsPrice)
          setInstructions('')
        } else {
          alert('You can not add from another restaurant.')
        }
      }
    }
  }

  const [listStock, setListStock] = useState()

  const [statea, setStatea] = useState(true)
  const [uniqueName, setUniqueName] = useState()

  function handleChange(i, event) {
    setStatea(false)
    setUniqueName(i)
  }

  function handleChangeClock(i, event) {
    setStatea(false)
    setUniqueName(i)
  }
  function disabledDate1(current) {
    // Can not select days before today and today
    return current && current.valueOf() < Date.now()
  }

  function handleSingleDate(e) {
    setSingleSelectedDate(e)
  }
  useEffect(() => {
    getPickupLocation()
  }, [props.product.locationID])
  return (
    <Modal
      isOpen={props.modal}
      toggle={() => {
        props.toggle()
        setSingleSelectedDate(null)
        setInstructions('')
        setDatePickerValidation(false)
      }}
      className='rest-menu-modal'>
      <ModalHeader
        className='menu-modal-header'
        toggle={() => {
          props.toggle()
          setSingleSelectedDate(null)
          setDatePickerValidation(false)
          setInstructions('')
        }}>
        {props.product.name}
      </ModalHeader>
      <ModalBody className='menu-modal-body'>
        <p className='description'>{props.product.description}</p>
        <hr></hr>
        <div className='d-flex justify-content-center'>
          <b>MAIN DISHES</b>
        </div>
        <Container className='extra-item'>
          <Row className='pt-2 pb-3'>
            <Col className='p-0' xl='6' lg='6' md='6' sm='6' xs='6'>
              <span className='addOn-table-title'>Name</span>
            </Col>
            <Col className='p-0' xl='6' lg='6' md='6' sm='6' xs='6'>
              <Container>
                <Row>
                  <Col className='p-0' xl='6' lg='6' md='6' sm='6' xs='8'>
                    <span className='addOn-table-title'>Portion</span>
                  </Col>
                  <Col className='p-0' xl='6' lg='6' md='6' sm='6' xs='4'>
                    <span className='float-right addOn-table-title '>Price</span>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          <Row className='py-1'>
            <Col className='p-0 pr-2' xl='6' lg='6' md='6' sm='6' xs='6'>
              <label htmlFor='vehicle1'>{props.product.name}</label>
            </Col>

            <Col className='p-0' xl='6' lg='6' md='6' sm='6' xs='6'>
              <Container>
                <Row>
                  <Col className='p-0' xl='6' lg='6' md='6' sm='6' xs='8'>
                    <div className='plus-minus-cont plus-minus-cont-extra'>
                      <span onClick={decrement}>-</span>
                      {multi}
                      <span onClick={increment}>+</span>
                    </div>
                  </Col>
                  <Col className='p-0' xl='6' lg='6' md='6' sm='6' xs='4'>
                    <span className='price'>${parseFloat(props.product.price).toFixed(2)}</span>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>

        {props.product.ProductExtraItemGroups.items.length === 0 ? null : (
          <>
            <hr></hr>
            <div className='d-flex justify-content-center'>
              <b>ADD ONS</b>{' '}
            </div>
            <Container className='extra-item'>
              <Row className='pt-2 pb-3'>
                <Col className='p-0' xl='6' lg='6' md='6' sm='6' xs='6'>
                  <span className='addOn-table-title'>Name</span>
                </Col>
                <Col className='p-0' xl='6' lg='6' md='6' sm='6' xs='6'>
                  <Container>
                    <Row>
                      <Col className='p-0' xl='6' lg='6' md='6' sm='6' xs='8'>
                        <span className='addOn-table-title'>Portion</span>
                      </Col>
                      <Col className='p-0' xl='6' lg='6' md='6' sm='6' xs='4'>
                        <span className='float-right addOn-table-title '>Price</span>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
              {addOns.map((x, index) => {
                return (
                  <div key={index}>
                    <Row className='py-1'>
                      <Col className='p-0' xl='6' lg='6' md='6' sm='6' xs='6'>
                        <label htmlFor='vehicle1'>{x.extraItemName}</label>
                      </Col>

                      <Col className='p-0' xl='6' lg='6' md='6' sm='6' xs='6'>
                        <Container>
                          <Row>
                            <Col className='p-0' xl='6' lg='6' md='6' sm='6' xs='8'>
                              <div className='plus-minus-cont plus-minus-cont-extra'>
                                <span
                                  onClick={() => {
                                    decrementAddOns(x)
                                  }}>
                                  -
                                </span>
                                {x.count}
                                <span
                                  onClick={() => {
                                    incrementAddOns(x)
                                  }}>
                                  +
                                </span>
                              </div>
                            </Col>
                            <Col className='p-0' xl='6' lg='6' md='6' sm='6' xs='4'>
                              <span className='price'>${parseFloat(x.extraItemPrice).toFixed(2)}</span>
                            </Col>
                          </Row>
                        </Container>
                      </Col>
                    </Row>
                  </div>
                )
              })}
            </Container>
          </>
        )}

        <hr></hr>

        <div className='d-flex justify-content-center'>
          <b>SPECIAL INSTRUCTIONS</b>
        </div>
        <textarea
          name='instruction'
          rows='3'
          cols='50'
          placeholder='Type instructions here...'
          onChange={(e) => setInstructions(e.target.value)}></textarea>
        <hr></hr>
        <div className='d-flex justify-content-center'>
          <b>PICKUP LOCATION</b>
        </div>

        {pickupLocation && (
          <>
            <p className='address-name'>{pickupLocation.addressName}</p>
            <p>
              {pickupLocation.street}, {pickupLocation.city}, {pickupLocation.state}, {pickupLocation.zipCode}{' '}
            </p>
          </>
        )}

        <hr></hr>
        <div className='d-flex justify-content-center'>
          <b>PICKUP TIME</b>
        </div>
      </ModalBody>
      <ModalFooter className='menu-modal-footer'>
        <div className='footer-cont'>
          <Container>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row className='pt-2 pb-4'>
                <Col className='date-picker-cont' xl='6' lg='6' md='6' sm='12' xs='12'>
                  {props.product.dayOfWeek === '' ? (
                    <>
                      <DatePicker
                        className='date-picker'
                        format={'YYYY-MM-DD'}
                        disabledDate={(current) => handleDisableDate(current)}
                        onChange={(e) => {
                          if (e !== null) {
                            setDatePickerValidation(false)
                            handleSingleDate(moment(e._d).format('YYYY-MM-DD'))
                          } else {
                            setSingleSelectedDate(null)
                            setDatePickerValidation(true)
                          }
                        }}
                      />
                      {datePickerValidation === true ? (
                        <p style={{ color: 'red' }}>Please fill out this field.</p>
                      ) : null}
                    </>
                  ) : null}
                  {props.product.dayOfWeek ? (
                    <>
                      <DatePicker
                        className='date-picker'
                        format={'YYYY-MM-DD'}
                        defaultValue={moment(props.product.dayOfWeek, 'YYYY-MM-DD')}
                        disabled
                        onChange={(e) => {
                          if (e !== null) {
                            setDatePickerValidation(false)
                            handleSingleDate(moment(e._d).format('YYYY-MM-DD'))
                          } else {
                            setSingleSelectedDate(null)
                            setDatePickerValidation(true)
                          }
                        }}
                      />
                      {datePickerValidation === true ? (
                        <p style={{ color: 'red' }}>Please fill out this field.</p>
                      ) : null}
                    </>
                  ) : null}
                </Col>
                <Col className='single-picker-cont' xl='6' lg='6' md='6' sm='12' xs='12'>
                  <div className='d-flex'>
                    <select
                      className='pickup-time-select'
                      name='hours'
                      id='hours'
                      onChange={(e) => {
                        setHours(e.target.value)
                      }}>
                      {totalHour
                        ? totalHour.map((x, index) => {
                            return (
                              <option value={x} key={index}>
                                {x}
                              </option>
                            )
                          })
                        : null}
                    </select>
                  </div>

                  {errors.pickupSelect ? <p style={{ color: 'red' }}>{errors.pickupSelect.message}</p> : null}
                </Col>
              </Row>
              <Row>
                <Col className='d-flex align-items-end justify-content-end p-0'>
                  <button
                    className='total-cont'
                    type='submit'
                    disabled={hours === null ? true : false}
                    onClick={() => {
                      if (singleSelectedDate === null) {
                        setDatePickerValidation(true)
                      }

                      setMenuPickupTime(hours)
                    }}>
                    <span>Checkout &nbsp;&nbsp;&nbsp;</span>
                    <span className='total'>${calculatePrice(props.product.price, extraItems, multi).toFixed(2)}</span>
                  </button>
                </Col>
              </Row>
            </Form>
          </Container>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default CardModal

{
  /* <Input
                      type='radio'
                      name='radio'
                      onChange={(e) => handleChange(index, e)}
                      value={item.startTime}
                    /> */
}

{
  /* 
                        <Col xl='4' lg='4' md='4' sm='4' xs='4'>
                          <Input
                            type='select'
                            name='startClock'
                            id='exampleSelect'
                            disabled={uniqueName === index ? false : true}
                            onChange={(e) => handleChangeClock(index, e)}>
                            <option value=''> </option>
                            <option value='00'>00</option>
                            <option value='15'>15</option>
                            <option value='30'>30</option>
                            <option value='45'>45</option>
                          </Input>
                        </Col> */
}

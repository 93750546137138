// store.js
import React, { createContext, useContext, useReducer } from "react";

const SearchContext = createContext();
const initialState = { search: "" };

const reducer = (state, action) => {
  switch (action.type) {
    case "change":
      return {
        search: action.search,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export const SearchProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <SearchContext.Provider value={{ state, dispatch }}>
      {children}
    </SearchContext.Provider>
  );
};

export const useStore = () => useContext(SearchContext);
